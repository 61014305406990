import { api, asyncApi } from "src/common/APIAnnotation"
import EmitterAble from "src/common/EmitterAble"
import { PlayerInterface } from "src/client/IClient"
import { ErrorCode, ErrorMsg, HwLLSError } from "src/ecode/ECode"
import { HWLLSEvents, MediaFormat, MediaType, StartPlayOptions, StreamInterruptRetry } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import CommonUtil from "src/utils/CommonUtil"
import { ValidatorUtil } from "src/utils/ValidatorUtil"
import { HlsPlayer } from "src/player/HlsPlayer"
import { PosterMask } from "src/player/PlayerUtils"

const module_ = 'HWHlsClient'
export class HWHlsClient extends EmitterAble implements PlayerInterface {
  private startPlayOptions: StartPlayOptions
  private hlsPlayer: HlsPlayer
  private debugStatisticTimer = {
    timer: null,
    interval: 1,
  }

  private detectTimer = {
    timer: null,
    interval: 3,
    interruptRetry: null
  }

  private recoveryInfo = {
    recoveryTimer: null,
    retryTimes: 0,
    recoveryUrl: null
  }

  constructor() {
    super(module_)
  }

  @asyncApi("HWHlsClient$startPlay#Promise<void>#string#StartPlayOptions")
  public async startPlay(url: string, options: StartPlayOptions): Promise<void> {
    LoggerFactory.info(module_, `[KPI] startPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    this.startPlayOptions = options
    url = url.replace(/\.flv/ig, '.m3u8')
    const checkResult = ValidatorUtil.checkUrlFormat(url, MediaFormat.HLS)
    if (!checkResult) {
      LoggerFactory.error(module_, `startPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }
    const playerDom = document.getElementById(options.elementId)
    if (!playerDom) {
      LoggerFactory.error(module_, `startPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'player Element is not exist.')
    }

    this.hlsPlayer = new HlsPlayer({
      eventEmitter: this.eventEmitter,
      url: url,
      parentDiv: playerDom,
      objectFit: options.objectFit,
      muted: options.muted,
      poster: this.startPlayOptions.poster
    })
    if (this.startPlayOptions.poster && this.startPlayOptions.poster.url) {
      PosterMask.preLoadPoster(this.startPlayOptions.poster.url)
    }
    await this.hlsPlayer.play(this.startPlayOptions.autoPlay, options.showLoading).catch(error => {
      const isPlayLimit = error instanceof HwLLSError && error.getCode() === ErrorCode.HWLLS_PLAY_NOT_ALLOW
      if (isPlayLimit) {
        this.eventEmitter.emit('Error', {
          errCode: error.getCode(),
          errDesc: error.getMsg()
        })
      } else {
        this.eventEmitter.emit('Error', {
          errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
          errDesc: ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
        })
      }
    })
  }

  @asyncApi("HWHlsClient$switchPlay#Promise<void>#string#StartPlayOptions")
  public async switchPlay(url: string, options?: StartPlayOptions): Promise<void> {
    LoggerFactory.info(module_, `[KPI] switchPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    this.startPlayOptions = options || this.startPlayOptions
    url = url.replace(/\.flv/ig, '.m3u8')

    const playerDom = document.getElementById(this.startPlayOptions.elementId)
    if (!playerDom) {
      LoggerFactory.error(module_, `switchPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'player Element is not exist.')
    }

    clearTimeout(this.debugStatisticTimer.timer)
    clearTimeout(this.detectTimer.timer)

    if (this.startPlayOptions.poster && this.startPlayOptions.poster.url) {
      PosterMask.preLoadPoster(this.startPlayOptions.poster.url)
    }
    await this.hlsPlayer.switchPlay({
      url: url,
      parentDiv: playerDom,
      objectFit: this.startPlayOptions.objectFit,
      muted: this.startPlayOptions.muted,
      autoPlay: this.startPlayOptions.autoPlay,
      showLoading: options.showLoading,
      poster: this.startPlayOptions.poster
    }).catch(error => {
      const isPlayLimit = error instanceof HwLLSError && error.getCode() === ErrorCode.HWLLS_PLAY_NOT_ALLOW
      if (isPlayLimit) {
        this.eventEmitter.emit('Error', {
          errCode: error.getCode(),
          errDesc: error.getMsg()
        })
      } else {
        this.eventEmitter.emit('Error', {
          errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
          errDesc: ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
        })
      }
    })
    if (this.debugStatisticTimer.timer) {
      this.streamStatistic(true, this.debugStatisticTimer.interval)
    }
    if (this.detectTimer.timer) {
      this.enableStreamStateDetection(true, this.detectTimer.interval, this.detectTimer.interruptRetry)
    }
  }

  @api("HWHlsClient$stopPlay#boolean#boolean")
  stopPlay(): boolean {
    this.hlsPlayer?.stopPlay()
    this.hlsPlayer = null
    clearTimeout(this.debugStatisticTimer.timer)
    this.debugStatisticTimer = {
      timer: null,
      interval: 1
    }
    clearTimeout(this.detectTimer.timer)
    this.detectTimer = {
      timer: null,
      interval: 3,
      interruptRetry: null
    }
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo = {
      recoveryTimer: null,
      retryTimes: 0,
      recoveryUrl: null
    }
    return true
  }

  @asyncApi("HWHlsClient$replay#Promise<void>#void")
  public async replay(): Promise<boolean> {
    if (this.hlsPlayer) {
      try {
        await this.hlsPlayer.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @asyncApi("HWHlsClient$resume#Promise<boolean>#void")
  public async resume(): Promise<boolean> {
    if (this.hlsPlayer) {
      try {
        await this.hlsPlayer.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @api("HWHlsClient$pause#boolean#void")
  public pause(): boolean {
    if (this.hlsPlayer) {
      this.hlsPlayer.pause()
      return true
    }
    return false
  }

  @api("HWHlsClient$pauseVideo#boolean#void")
  public pauseVideo(): boolean {
    LoggerFactory.error(module_, 'hls player is not support pause video only.')
    return false
  }

  @asyncApi("HWHlsClient$pauseVideo#Promise<boolean>#void")
  public async resumeVideo(): Promise<boolean> {
    LoggerFactory.error(module_, 'hls player is not support resume video only.')
    return true
  }

  @api("HWHlsClient$pauseVideo#boolean#void")
  public pauseAudio(): boolean {
    if (this.hlsPlayer) {
      this.hlsPlayer.mute(true)
      return true
    }
    return false
  }

  @asyncApi("HWHlsClient$pauseVideo#Promise<boolean>#void")
  public async resumeAudio(): Promise<boolean> {
    if (this.hlsPlayer) {
      this.hlsPlayer.mute(false)
      return true
    }
    return false
  }

  @api("HWHlsClient$pauseVideo#boolean#void")
  setPlayoutVolume(volume: number): boolean {
    this.hlsPlayer?.setPlayoutVolume(volume)
    return true
  }

  @api("HWHlsClient$pauseVideo#boolean#void")
  getPlayoutVolume(): number {
    return this.hlsPlayer?.getPlayoutVolume()
  }

  @api("HWHlsClient$pauseVideo#boolean#void")
  destoryClient(): void {
    this.hlsPlayer?.destory()
    this.hlsPlayer = null
    clearTimeout(this.debugStatisticTimer.timer)
    this.debugStatisticTimer = {
      timer: null,
      interval: 1
    }
    clearTimeout(this.detectTimer.timer)
    this.detectTimer = {
      timer: null,
      interval: 3,
      interruptRetry: null
    }
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo = {
      recoveryTimer: null,
      retryTimes: 0,
      recoveryUrl: null
    }
  }

  @api("HWHlsClient$streamStatistic#boolean#number#void")
  streamStatistic(enable: boolean, interval = 1): void {
    if (enable) {
      if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
        LoggerFactory.error(module_, 'streamStatistic, invalid parameter of interval.')
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
      }
      this.getStreamStatisticInfo(interval)
    } else {
      clearTimeout(this.debugStatisticTimer.timer)
      this.debugStatisticTimer = {
        timer: null,
        interval: 1
      }
    }
  }


  @api("HWHlsClient$enableStreamStateDetection#boolean#number#StreamInterruptRetry#void")
  enableStreamStateDetection(enable: boolean, interval = 3, interruptRetry?: StreamInterruptRetry): boolean {
    if (!enable) {
      clearTimeout(this.detectTimer.timer)
      this.detectTimer = {
        timer: null,
        interval: 3,
        interruptRetry: null
      }
      return true
    }
    if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
      LoggerFactory.error(module_, 'enableStreamStateDetection, invalid parameter of interval.')
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }

    clearTimeout(this.detectTimer.timer)
    const preStat = {
      preFramesDecoded: 0,
      isInterrupted: false
    }

    if (this.hlsPlayer) {
      preStat.preFramesDecoded = CommonUtil.getValue(this.hlsPlayer?.getStatisticInfo()?.totalFrameDecoded, 0)
    }

    this.detectTimer.interval = interval
    this.detectTimer.interruptRetry = interruptRetry
    this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    return true
  }

  private getStreamStatisticInfo(interval: number) {
    clearTimeout(this.debugStatisticTimer.timer)
    this.debugStatisticTimer.interval = interval
    this.debugStatisticTimer.timer = setTimeout(() => {
      const statisticInfo = this.hlsPlayer?.getStatisticInfo()
      if (statisticInfo) {
        this.eventEmitter.emit(HWLLSEvents.MEDIA_STATISTIC, {
          audio: {
            mediaType: MediaType.TRACK_TYPE_AUDIO,
            bitRate: 0,
            codec: statisticInfo.audioCodec
          },
          video: {
            mediaType: MediaType.TRACK_TYPE_VIDEO,
            codec: statisticInfo.videoCodec,
            bitRate: Math.round(statisticInfo.bitRate),
            frameRate: statisticInfo.frameRate,
            width: statisticInfo.width,
            height: statisticInfo.height
          }
        })
      }
      this.getStreamStatisticInfo(interval)
    }, interval * 1000)
  }

  private judgeStreamInterrupted(preStat: any, interval: number, interruptRetry?: StreamInterruptRetry): void {
    this.detectTimer.timer = setTimeout(() => {
      clearTimeout(this.detectTimer.timer)
      let isInterrupted = false
      const statisticInfo = this.hlsPlayer?.getStatisticInfo()
      if (statisticInfo) {
        isInterrupted = CommonUtil.getValue(statisticInfo?.totalFrameDecoded, 0) - CommonUtil.getValue(preStat.preFramesDecoded, 0) <= 0
        if (!isInterrupted) {
          clearTimeout(this.recoveryInfo.recoveryTimer)
          this.recoveryInfo.retryTimes = 0
        }
        if (statisticInfo) {
          preStat.preFramesDecoded = CommonUtil.getValue(statisticInfo.frameRate, 0)
        }
      }

      if (preStat.isInterrupted && !isInterrupted) {
        this.hlsPlayer?.loadingMask(false)
        this.eventEmitter.emit(HWLLSEvents.VIDEO_RECOVERY)
        this.eventEmitter.emit(HWLLSEvents.AUDIO_RECOVERY)
        preStat.isInterrupted = false
      } else if (!preStat.isInterrupted && isInterrupted) {
        this.startPlayOptions.showLoading && this.hlsPlayer?.loadingMask(true)
        this.eventEmitter.emit(HWLLSEvents.VIDEO_INTERRUPTED)
        this.eventEmitter.emit(HWLLSEvents.AUDIO_INTERRUPTED)
        preStat.isInterrupted = true
        if (interruptRetry?.enable) {
          LoggerFactory.info(this.moudle_, `autoRecoverPlay timer start`)
          clearTimeout(this.recoveryInfo.recoveryTimer)
          this.autoRecoverPlay(this.recoveryInfo.recoveryUrl, Math.max(10, interruptRetry?.retryInterval || 30), interruptRetry?.retryTimes || 30)
        }
      }
      this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    }, interval * 1000)
  }

  private autoRecoverPlay(recoveryUrl: string, retryInterval: number, retryTimes: number): void {
    let currentRetryTimes = 0
    this.recoveryInfo.recoveryTimer = setTimeout(async () => {
      LoggerFactory.info(this.moudle_, `autoRecoverPlay every ${retryInterval} sencond`)
      clearTimeout(this.recoveryInfo.recoveryTimer)
      this.recoveryInfo.recoveryTimer = null
      if (recoveryUrl !== this.recoveryInfo.recoveryUrl) {
        this.recoveryInfo.retryTimes = 0
        return
      }
      try {
        this.recoveryInfo.retryTimes++
        currentRetryTimes = this.recoveryInfo.retryTimes
        await this.switchPlay(recoveryUrl)
      } finally {
        this.recoveryInfo.retryTimes = currentRetryTimes
        if (currentRetryTimes < retryTimes) {
          this.autoRecoverPlay(recoveryUrl, retryInterval, retryTimes)
        } else {
          this.eventEmitter.emit('Error', {
            errCode: ErrorCode.HWLLS_PLAY_HLS_RETRY_FAILED,
            errDesc: ErrorMsg[ErrorCode.HWLLS_PLAY_HLS_RETRY_FAILED]
          })
        }
      }
    }, retryInterval * 1000)
  }
}