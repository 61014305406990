import { EventEmitter } from "events"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import commonUtil from "src/utils/CommonUtil"
import { HWLLSEvents, MediaType, RTCStreamEvent } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { RTCAudioPlayer, RTCVideoPlayer } from "src/player/WebRTCPlayer"

export interface PlayResult {
  trackType: MediaType;
  error: Error;
}

const enum TrackPlayState {
  Playing,    // app成功调用play或者resume后的状态
  Closed,     // app成功调用close后的状态
}

const module_ = 'HLLSTrack'

/**
 * HLLSTrack，业务封装track, 类型: 视频track/音频track
 */
export class HLLSTrack {
  private trackId_: string // track的标识id，对于本地流或者远端流，都对应平台返回的streamid
  private readonly trackType_: MediaType // 'audio' 或 'video'
  private track_: MediaStreamTrack
  private player_: any // 持有一个音频或者视频播放器
  private elementId_: string
  private objectFit_: string
  private playState_: TrackPlayState // 该track的app成功调用播放相关接口后的播放状态
  private trackMuted_: boolean  // 是否mute
  private audioVolume_: number  // 音频的音量大小，设置的时候可能播放器未创建
  private event_: EventEmitter
  private showLoading: boolean

  private readonly audioStart: ((e: any) => void)
  private readonly videoStart: ((e: any) => void)
  private readonly loadingHandler: ((e: any) => void)
  private readonly statusTraceReporter_: ((e: any) => void)
  private readonly fullScreenStatusHandler: ((e: any) => void)

  constructor(options: {
    trackType: MediaType;
    track?: MediaStreamTrack;
    event?: EventEmitter;
  }) {
    this.trackType_ = options.trackType
    this.track_ = options.track
    this.playState_ = TrackPlayState.Closed
    this.trackMuted_ = false
    this.event_ = options.event || new EventEmitter()
    this.trackId_ = commonUtil.generateStandardUuid()

    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      this.audioStart = () => {
        this.event_.emit(HWLLSEvents.AUDIO_START)
      }
    } else {
      this.videoStart = () => {
        this.event_.emit(HWLLSEvents.VIDEO_START)
      }

      this.loadingHandler = (isStuck: boolean) => {
        if (!this.showLoading) {
          return
        }
        if (isStuck) {
          this.player_?.loadingMask(true)
        } else {
          this.player_?.loadingMask(false)
        }
      }
      this.fullScreenStatusHandler = (event) => {
        this.event_.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, event)
      }
    }
    this.statusTraceReporter_ = (event) => {
      this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, event)
    }
  }

  public getTrackType(): MediaType {
    return this.trackType_
  }

  public getTrackId(): string {
    return this.trackId_
  }

  public getElementId(): string {
    return this.elementId_
  }

  public getObjectFit(): string {
    return this.objectFit_
  }

  public getTrack(): MediaStreamTrack {
    return this.track_
  }

  public async replaceTrack(track: MediaStreamTrack): Promise<void> {
    if (!track) {
      return
    }
    this.player_?.replaceTrack(track)
    this.track_ = track
    this.track_.enabled = !this.trackMuted_
  }

  public removeTrack(): void {
    this.player_ && this.player_.stop()
    this.track_ = null
  }

  public async play(playerDiv: HTMLElement, elementId: string, options?: {
    objectFit?: string;
    muted?: boolean;
    showLoading?: boolean;
    autoPlay?: boolean;
    poster?: any;
  }): Promise<PlayResult> {
    const trackType = this.getTrackType()
    LoggerFactory.info(module_, `${trackType} track begin play, elementId: ${elementId}, playerDiv.id: ${playerDiv.id}, options: ${JSON.stringify(options)} `)
    if (this.playState_ === TrackPlayState.Playing) {
      LoggerFactory.info(module_, `${trackType} track is playing, return `)
      return {
        trackType: trackType,
        error: null
      }
    }
    if (!this.track_) {
      LoggerFactory.error(module_, `${trackType} track is empty`)
      return {
        trackType: trackType,
        error: new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, `${trackType} track is empty`)
      }
    }
    this.elementId_ = elementId
    switch (this.trackType_) {
      case MediaType.TRACK_TYPE_AUDIO: {
        if (!this.player_) {
          LoggerFactory.info(module_, 'create AudioPlayer')
          this.player_ = new RTCAudioPlayer({
            playerDiv: playerDiv,
            playerId: 'player',
            track: this.track_,
            muted: options.muted,
            volume: this.audioVolume_,
            autoPlay: options.autoPlay
          })
        }
        break
      }
      case MediaType.TRACK_TYPE_VIDEO: {
        this.objectFit_ = options.objectFit
        this.showLoading = options.showLoading
        if (!this.player_) {
          LoggerFactory.info(module_, 'create VideoPlayer')
          this.player_ = new RTCVideoPlayer({
            parentDiv: playerDiv,
            playerId: 'player',
            track: this.track_,
            objectFit: options.objectFit,
            autoPlay: options.autoPlay,
            showLoading: options.showLoading,
            poster: options.poster
          })
          if (options.showLoading) {
            this.player_.loadingMask(true)
          }
        }
        break
      }
    }

    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      this.player_.off(HWLLSEvents.AUDIO_START, this.audioStart)
      this.player_.on(HWLLSEvents.AUDIO_START, this.audioStart)
    } else {
      this.player_.off(HWLLSEvents.VIDEO_START, this.videoStart)
      this.player_.on(HWLLSEvents.VIDEO_START, this.videoStart)
      this.player_.off(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, this.fullScreenStatusHandler)
      this.player_.on(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, this.fullScreenStatusHandler)

      this.event_.removeListener(HWLLSEvents.VIDEO_STUCK, this.loadingHandler)
      this.event_.on(HWLLSEvents.VIDEO_STUCK, this.loadingHandler)
    }
    this.player_.off(RTCStreamEvent.PLAYER_STATE_TRACE, this.statusTraceReporter_)
    this.player_.on(RTCStreamEvent.PLAYER_STATE_TRACE, this.statusTraceReporter_)

    LoggerFactory.info(module_, `play ${trackType} track start`)
    this.playState_ = TrackPlayState.Playing
    try {
      await this.player_.play()
      return {
        trackType: trackType,
        error: null
      }
    } catch (error) {
      const isPlayTimeout = error instanceof HwLLSError && /.*timeout.*/i.test(error.getMsg())
      LoggerFactory[isPlayTimeout ? 'warn' : 'error'](module_, `play ${trackType} failed: ${error} `)
      if (!isPlayTimeout) {
        this.playState_ = TrackPlayState.Closed
      }
      return {
        trackType: trackType,
        error: error
      }
    }
  }

  public stop(): void {
    if (!this.player_) {
      LoggerFactory.info(module_, `${this.getTrackType()} player is null`)
      return
    }
    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      this.player_.off(HWLLSEvents.AUDIO_START, this.audioStart)
    } else {
      this.player_.off(HWLLSEvents.VIDEO_START, this.videoStart)
      this.event_.removeListener(HWLLSEvents.VIDEO_STUCK, this.loadingHandler)
    }
    this.player_.stop()
    this.player_ = null
    this.trackMuted_ = false
    LoggerFactory.info(module_, `${this.getTrackType()} stop player success`)
    this.elementId_ = ''
    LoggerFactory.info(module_, `${this.getTrackType()} close success`)
    this.playState_ = TrackPlayState.Closed
  }

  public async resume(): Promise<void> {
    if (!this.player_) {
      LoggerFactory.info(module_, `${this.getTrackType()} player is null`)
      throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, `${this.getTrackType()} player is null`)
    }
    try {
      await this.player_.resume()
    } catch (error) {
      LoggerFactory.error(module_, `${this.getTrackType()} resume failed: ${error} `)
      throw error
    }
  }

  public pause(): void {
    if (!this.player_) {
      LoggerFactory.info(module_, `${this.getTrackType()} player is null`)
      throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, `${this.getTrackType()} player is null`)
    }
    this.player_.pause()
  }

  public getTrackMuted(): boolean {
    if (this.track_) {
      return !this.track_.enabled
    }
    return this.trackMuted_
  }

  public muteTrack(): void {
    if (this.track_) {
      this.track_.enabled = false
    }
    this.trackMuted_ = true
  }

  public unmuteTrack(): void {
    if (this.track_) {
      this.track_.enabled = true
    }
    if (this.player_.isMuted && this.player_.isMuted()) {
      this.player_.setVolume()
    }
    this.trackMuted_ = false
  }

  public setAudioVolume(volume: number): void {
    if (this.trackType_ !== MediaType.TRACK_TYPE_AUDIO) {
      return
    }
    this.audioVolume_ = volume / 100
    if (this.player_) {
      this.player_.setVolume(this.audioVolume_)
    }
  }

  public getAudioLevel(): number {
    if (this.trackType_ !== MediaType.TRACK_TYPE_AUDIO || !this.player_) {
      return 0
    }
    return this.player_.getAudioLevel()
  }

  public async replay(): Promise<void> {
    if (!this.player_) {
      LoggerFactory.info(module_, `${this.getTrackType()} player is null`)
      throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, `${this.getTrackType()} player is null`)
    }
    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      this.player_.off(HWLLSEvents.AUDIO_START, this.audioStart)
      this.player_.on(HWLLSEvents.AUDIO_START, this.audioStart)
    } else {
      this.player_.off(HWLLSEvents.VIDEO_START, this.videoStart)
      this.player_.on(HWLLSEvents.VIDEO_START, this.videoStart)
      this.player_.off(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, this.fullScreenStatusHandler)
      this.player_.on(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, this.fullScreenStatusHandler)

      this.event_.removeListener(HWLLSEvents.VIDEO_STUCK, this.loadingHandler)
      this.event_.on(HWLLSEvents.VIDEO_STUCK, this.loadingHandler)
    }
    this.player_.off(RTCStreamEvent.PLAYER_STATE_TRACE, this.statusTraceReporter_)
    this.player_.on(RTCStreamEvent.PLAYER_STATE_TRACE, this.statusTraceReporter_)

    LoggerFactory.info(module_, `rePlay ${this.getTrackType()} track start`)
    this.playState_ = TrackPlayState.Playing
    try {
      await this.player_.play()
    } catch (error) {
      LoggerFactory.error(module_, `replay${this.getTrackType()}, replay failed: ${error}`)
      this.playState_ = TrackPlayState.Closed
      throw error
    }
  }

  public exitFullScreen(): void {
    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      return
    }
    this.player_?.exitFullScreen()
  }

  public enterFullScreen(): void {
    if (this.trackType_ === MediaType.TRACK_TYPE_AUDIO) {
      return
    }
    this.player_?.enterFullScreen()
  }
}