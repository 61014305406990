import { SessionDescription, write } from "sdp-transform"
import { MediaType } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { MediaDirection } from "src/sdp/ISdp"
import { SDPEditor } from "src/sdp/SDPEditor"

export class UnifiedPlanSDPEditor extends SDPEditor {
  private static modifyBundle(sessionDesc: SessionDescription, bundle: string): void {
    for (const group of (sessionDesc.groups || [])) {
      if (group.type === 'BUNDLE') {
        group.mids = `${group.mids} ${bundle}`
        break
      }
    }
  }

  public transformAnswerSdp(answerSdp: string, localSdp: string): string {
    LoggerFactory.debug(this.module_, `transformAnswerSdp, answerSdp: ${this.printSdpInfo(answerSdp)}`)
    const answerSdpDes = this.parseSdp(answerSdp)
    const localSdpDesc = this.parseSdp(localSdp)
    if (!answerSdpDes || !localSdpDesc) {
      LoggerFactory.error(this.module_, `transformAnswerSdp failed,the sdp is invalid`)
      return undefined
    }

    const answerAudioMedias = this.getMedias(answerSdpDes, MediaType.TRACK_TYPE_AUDIO)
    answerAudioMedias.forEach(audioMedia => {
      audioMedia.direction = MediaDirection.SEND_ONLY
    })

    let answerVideoMedias = this.getMedias(answerSdpDes, MediaType.TRACK_TYPE_VIDEO)
    const localVideoMedias = this.getMedias(localSdpDesc, MediaType.TRACK_TYPE_VIDEO)
    if (localVideoMedias.length !== answerVideoMedias.length) {
      const answerMids = answerVideoMedias.map(media => {
        return `${media.mid}`
      })
      localVideoMedias.forEach((videoMedia) => {
        if (!answerMids.includes(`${videoMedia.mid}`)) {
          const cloneMedia: SessionDescription['media'][number] = JSON.parse(JSON.stringify(answerVideoMedias[0]))
          cloneMedia.mid = videoMedia.mid
          answerSdpDes.media.push(cloneMedia)
          UnifiedPlanSDPEditor.modifyBundle(answerSdpDes, videoMedia.mid)
        }
      })
    }

    answerVideoMedias = this.getMedias(answerSdpDes, MediaType.TRACK_TYPE_VIDEO)
    answerVideoMedias.forEach(videoMedia => {
      videoMedia.direction = MediaDirection.SEND_ONLY
    })

    const resSdp = write(answerSdpDes)
    LoggerFactory.debug(this.module_, `transformAnswerSdp success,answerSdp: ${this.printSdpInfo(resSdp)}`)
    return resSdp
  }
}
