import { LoggerFactory } from "src/logger/LoggerFactory"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import CommonUtil from 'src/utils/CommonUtil'
import { configs } from "src/config/config"

const module_ = "httpRequest"

class HttpRequest {
  private defaultRequestOption: any

  constructor() {
    this.defaultRequestOption = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      referrerPolicy: 'strict-origin-when-cross-origin'
    }
  }

  public async fetch(requestUrls: string[], requestOption: any = {}, requestHeader: any = {}, timeOut = configs.REQUEST_DEFAULT_TIMEOUT, retryTimes = 1, extendInfos?: any): Promise<any> {
    let httpTaskId: string = null
    let err: Error = null
    retryTimes = retryTimes || 1

    let retryCount = 0
    while (retryCount < retryTimes) {
      for (let idx = 0; idx < requestUrls.length; idx++) {
        let resp = null
        const extendInfo = {}
        httpTaskId = CommonUtil.generateRandomId(16, 10)
        LoggerFactory.debug(module_, `[HTTP_ID:${httpTaskId}] fetch request start: ${LoggerFactory.shieldUrlParameters(requestUrls[idx])}`)
        try {
          this.extendInfosHandle(requestUrls[idx], 'start', extendInfo, extendInfos)
          resp = await this.doFetch(requestUrls[idx], requestOption, requestHeader, timeOut, httpTaskId)
          this.extendInfosHandle(requestUrls[idx], 'end', extendInfo, extendInfos, resp)
          LoggerFactory.debug(module_, `[HTTP_ID:${httpTaskId}] fetch request success`)
        } catch (error) {
          LoggerFactory.error(module_, `[HTTP_ID:${httpTaskId}], requestUrl: ${LoggerFactory.shieldUrlParameters(requestUrls[idx])}, fetch request failed`)
          this.extendInfosHandle(requestUrls[idx], 'end', extendInfo, extendInfos, error)
          if (idx >= requestUrls.length - 1) {
            retryCount++
            if (retryCount < retryTimes) {
              await CommonUtil.sleep((CommonUtil.getRandom() * 500) + 500)
            }
          }
          err = error
        }
        if (resp) {
          err = null
          return resp
        }
      }
    }

    if (err) {
      throw err
    }
  }

  private doFetch(requestUrl: string, requestOption: any = {}, requestHeader: any = {}, timeOut: number, httpTaskId: string): Promise<any> {
    let requestTimer: NodeJS.Timeout
    let controller: AbortController = null
    let signal = null
    if (AbortController) {
      controller = new AbortController()
      signal = controller.signal
    }
    const requestTimeOutDfd = new Promise((_resolve, reject) => {
      requestTimer = setTimeout(() => {
        clearTimeout(requestTimer)
        reject(new HwLLSError(ErrorCode.HWLLS_ERROR_SERVER_NO_RESPONSE))
        controller && controller.abort()
      }, timeOut)
    })

    const requestDfd = new Promise((resolve, reject) => {
      let fetchCall = null
      if (signal) {
        fetchCall = fetch(requestUrl, { ...this.defaultRequestOption, ...requestOption, ...{ headers: requestHeader }, ...{ signal } })
      } else {
        fetchCall = fetch(requestUrl, { ...this.defaultRequestOption, ...requestOption, ...{ headers: requestHeader } })
      }
      fetchCall.then(response => {
        if (/webrtc\/v1\/pullstream/ig.test(requestUrl)) {
          CommonUtil.syncLocalTime(response.headers.get('Date'))
        }

        if (!response.ok) {
          LoggerFactory.error(module_, `[HTTP_ID:${httpTaskId}] doFetch failed: ${response.status}`)
          if (response.status >= 500 || response.status === 0) {
            reject(new HwLLSError(ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL, `${response.status} _ ${response.statusText || 'fetch failed'}.`))
          } else {
            resolve({
              httpCode: response.status
            })
          }
          return
        }
        let getRespDfd: any
        const contentType = response.headers.get('Content-Type') || requestHeader['Content-Type']
        if (/.*application\/json.*/.test(contentType)) {
          getRespDfd = response.json()
        } else if (/^text.*/.test(contentType)) {
          getRespDfd = response.text()
        } else {
          getRespDfd = response.arrayBuffer()
        }
        getRespDfd.then(result => {
          resolve({
            httpCode: response.status,
            data: result
          })
        }).catch(error => {
          LoggerFactory.error(module_, `[HTTP_ID:${httpTaskId}], requestUrl: ${LoggerFactory.shieldUrlParameters(requestUrl)}, doFetch get response failed: ${error}`)
          reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error.message || 'get respone error.'))
        })
      }).catch(error => {
        LoggerFactory.error(module_, `[HTTP_ID:${httpTaskId}], requestUrl: ${LoggerFactory.shieldUrlParameters(requestUrl)}, doFetch failed for exception: ${error}`)
        reject(new HwLLSError(ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL, error.getMsg ? error.getMsg() : error.message))
      }).finally(() => {
        clearTimeout(requestTimer)
      })
    })
    return Promise.race([requestTimeOutDfd, requestDfd])
  }

  private extendInfosHandle(requestUrl: string, action: string, extendInfo: any, extendInfos: any, result?: any): void {
    if (extendInfos) {
      if (action === 'start') {
        Object.assign(extendInfo, {
          id: extendInfos.length + 1,
          domain: requestUrl,
          start_ms: CommonUtil.getCurrentLocalTimestamp(),
          end_ms: 0,
          rspCode: '',
          errMsg: ''
        })
      } else if (action === 'end') {
        if (result instanceof HwLLSError) {
          extendInfo.end_ms = CommonUtil.getCurrentLocalTimestamp()
          extendInfo.rspCode = result.getCode()
          extendInfo.errMsg = result.getMsg()
        } else {
          extendInfo.end_ms = CommonUtil.getCurrentLocalTimestamp()
          extendInfo.rspCode = result.httpCode
        }
        extendInfo.delay_ms = extendInfo.end_ms - extendInfo.start_ms
        extendInfos.push(extendInfo)
      } else {
        LoggerFactory.info(module_, `cannot handle`)
      }
    }
  }
}

const httpRequest = new HttpRequest()

export default httpRequest
