import { ErrorCode, HwLLSError } from 'src/ecode/ECode'
import adapter from 'webrtc-adapter'
import FlvJs from 'flv.js'
import HlsJS from 'hls.js'
import { browserConfigs } from 'src/config/browserConfig'

// @ts-ignore
window.RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection

class System {
  static async checkSystemRequirements(): Promise<boolean> {
    return await this.webRTCCapabilityCheck()
  }

  static isChrome(): boolean {
    return adapter.browserDetails.browser === 'chrome'
  }

  static isSafari(): boolean {
    return adapter.browserDetails.browser === 'safari'
  }

  static isFirefox(): boolean {
    return adapter.browserDetails.browser === 'firefox'
  }

  static isOnlySupportUnfiedPlan(): boolean {
    let isSupport = true
    if (adapter.browserDetails.browser === 'chrome') {
      const chromeVersion = System.getBrowserVersion()
      isSupport = chromeVersion > 90
    } else if (adapter.browserDetails.browser === 'safari') {
      isSupport = adapter.browserDetails.supportsUnifiedPlan
    }
    return isSupport
  }

  public static hlsCapabilityCheck(): boolean {
    let browserSupport = HlsJS.isSupported()
    if (!browserSupport) {
      const VideoElement = document.createElement('video')
      browserSupport = Boolean(VideoElement.canPlayType('application/vnd.apple.mpegurl'))
    }
    if (!browserSupport) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_LIVE_UNSUPPORTED, 'hls format unsupport.')
    }
    return true
  }

  public static flvCapabilityCheck(): boolean {
    let browserSupport = FlvJs.isSupported()
    if (browserSupport) {
      const futureList = FlvJs.getFeatureList()
      browserSupport = futureList.mseLiveFlvPlayback || futureList.nativeMP4H264Playback
    }
    if (!browserSupport) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_LIVE_UNSUPPORTED, 'flv format unsupport.')
    }
    return true
  }

  public static async webRTCCapabilityCheck(): Promise<boolean> {
    if (!window || !navigator) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_WEBRTC_UNSUPPORTED, 'window or navigator error')
    }

    if (!window.RTCPeerConnection) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_WEBRTC_UNSUPPORTED, 'RTCPeerConnection Object not exist')
    }

    let offer = null
    try {
      let checkConnection = new RTCPeerConnection()
      offer = await checkConnection.createOffer({ offerToReceiveAudio: false, offerToReceiveVideo: true })
      if (System.isChrome() && !/H264/i.test(offer.sdp)) {
        checkConnection.close()
        checkConnection = new RTCPeerConnection()
        offer = await checkConnection.createOffer({ offerToReceiveAudio: false, offerToReceiveVideo: true })
      }
      checkConnection.close()
    } catch (error) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_WEBRTC_UNSUPPORTED, `RTCPeerConnection construct error: ${error.message}`)
    }
    if (!/H264/i.test(offer.sdp)) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_WEBRTC_UNSUPPORTED, 'RTCPeerConnection unsupport AVC codec.')
    }

    // Verify the blacklist or whitelist of mobile browsers that support WebRTC when the SDP supports H.264
    if (adapter.browserDetails.browser === 'chrome' && !System.browserSupportCheckByPolicy()) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_WEBRTC_UNSUPPORTED, `browser version not support: ${navigator.userAgent}`)
    }
    return true
  }

  static browserSupportCheckByPolicy(): boolean {
    let checkResult = false
    const policies = browserConfigs.POLICY === 0 ? browserConfigs.WEBRTC_UNSUPPORT_BLACKLIST : browserConfigs.WEBRTC_UNSUPPORT_WHITELIST
    for (const blackReg of policies) {
      if (blackReg.test(navigator.userAgent)) {
        checkResult = true
        break
      }
    }
    return browserConfigs.POLICY === 0 ? !checkResult : checkResult
  }

  static getBrowserVersion(): number {
    if (adapter.browserDetails.browser === 'safari') {
      const result = /.*Version\/(\d+).*/ig.exec(navigator.userAgent)
      if (result && result.length === 2) {
        return parseInt(result[1])
      }
    }
    if (adapter.browserDetails.browser === 'chrome') {
      return adapter.browserDetails.version || 91
    }

    return adapter.browserDetails.version
  }
}

export default System
