export const enum SdpNegotiateStandard {
  CUSTOMIZE_STANDARD,
  COMMON_STANDARD
}

export enum MediaType {
  TRACK_TYPE_AUDIO = 'audio',
  TRACK_TYPE_VIDEO = 'video'
}

export interface StartPlayOptions {
  elementId: string;
  objectFit?: PlayerViewMode;
  muted?: boolean;
  sessionId?: string;
  showLoading?: boolean;
  autoPlay?: boolean;
  poster?: {
    url: string;
    mode?: PosterMode;
    startEnable?: boolean;
    pauseEnable?: boolean;
  };
  webrtcConfig?: WebRTCConfig;
  domainPolicy?: DomainPolicy;
  schedulePolicy?: SchedulePolicy;
}

export interface WebRTCConfig {
  receiveVideo: boolean;
  receiveAudio: boolean;
}

export enum PosterMode {
  CROP = 'crop', // 裁剪模式
  FILL = 'fill' // 伸缩铺满
}

export interface PlayOptions extends StartPlayOptions {
  audio?: boolean;
  video?: boolean;
}

/**
 * 公共字段
 */
export interface CommInfo {
  ctime: number;
  session: string;
  deviceId: string;
  domain: string;
  appName: string;
  streamName: string;
  v: string;
  event: number;
  eventName: string;
  netType: string;
  userAgent: string;
  svrSid: string;
}

/**
 *对外接口信息
 */
export interface ApiCallInfo extends CommInfo {
  apiCallId: string;
  apiName: string;
  params: string;
  startTime: number;
  endTime: number;
  exception?: string;
  ret?: string;
}

/**
 * CallbackInfo
 */
export interface CallbackInfo extends CommInfo {
  callbackId?: string;
  callbackName?: string;
  callbackType?: string;
  params?: string;
  startTime?: number;
  endTime?: number;
}

export const enum PullStreamResult {
  'SUCC' = 0,
  'FAIL' = 1,
  'INTERRUPT' = 2,
  'CONNECTION_UNCOMPLETE' = 3,
  'SUCC_AFTER_TIMEOUT' = 4
}

export const enum ConnectionStatus {
  'CLOSED' = 0,
  'CONNECTED' = 1
}

export enum StatEvents {
  'EVENT_START_PULL_STREAM_REQ' = 201,
  'EVENT_STOP_PULL_STREAM_REQ' = 202,
  'EVENT_PULL_MEDIA_STREAM' = 203,
  'EVENT_CONNECTION_CHANNEL_STATUS' = 204
}

export enum QosEvents {
  'EVENT_VIDEO_QOS' = 1100,
  'EVENT_AUDIO_QOS' = 1101
}

export enum ApiEvents {
  API_CALL = 501,
  CALLBACK_API = 502
}

export interface AudioQosInfo extends CommInfo {
  ssrc: number;
  accessAddr: string;
  SFUAddr: string;
  codec: string;
  bitrate: number;
  lostPktCnt: number;
  finalLostPktCnt: number;
  maxContLostPkts: number;
  rtt: number;
  jitter: number;
  bytes: number;
  packets: number;
  JBDepth: number;
  playTotalTime: number;
  freeze200Cnt: number;
  freeze200Duration: number;
  nackCnt: number;
}

export interface VideoQosInfo extends CommInfo {
  ssrc: number;
  accessAddr: string;
  SFUAddr: string;
  codec: string;
  picW: string;
  picH: number;
  bitrate: number;
  frameRate: number;
  lostPktCnt: number;
  finalLostPktCnt: number;
  maxContLostPkts: number;
  rtt: number;
  jitter: number;
  bytes: number;
  packets: number;
  JBDepth: number;
  playTotalTime: number;
  decFrameCnt: number;
  freeze200Cnt: number;
  freeze200Duration: number;
  freeze600Cnt: number;
  freeze600Duration: number;
  iFrameCnt: number;
  pFrameCnt: number;
  bFrameCnt: number;
  delayInfo: any;
  downloadDelay: number;
  framesReceived: number;
  framesDropped: number;
  framesDecoded: number;
  pliCnt: number;
  nackCnt: number;
  firCnt: number;
}

export interface SfuInfo {
  connectionId?: string;
  ipAddress?: string;
  videoPort?: number;
  audioPort?: number;
  aSsrc?: number;
  vSsrc?: number;
}

export const RTCStreamEvent: Record<string, string> = {
  PLAYER_STATE_TRACE: 'player-state-trace'
}

export interface TimeObject {
  year: string;
  month: string;
  day: string;
  hour: string;
  min: string;
  sec: string;
  millsec: string;
  zoneOff: string;
}

export const HWLLSEvents: Record<string, string> = {
  MEDIA_STATISTIC: 'media-statistic',
  NET_QUALITY: 'network-quality',
  VIDEO_INTERRUPTED: 'video-broken',
  AUDIO_INTERRUPTED: 'audio-broken',
  VIDEO_RECOVERY: 'video-recovery',
  AUDIO_RECOVERY: 'audio-recovery',
  AUDIO_START: 'audio-start',
  VIDEO_START: 'video-start',
  VIDEO_STUCK: 'video_stuck',
  FULLSCREEN_STATUS_CHANGED: 'fullscreen-status-changed'
}

export interface StatisticBase {
  mediaType: MediaType;
  codec: string;
  bitRate: number;
  jitter?: number;
  bytesReceived?: number; // 已接收字节数
  packetsReceived?: number; // 已接收包数
  packetsLost?: number; // 丢包数
  freeze200Count?: number;
  freeze200Duration?: number;
}

export interface VideoStatistic extends StatisticBase {
  frameRate: number; // 帧率
  width: number; // 视频宽度
  height: number; // 视频高度
  freeze600Count?: number;
  freeze600Duration?: number;
}

export interface StatisticInfo {
  video?: VideoStatistic;
  audio?: StatisticBase;
}

export const enum NetworkQualityTypes {
  NETWORK_QUALITY_INIT = -1,
  NETWORK_QUALITY_UNKNOW = 0,
  NETWORK_QUALITY_GREAT,
  NETWORK_QUALITY_GOOD,
  NETWORK_QUALITY_DEFECTS,
  NETWORK_QUALITY_WEAK,
  NETWORK_QUALITY_BAD,
  NETWORK_QUALITY_DISCONNECT
}

export interface StreamParams {
  accessAddr?: string;
  routeType?: RouteType;
  streamDomain?: string;
  appName?: string;
  streamName?: string;
  sessionId?: string;
}

export enum RouteType {
  'DNS',
  'GSLB'
}

export interface PullMediaParams {
  keyInfoMsg?: any;
  svrSid?: string;
  svrsig?: string;
  streamUrl?: string;
  clientAddr?: string;
}

export interface PlayTrackKpi {
  startPlayTime: number;
  endPlayTime: number;
  glsbRequest?: number;
  glsbRespone?: number;
  negotiateSDPRequest: number;
  negotiateSDPRespone: number;
  iceCandidateStart: number;
  iceCandidateComplete: number;
  icePairStart: number;
  iceChecking?: number;
  iceComplete?: number;
  dtlsComplete?: number;
  audioPlaySucc: number;
  videoPlaySucc: number;
  videoTimeOut: number;
  audioTimeOut: number;
}

export interface LoadingConfig {
  netQualityLoading?: boolean;
  netQualityLoadingThreshold?: number;
  frameStuckLoading?: boolean;
  frameStuckThreshold?: number;
}

export interface StreamInterruptRetry {
  enable?: boolean;
  retryInterval?: number;
  retryTimes?: number;
}

export enum PlayerViewMode {
  CONTAIN = 'contain',
  FILL = 'fill',
  COVER = 'cover'
}

export enum MediaFormat {
  WEBRTC = 'webrtc',
  HLS = 'hls',
  FLV = 'flv'
}

export enum DomainPolicy {
  USER_DOMIAN,
  SHARE_DOMAIN
}

export enum SchedulePolicy {
  DNS = "DNS",
  HTTPDNS = "HTTPDNS"
}

export enum RetryNumber {
  SHARE_DOMAIN_RETRY_TIMES = 2,
  USER_DOMAIN_RETRY_TIMES = 3
}