export enum MediaDirection {
  'SEND_RECV' = 'sendrecv',
  'SEND_ONLY' = 'sendonly',
  'RECV_ONLY' = 'recvonly',
  'INACTIVE' = 'inactive'
}

export const audioPayloadTypes = {
  opusPayload: 116,
}
export const videopayloadTypes = {
  rtxPayLoad: 107,
  h264PayLoad: 109,
  redPayLoad: 110,
  ulpfecPayLoad: 112
}

export enum RTCMediaCodec {
  H264 = 'H264',
  OPUS = 'opus'
}
