import { LoggerFactory } from "src/logger/LoggerFactory"

const parameterValidKeys = {
  'OPS_STATISC_ENABLE': 'OPS_STATISC_ENABLE',
  "LOADING_CONFIG": "LOADING_CONFIG",
  "ACCESS_DOMAIN": "ACCESS_DOMAIN",
  "DNS_QUERY_ENABLE": "DNS_QUERY_ENABLE",
  "GLSB_DOMAIN": "GLSB_DOMAIN"
}
const module_ = 'ParametersUtil'

class ParametersUtil {
  private parameters: Map<string, any>
  constructor() {
    this.parameters = new Map<string, any>()
  }

  setParameter(parameterKey: string, parameterValue: any): boolean {
    if (!parameterKey) {
      LoggerFactory.error(module_, 'setParameter, parameterKey is null')
      return false
    }
    try {
      LoggerFactory.debug(module_, `setParameter, parameterKey: ${parameterKey}, parameterValue: ${parameterValue}`)
      this.parameters.set(parameterKey.toLowerCase(), parameterValue)
      return true
    } catch (error) {
      LoggerFactory.error(module_, `setParameter occur error: ${error}`)
      return false
    }
  }

  getParameter(parameterKey: string): any {
    try {
      if (!parameterKey || !this.parameters.has(parameterKey.toLowerCase())) {
        return null
      }
      return this.parameters.get(parameterKey.toLowerCase())
    } catch (error) {
      LoggerFactory.error(module_, `getParameter occur error: ${error}`)
      return null
    }
  }
}

const parametersUtil = new ParametersUtil()
export { parametersUtil, parameterValidKeys }
