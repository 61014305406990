export const configs = {
  "SDK_VERSION": "2.4.0",
  "COMMIT_ID": "df77da8",
  "NEGOTIATE_SDP_URL_PATH": "/webrtc/v1/pullstream",
  "STOP_PLAY_URL_PATH": "/webrtc/v1/stopstream",
  "OPS_REPORT_DOMAIN": "log-collection-ap-southeast-3.rocket-cdn.com",
  "SHARE_PULL_DOMAIN": ["live-lll-overseas.huaweicloud.com", "live-lll-overseas.huaweicloud.com"],
  "GSLB_DOMAIN": "cloudlive-gslb-overseas.livehwc3.cn",
  "GSLB_REQUEST_TIMEOUT": 4000,
  "DNS_REQUEST_TIMEOUT": 3000,
  "PULLSTREAM_REQUEST_TIMEOUT": 6000,
  "REQUEST_DEFAULT_TIMEOUT": 5000,
  "OPS_AUTH_TOKEN": "Token 1dc3549b538515311e23d68684a67fb93341f3f5",
  "OPS_REPORT_INTERVAL": 5000,
  "STATISTIC_INTERVAL": 100,
  "PUBLIC_DNS_SERVER": ["dns.google", "cloudflare-dns.com"]
}
