import CommonUtil from "src/utils/CommonUtil"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"

export type ExecutableFunction = (...args) => any

const module_ = 'FunctionUtil'

type AsyncRetryFunc = (tryNumber: any) => any

export class FunctionUtil {
  /**
   * 提供promise超时方法
   * @param promise
   * @param timeout
   */
  static callWithTimeout(func: any, timeout: number): Promise<any> {
    let reject
    const timeoutPromise_ = new Promise((resolve, x) => {
      reject = x
    })
    let timeoutTimer = null
    if (timeout) {
      timeoutTimer = setTimeout(() => {
        clearTimeout(timeoutTimer)
        reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR))
      }, timeout)
    }
    return Promise.race([func, timeoutPromise_]).finally(() => {
      clearTimeout(timeoutTimer)
    })
  }

  /**
   * 提供重试功能的方法，支持中断功能
   *
   * @param promise
   * @param retryWhenSuccess: 如果异步请求成功，是否继续重试。应用到：比如心跳的场景，不需要获取具体的响应消息
   * @param retryTimes: 如果为-1 表示无限重试
   * @param sleep
   * @param interruptHandler?
   */
  static async callWithRetryTimes(
    promise: AsyncRetryFunc,
    retryWhenSuccess: boolean,
    retryTimes: number,
    sleep: ExecutableFunction | number,
    interruptHandler?: ExecutableFunction): Promise<any> {

    let leftTimes = retryTimes >= 0 ? retryTimes + 1 : -1
    let tryNumber = 1
    let isInterrupted = false

    // eslint-disable-next-line no-unmodified-loop-condition
    while (leftTimes === -1 || leftTimes) {
      let err: any
      let rsp: any
      try {
        rsp = await promise(tryNumber)
      } catch (error) {
        err = error
        isInterrupted = interruptHandler && interruptHandler(err)
        LoggerFactory.warn(module_, `callWithRetryTimes, func:${promise.name}, isInterrupted:${isInterrupted},` +
          `retryTimes:${retryTimes}, left times:${leftTimes === -1 ? -1 : leftTimes - 1}, tryNumber:${tryNumber}, err: ${err}`)
      } finally {
        const needRetry: boolean = (err || (!err && retryWhenSuccess)) && (!isInterrupted) && (leftTimes === -1 || --leftTimes > 0)
        if (!needRetry) {
          if (!err) {
            // eslint-disable-next-line no-unsafe-finally
            return rsp
          }
          // eslint-disable-next-line no-unsafe-finally
          throw err
        } else {
          const delay = typeof sleep === 'number' ? sleep : sleep(err)
          await CommonUtil.sleep(delay)
          tryNumber++
        }
      }
    }
  }
}
