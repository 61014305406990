import EventEmitter from "events"
import { HWLLSEvents, PosterMode } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import System from "src/system/System"
import commonUtil from "src/utils/CommonUtil"
const module_ = 'PlayerUtils'
export class PlayerAdapter {
  public static async multiPlatformAdapter(): Promise<void> {
    return new Promise(resolve => {
      // ios设备微信内置浏览器wkwebview处理逻辑
      if (commonUtil.isWKWebview()) {
        // @ts-ignore
        const weixinJSBridge = window.WeixinJSBridge
        if (weixinJSBridge) {
          LoggerFactory.info(module_, `multiPlatformAdapter handle begin`)
          weixinJSBridge.invoke('getNetworkType', {}, () => {
            resolve()
          }, false)
        } else {
          document.addEventListener("WeixinJSBridgeReady", () => {
            LoggerFactory.info(module_, `multiPlatformAdapter WeixinJSBridgeReady,  handle begin`)
            weixinJSBridge.invoke('getNetworkType', {}, () => {
              resolve()
            }, false)
          })
        }
      } else {
        resolve()
      }
    })
  }
}

export class LoadingMask {
  private loadingParentDiv: any
  private loadingTimer = {
    timer: null,
    lastLoadingStatus: ''
  }

  constructor(playerDiv: HTMLElement) {
    this.loadingParentDiv = playerDiv
  }

  public loadingMask(isShow: boolean) {
    let loadingMaskElement = this.loadingParentDiv.querySelector('#loadingMask')
    if (!loadingMaskElement && isShow) {
      loadingMaskElement = commonUtil.createLoadingMask()
      this.loadingParentDiv.appendChild(loadingMaskElement)
    }

    if (loadingMaskElement) {
      const targetDisPlayStatus = isShow ? 'block' : 'none'
      if (this.loadingTimer.timer) {
        this.loadingTimer.lastLoadingStatus = targetDisPlayStatus
        return
      }
      if (loadingMaskElement.style.display !== targetDisPlayStatus) {
        loadingMaskElement.style.display = targetDisPlayStatus
      }
      if (!isShow) {
        return
      }
      this.loadingTimer.timer = setTimeout(() => {
        clearTimeout(this.loadingTimer.timer)
        this.loadingTimer.timer = null
        if (this.loadingTimer.lastLoadingStatus === 'none') {
          loadingMaskElement.style.display = 'none'
        }
        this.loadingTimer.lastLoadingStatus = ''
      }, 300)
    }
  }

  public stopLoadingMask() {
    clearTimeout(this.loadingTimer.timer)
    this.loadingTimer.timer = null
    this.loadingTimer.lastLoadingStatus = ''
    const loadingMask = this.loadingParentDiv.querySelector('#loadingMask')
    if (loadingMask) {
      this.loadingParentDiv?.removeChild(loadingMask)
    }
  }
}

export class PosterMask {
  private static preLoadImages: Map<string, any> = new Map<string, any>()

  public static preLoadPoster(url: string): void {
    if (PosterMask.preLoadImages.has(url)) {
      return
    }

    const posterDiv = document.createElement('div')
    posterDiv.setAttribute('id', 'poster_div')
    posterDiv.style.width = "100%"
    posterDiv.style.height = "100%"
    posterDiv.style.position = "absolute"
    posterDiv.style.display = 'none'
    posterDiv.style['z-index'] = 98
    posterDiv.style['justify-content'] = 'center'
    posterDiv.style['align-items'] = 'center'
    posterDiv.style['overflow'] = 'hidden'


    const poster = new Image()
    poster.src = url
    posterDiv.appendChild(poster)

    PosterMask.preLoadImages.set(url, posterDiv)
    if (PosterMask.preLoadImages.size > 5) {
      for (const key of PosterMask.preLoadImages.keys()) {
        PosterMask.preLoadImages.delete(key)
        break
      }
    }
    poster.onload = () => {
      posterDiv.style['background-color'] = '#000'
      LoggerFactory.info(module_, `preLoadPoster success`)
    }
    poster.onerror = () => {
      LoggerFactory.error(module_, `preLoadPoster failed`)
      PosterMask.disPlayPoster(false, url)
      PosterMask.preLoadImages.delete(url)
    }
  }

  public static attachPoster(parentElement: HTMLElement, posterUrl: string, mode: PosterMode): void {
    const poster = PosterMask.preLoadImages.get(posterUrl)
    if (!poster) {
      return
    }

    const image = poster.querySelector('img')
    if (mode === PosterMode.FILL) {
      image.style.width = '100%'
      image.style.height = '100%'
    } else {
      image.style.width = 'auto'
      image.style.height = 'auto'
    }
    if (!parentElement.querySelector('#poster_div img')) {
      parentElement.appendChild(poster)
    }
  }

  public static detachPoster(parentElement: HTMLElement, posterUrl: string): void {
    const poster = PosterMask.preLoadImages.get(posterUrl)
    if (!poster) {
      return
    }
    parentElement.removeChild(poster)
    poster.style.display = 'none'
  }

  public static disPlayPoster(disPlay: boolean, posterUrl: string): void {
    const poster = PosterMask.preLoadImages.get(posterUrl)
    if (!poster) {
      return
    }
    if (disPlay) {
      poster.style.display = 'flex'
    } else {
      poster.style.display = 'none'
    }
  }
}

export class FullScreen {
  private event: EventEmitter
  private isFullScreen: boolean
  private supportFullScreenApi = {}
  private fullScreenApiMap = [["requestFullscreen", "exitFullscreen", "fullscreenElement", "fullscreenEnabled", "fullscreenchange", "fullscreenerror"], ["webkitRequestFullscreen", "webkitExitFullscreen", "webkitFullscreenElement", "webkitFullscreenEnabled", "webkitfullscreenchange", "webkitfullscreenerror"], ["webkitRequestFullScreen", "webkitCancelFullScreen", "webkitCurrentFullScreenElement", "webkitCancelFullScreen", "webkitfullscreenchange", "webkitfullscreenerror"], ["mozRequestFullScreen", "mozCancelFullScreen", "mozFullScreenElement", "mozFullScreenEnabled", "mozfullscreenchange", "mozfullscreenerror"], ["msRequestFullscreen", "msExitFullscreen", "msFullscreenElement", "msFullscreenEnabled", "MSFullscreenChange", "MSFullscreenError"]]
  private videoElement: any

  constructor() {
    this.isFullScreen = false
    for (const fullScreenApi of this.fullScreenApiMap) {
      if (fullScreenApi[1] in document) {
        for (let index = 0; index < this.fullScreenApiMap[0].length; index++) {
          this.supportFullScreenApi[this.fullScreenApiMap[0][index]] = fullScreenApi[index]
        }
        break
      }
    }
    if (Object.keys(this.supportFullScreenApi).length < 1) {
      this.supportFullScreenApi = {
        "requestFullscreen": "webkitRequestFullscreen",
        "exitFullscreen": "webkitExitFullscreen",
        "fullscreenElement": "webkitFullscreenElement",
        "fullscreenEnabled": "webkitFullscreenEnabled",
        "fullscreenchange": "webkitfullscreenchange",
        "fullscreenerror": "webkitfullscreenerror"
      }
    }
    this.addFullScreenEventsListener()
  }

  public enterFullScreen(element: any, videoElement: any, event: EventEmitter): void {
    this.videoElement = videoElement
    this.event = event
    try {
      if (this.supportFullScreenApi['requestFullscreen']) {
        if (System.isSafari() && videoElement['webkitEnterFullscreen']) {
          videoElement['webkitEnterFullscreen']()
          return
        }

        if (element[this.supportFullScreenApi['requestFullscreen']]) {
          LoggerFactory.info(module_, `enter fullScreen with ${this.supportFullScreenApi['requestFullscreen']} on player element.`)
          element[this.supportFullScreenApi['requestFullscreen']]()
          return
        }

        if (this.supportFullScreenApi['requestFullscreen'] && videoElement[this.supportFullScreenApi['requestFullscreen']]) {
          LoggerFactory.info(module_, `enter fullScreen with ${this.supportFullScreenApi['requestFullscreen']} on video element.`)
          videoElement[this.supportFullScreenApi['requestFullscreen']]()
        }
      }
    } catch (err) {
      LoggerFactory.error(module_, `enterFullScreen occur error: ${err}.`)
    }
  }

  public exitFullScreen(): void {
    try {
      if (this.supportFullScreenApi['exitFullscreen'] && document[this.supportFullScreenApi['exitFullscreen']]) {
        LoggerFactory.info(module_, `exit fullScreen with ${this.supportFullScreenApi['exitFullscreen']}.`)
        document[this.supportFullScreenApi['exitFullscreen']]()
      }
    } catch (err) {
      LoggerFactory.error(module_, `exitFullScreen occur error: ${err}.`)
    }
  }

  public getFullScreenStatus(): boolean {
    return this.isFullScreen
  }

  private addFullScreenEventsListener() {
    if (Object.keys(this.supportFullScreenApi).length < 1) {
      LoggerFactory.error(module_, `addFullScreenEventsListener failed.`)
      return
    }
    const fullScreenChangeCB = () => {
      if (!System.isSafari()) {
        if (document[this.supportFullScreenApi['fullscreenElement']]) {
          LoggerFactory.info(module_, `trigger enter-full-screen event.`)
          this.isFullScreen = true
          this.event.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
            isFullScreen: true,
            isPause: this.videoElement.paused
          })
        } else {
          LoggerFactory.info(module_, `trigger exit-full-screen event.`)
          this.isFullScreen = false
          this.event.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
            isFullScreen: false,
            isPause: this.videoElement.paused
          })
        }
        return
      }
      if (this.isFullScreen !== !!this.videoElement?.webkitDisplayingFullscreen) {
        if (this.videoElement?.webkitDisplayingFullscreen) {
          LoggerFactory.info(module_, `trigger enter-full-screen event.`)
          this.isFullScreen = true
          this.event.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
            isFullScreen: true,
            isPause: this.videoElement.paused
          })
        } else {
          LoggerFactory.info(module_, `trigger exit-full-screen event.`)
          this.isFullScreen = false
          this.event.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
            isFullScreen: false,
            isPause: this.videoElement.paused
          })
        }
      }
    }
    if (System.isSafari()) {
      setInterval(fullScreenChangeCB, 100)
    } else {
      document.addEventListener(this.supportFullScreenApi['fullscreenchange'], fullScreenChangeCB)
    }
  }
}

const fullScreen = new FullScreen()
export default fullScreen