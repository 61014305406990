import { parse, SessionDescription, write, parseParams, ParamMap, MediaDescription } from 'sdp-transform'
import { audioPayloadTypes, MediaDirection, RTCMediaCodec, videopayloadTypes } from 'src/sdp/ISdp'
import { MediaType, SfuInfo } from 'src/common/ObjDefinition'
import { LoggerFactory } from 'src/logger/LoggerFactory'
import System from 'src/system/System'

// 单一 NALU 单元模式
const packetizationModeNonInterleaved = 1
// SDP中的属性类型
const attrTypePacketizationMode = 'packetization-mode'
const baselineProfileLevelId = '42e01f'
const attrTypeProfileLevelId = 'profile-level-id'
const attrTypeMaxBr = 'max-br'
const videoMaxBrValue = '5000000'
const usefulALines = ['groups', 'media']
const usefulMLines = ['candidates', 'connection', 'direction', 'mid', 'payloads', 'port', 'protocol', 'rtp', 'type', 'fmtp', 'ssrcs']

export class SDPEditor {
  protected readonly module_ = 'sdp'

  public printSdpInfo(sdp: string): string {
    const sdpDes = this.parseSdp(sdp)
    if (!sdpDes) {
      return undefined
    }
    for (const aline in sdpDes) {
      if (!Object.prototype.hasOwnProperty.call(sdpDes, aline)) {
        continue
      }
      if (!usefulALines.includes(aline)) {
        delete sdpDes[aline]
      }
      if (aline === 'media') {
        sdpDes[aline].forEach(media => {
          for (const mline in media) {
            if (!usefulMLines.includes(mline)) {
              delete media[mline]
            }
          }
        })
      }
    }
    return LoggerFactory.shieldIpAddress(write(sdpDes))
  }

  public containsValidVideoPayload(offerSdp: string): boolean {
    LoggerFactory.debug(this.module_, `containsValidVideoPayload, offerSdp: ${this.printSdpInfo(offerSdp)}`)
    const sdpDes = this.parseSdp(offerSdp)
    if (!sdpDes) {
      LoggerFactory.error(this.module_, `containsValidVideoPayload failed,the sdp is invalid`)
      return false
    }
    const videoMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_VIDEO)
    return videoMedias.some(videoMedia => {
      return videoMedia.rtp?.some(rtp => rtp.codec.toUpperCase() === RTCMediaCodec.H264)
    })
  }

  /**
   *转换webrtc生成的sdp为room适配的sdp
   *
   * @param {string} offerSdp
   * @returns {string}
   */
  public transformOfferSdp(offerSdp: string): string {
    LoggerFactory.info(this.module_, 'transformOfferSdp begin')

    const sdpDes = this.parseSdp(offerSdp)
    if (!sdpDes) {
      LoggerFactory.error(this.module_, `transformOfferSdp failed,the sdp is invalid`)
      return undefined
    }

    // 删除m=application部分
    this.deleteUnexpectedMedia(sdpDes)

    const videoMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_VIDEO)
    const audioMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_AUDIO)

    videoMedias.forEach(videoMedia => {
      this.transformVideoPayload(videoMedia)
      // 删除ssrc-group行，audio/video中都保留第一组ssrc
      this.deleteRetransmissionSsrc(videoMedia)
      videoMedia.direction = MediaDirection.RECV_ONLY
    })

    audioMedias.forEach(audioMedia => {
      // audio保留opus，并修改音频的payload为109
      this.transformAudioPayload(audioMedia)
      // 删除ssrc-group行，audio/video中都保留第一组ssrc
      this.deleteRetransmissionSsrc(audioMedia)
      audioMedia.direction = MediaDirection.RECV_ONLY
    })

    const resSdp = write(sdpDes)
    LoggerFactory.debug(this.module_, `transformOfferSdp success, sdp: ${this.printSdpInfo(resSdp)}`)
    return resSdp
  }

  /* sdp中添加收发ssrc范围的a行 */
  public modifySdpCandidates(sdp: string, candidate: RTCIceCandidate[]): string {
    const sdpDes: SessionDescription = this.parseSdp(sdp)
    if (!sdpDes) {
      LoggerFactory.error(this.module_, `modifySdpCandidates failed, the sdp is invalid : ${sdp}`)
      return undefined
    }
    const videoMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_VIDEO)
    const audioMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_AUDIO)
    let audioMid = ''
    let videoMid = ''

    if (videoMedias && videoMedias.length > 0) {
      this.modifyCandidate(videoMedias[0], candidate)
      videoMid = videoMedias[0].mid
      if (videoMedias.length > 1) {
        for (let idx = 0; idx < sdpDes.media.length; idx++) {
          if (sdpDes.media[idx].type !== MediaType.TRACK_TYPE_AUDIO && sdpDes.media[idx].mid !== videoMedias[0].mid) {
            delete sdpDes.media[idx]
          }
        }
      }
    }

    if (audioMedias && audioMedias.length > 0) {
      this.modifyCandidate(audioMedias[0], candidate)
      audioMid = audioMedias[0].mid
    }

    for (const group of (sdpDes.groups || [])) {
      if (group.type === 'BUNDLE') {
        group.mids = `${audioMid} ${videoMid}`
        break
      }
    }

    for (const media of (sdpDes.media || [])) {
      if (media.type !== MediaType.TRACK_TYPE_AUDIO) {
        this.processGCCType(media)
      }
    }

    const resSdp: string = write(sdpDes)
    LoggerFactory.debug(this.module_, `modifySdpCandidates success, sdp: ${this.printSdpInfo(resSdp)}`)
    return resSdp
  }

  public getSfuInfo(sdp: string): SfuInfo {
    const SFUAddr = this.parseSdp(sdp)
    const info: SfuInfo = {}
    let mediaConnection
    const audioMedias = this.getMedias(SFUAddr, MediaType.TRACK_TYPE_AUDIO)
    if (audioMedias && audioMedias.length > 0) {
      info.audioPort = audioMedias[0].candidates?.find(candidate => candidate.port !== 9)?.port || audioMedias[0].port
      mediaConnection = audioMedias[0].connection
      info.aSsrc = parseInt(String(audioMedias[0].ssrcs.find(ssrc => !!ssrc.id)?.id))
    }
    if (mediaConnection) {
      info.ipAddress = mediaConnection.ip
    }

    const videoMedias = this.getMedias(SFUAddr, MediaType.TRACK_TYPE_VIDEO)
    if (videoMedias && videoMedias.length > 0) {
      info.videoPort = videoMedias[0].candidates?.find(candidate => candidate.port !== 9)?.port || info.audioPort || videoMedias[0].port
      mediaConnection = videoMedias[0].connection
      info.vSsrc = parseInt(String(videoMedias[0].ssrcs.find(ssrc => !!ssrc.id)?.id))
    }

    if (!info.ipAddress && mediaConnection) {
      info.ipAddress = mediaConnection.ip
    }
    return info
  }
  // 转换sdp字符串为sdp对象
  protected parseSdp(strSdp: string): SessionDescription {
    if (!strSdp) {
      LoggerFactory.error(this.module_, 'parseSdp failed : sdp  is null ')
      return undefined
    }
    const sdp = parse(strSdp)

    if (sdp.media.length === 0) {
      LoggerFactory.error(this.module_, 'parseSdp failed : the media of sdp is null ')
      return undefined
    }
    return sdp
  }
  /*
  *从SDP中获取对应媒体类型的多个媒体信息
  */
  protected getMedias(sdp: SessionDescription, mediaType: string): SessionDescription['media'] {
    return sdp.media.filter(({ type }) => type === mediaType)
  }

  /**
   *删除除音视频外的其他m行
   *
   * @param {SessionDescription} sdpDes
   */
  protected deleteUnexpectedMedia(sdpDes: SessionDescription): void {
    if (!sdpDes.media) {
      return
    }
    sdpDes.media = sdpDes.media.filter(({ type }) => type === MediaType.TRACK_TYPE_VIDEO || type === MediaType.TRACK_TYPE_AUDIO)
  }

  // 仅保留第一个符合条件的h264的payload，并把payload值修改为109
  protected transformVideoPayload(videoMedia: SessionDescription['media'][number]): void {
    if (!videoMedia.rtp) {
      LoggerFactory.warn(this.module_, 'transformVideoPayload failed ,rtp is null')
      return
    }
    let retainPayload = null
    let firstPayload = null
    let firstMatch = 0
    for (const rtp of videoMedia.rtp) {
      if (rtp.codec.toUpperCase() !== RTCMediaCodec.H264) {
        continue
      }
      if (!firstPayload) {
        firstPayload = rtp.payload
        LoggerFactory.info(this.module_, `record firstPayload, firstPayload: ${firstPayload}`)
      }

      // 如果packetization-mode值为1且profile-level-id为42e01f，则不再向后查找
      const packetization = this.getPacketizationMode(videoMedia.fmtp, rtp.payload)
      const profileLevelId = this.getProfileLevelId(videoMedia.fmtp, rtp.payload)
      if (packetization === packetizationModeNonInterleaved) {
        if (firstMatch === 0) {
          firstPayload = rtp.payload
          firstMatch = 1
        }
        if (profileLevelId === baselineProfileLevelId) {
          retainPayload = rtp.payload
          LoggerFactory.info(this.module_, `set retainPayload in transformVideoPayload, retainPayload: ${retainPayload}`)
          break
        }
      }
    }

    // BUG2020090300084 修复Chrome 60.0.3112.113入会失败问题
    if (!retainPayload) {
      retainPayload = firstPayload
      LoggerFactory.info(
        this.module_,
        `no expected payload, use firstPayload as retainPayload, retainPayload: ${retainPayload}`
      )
    }

    // 没有符合条件的h264的payload
    if (!retainPayload) {
      LoggerFactory.error(this.module_, 'transformVideoPayload failed,can not find expected payload.')
      return
    }
    this.deletePayload(videoMedia, retainPayload)
    this.modifyPayload(videoMedia, videopayloadTypes.h264PayLoad)

    this.addVideoFec(videoMedia)
    // fmtp中增加max-br
    this.addFmtpAttr(videoMedia, videopayloadTypes.h264PayLoad, attrTypeMaxBr, videoMaxBrValue)
    this.addFmtpAttr(videoMedia, videopayloadTypes.h264PayLoad, 'sps-pps-idr-in-keyframe', '1')

    // fmtp中增加apt
    this.addFmtpAttr(videoMedia, videopayloadTypes.rtxPayLoad, 'apt', `${videopayloadTypes.h264PayLoad}`)
    this.addFmtpAttr(videoMedia, videopayloadTypes.rtxPayLoad, 'sps-pps-idr-in-keyframe', '1')
  }

  // 仅保留第一个符合条件的opus的payload，并把payload值修改为109
  protected transformAudioPayload(audioMedia: SessionDescription['media'][number]): void {
    if (!audioMedia.rtp) {
      LoggerFactory.warn(this.module_, 'transformAudioPayload failed, rtp is null')
      return
    }
    let retainPayload: number
    for (const rtp of audioMedia.rtp) {
      if (rtp.codec.toLowerCase() === RTCMediaCodec.OPUS) {
        retainPayload = rtp.payload
        break
      }
    }

    if (!retainPayload) {
      LoggerFactory.error(this.module_, 'transformAudioPayload failed,can not find expected payload.')
      return
    }
    this.deletePayload(audioMedia, retainPayload)
    this.modifyPayload(audioMedia, audioPayloadTypes.opusPayload)
    // 增加音频NACK
    this.addNack(audioMedia)
  }

  // 转换ssrc：删除ssrc-group行，audio/video中都删除重传ssrc
  protected deleteRetransmissionSsrc(media: SessionDescription['media'][number]): void {
    if (!media.ssrcGroups || !media.ssrcs) {
      return
    }

    // 从ssrcGroups中获取重传ssrc
    const retransmissionSsrcs: string[] = []
    media.ssrcGroups.forEach(({ ssrcs }) => {
      const retransmissionSsrc = this.getRetransmissionSsrc(ssrcs)
      if (retransmissionSsrc !== null) {
        retransmissionSsrcs.push(retransmissionSsrc)
      }
    })
    if (retransmissionSsrcs.length === 0) {
      return
    }

    media.ssrcGroups = undefined
    media.ssrcs = media.ssrcs.filter(({ id }) => !retransmissionSsrcs.includes(id.toString()))
  }

  // 根据获取payload对应的packetization-mode值  a=fmtp:102 level-asymmetry-allowed=1;packetization-mode=1;profile-level-id=42001f
  private getPacketizationMode(fmtps: SessionDescription['media'][number]['fmtp'], payload: number): number {
    if (!fmtps) {
      return packetizationModeNonInterleaved
    }
    for (const fmtp of fmtps) {
      if (fmtp.payload !== payload) {
        continue
      }
      // 当未设置packetization-mode时，默认值为1
      if (!fmtp.config.includes(attrTypePacketizationMode)) {
        return packetizationModeNonInterleaved
      }
      const configMap: ParamMap = parseParams(fmtp.config)

      if (configMap[attrTypePacketizationMode] === null) {
        return packetizationModeNonInterleaved
      }
      return parseInt(String(configMap[attrTypePacketizationMode]), 10)
    }
    return packetizationModeNonInterleaved
  }

  private getProfileLevelId(fmtps: SessionDescription['media'][number]['fmtp'], payload: number): string {
    if (!fmtps) {
      return ''
    }
    for (const fmtp of fmtps) {
      if (fmtp.payload !== payload) {
        continue
      }
      if (!fmtp.config.includes(attrTypeProfileLevelId)) {
        return ''
      }
      const configMap: ParamMap = parseParams(fmtp.config)

      if (!configMap[attrTypeProfileLevelId]) {
        return ''
      }
      return String(configMap[attrTypeProfileLevelId])
    }
    return ''
  }

  // 删除不需要的payload
  private deletePayload(media: SessionDescription['media'][number], retainPayload: number): void {
    if (media.rtp) {
      media.rtp = media.rtp.filter(({ payload }) => payload === retainPayload)
    }
    if (media.rtcpFb) {
      media.rtcpFb = media.rtcpFb.filter(({ payload }) => payload === retainPayload)
    }
    if (media.fmtp) {
      media.fmtp = media.fmtp.filter(({ payload }) => payload === retainPayload)
    }
    media.payloads = retainPayload.toString()
  }
  // 修改为仅剩的唯一一个payload为目标值
  private modifyPayload(media: SessionDescription['media'][number], targetPayload: number): void {
    if (media.rtp) {
      media.rtp.forEach((rtp) => {
        rtp.payload = targetPayload
      })
    }
    if (media.rtcpFb) {
      media.rtcpFb.forEach((rtcpFb) => {
        rtcpFb.payload = targetPayload
      })
    }
    if (media.fmtp) {
      media.fmtp.forEach((fmtp) => {
        fmtp.payload = targetPayload
      })
    }

    media.payloads = targetPayload.toString()
  }

  /* 添加视频fec */
  private addVideoFec(media: SessionDescription['media'][number]) {
    if (!media.rtp) {
      media.rtp = []
    }
    media.rtp.push({ payload: videopayloadTypes.rtxPayLoad, codec: 'rtx', rate: 90000 })
    media.rtp.push({ payload: videopayloadTypes.redPayLoad, codec: 'red', rate: 90000 })
    media.rtp.push({ payload: videopayloadTypes.ulpfecPayLoad, codec: 'ulpfec', rate: 90000 })
    media.payloads = `${videopayloadTypes.rtxPayLoad} ${media.payloads} ${videopayloadTypes.redPayLoad} ${videopayloadTypes.ulpfecPayLoad}`
  }

  // fmtp中增加属性
  private addFmtpAttr(media: SessionDescription['media'][number], payload: number, attr: string, value: string): void {
    if (!media.fmtp || media.fmtp.length === 0) {
      media.fmtp = []
      media.fmtp.push({ payload: payload, config: attr + '=' + value })
      return
    }
    const targetFmpt = media.fmtp.find(fmtp => fmtp.payload === payload)

    if (!targetFmpt) {
      media.fmtp.push({ payload: payload, config: attr + '=' + value })
    } else {
      const configMap: ParamMap = parseParams(targetFmpt.config)
      if (configMap[attr]) {
        return
      }

      targetFmpt.config = targetFmpt.config + ';' + attr + '=' + value
    }
  }

  // 向media中增加nack，还没有测试过
  private addNack(media: SessionDescription['media'][number]): void {
    const mediaPayload = media.rtp[0].payload
    if (!media.rtcpFb) {
      media.rtcpFb = []
    }
    media.rtcpFb.push({ payload: mediaPayload, type: 'ccm', subtype: 'fir' })
    media.rtcpFb.push({ payload: mediaPayload, type: 'nack' })
    media.rtcpFb.push({ payload: mediaPayload, type: 'nack', subtype: 'pli' })
  }

  private modifyCandidate(media: SessionDescription['media'][number], candidates: any[]): void {
    if (!media.candidates) {
      const candidate = candidates?.find(candidate => `${media.mid}` === `${candidate.sdpMid}`)
      if (candidate) {
        const generation = /.*generation\s(\d+).*/.exec(candidate.candidate) || []
        const networkId = /.*network\-id\s(\d+).*/.exec(candidate.candidate) || []
        const networkCost = /.*network\-cost\s(\d+).*/.exec(candidate.candidate) || []
        media.candidates = media.candidates || []
        media.candidates.push({
          foundation: candidate.foundation,
          component: candidate.component === 'rtp' ? 1 : 2,
          transport: candidate.protocol,
          priority: candidate.priority,
          ip: candidate.address,
          port: candidate.port,
          type: candidate.type,
          raddr: candidate.relatedAddress,
          rport: candidate.relatedPort,
          tcptype: candidate.tcpType,
          generation: generation.length === 2 ? parseInt(generation[1]) : undefined,
          'network-id': networkId.length === 2 ? parseInt(networkId[1]) : undefined,
          'network-cost': networkCost.length === 2 ? parseInt(networkCost[1]) : undefined,
        })
      }
    }
  }

  // 从ssrcgroup的ssrcs中获取重传流ssrc，比如a=ssrc-group:FID 258413418 2763591883，则入参ssrcs为258413418 2763591883
  private getRetransmissionSsrc(ssrcs: string): string {
    if (!ssrcs) {
      LoggerFactory.warn(this.module_, 'getRetransmissionSsrc failed, ssrcs is null')
      return undefined
    }
    const ssrcArray = ssrcs.split(' ')

    const arrayLen = 2
    if (ssrcArray.length !== arrayLen) {
      LoggerFactory.warn(this.module_, `getRetransmissionSsrc failed, ssrcs(${ssrcs}) is invalid`)
      return undefined
    }
    return ssrcArray[1]
  }

  private processGCCType(videoMedia: MediaDescription): void {
    if (System.isFirefox() && System.getBrowserVersion() < 100) {
      let targetIdx = videoMedia.rtcpFb.findIndex(fb => fb.type === 'transport-cc')
      if (targetIdx >= 0) {
        videoMedia.rtcpFb.splice(targetIdx, 1)
      }

      targetIdx = videoMedia.ext.findIndex(ext => /transport-wide-cc/i.test(ext.uri))
      if (targetIdx >= 0) {
        videoMedia.ext.splice(targetIdx, 1)
      }
    }
  }
}
