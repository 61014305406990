import EventEmitter from "events"
import commonUtil from "src/utils/CommonUtil"

export class InternalSysEventsBus {
  private eventBus: Map<string, EventEmitter>
  private eventHandlers = {
    visibilitychange: () => {
      let eventName = 'FOREGROUND'
      if (document.hidden) {
        eventName = 'BACKGROUND'
      }
      for (const event of this.eventBus.values()) {
        event.emit(eventName)
      }
    }
  }

  private eventListeners = {
    visibilitychange: (action: 'ADD' | 'REMOVE') => {
      if (action === 'ADD') {
        document.addEventListener("visibilitychange", this.eventHandlers['visibilitychange'])
      } else {
        document.removeEventListener("visibilitychange", this.eventHandlers['visibilitychange'])
      }
    }
  }
  constructor() {
    this.eventBus = new Map<string, EventEmitter>()
    this.addListener('visibilitychange', commonUtil.isMobileDevice.bind(commonUtil))
  }

  public register(event: EventEmitter): string {
    const eventUniqueId = commonUtil.generateRandomId(32)
    this.eventBus.set(eventUniqueId, event)
    return eventUniqueId
  }

  public unregister(eventUniqueId: string): void {
    if (!eventUniqueId) {
      return
    }
    this.eventBus.delete(eventUniqueId)
  }

  public addListener(listenerName: string, condition?: any): void {
    if (condition && !condition()) {
      return
    }
    if (this.eventListeners[listenerName]) {
      this.eventListeners[listenerName]('ADD')
    }
  }

  public removeListener(listenerName: string): void {
    if (this.eventListeners[listenerName]) {
      this.eventListeners[listenerName]('REMOVE')
    }
  }
}
export const SysEventsBus = new InternalSysEventsBus()