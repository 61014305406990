import { ErrorCode, HwLLSError } from 'src/ecode/ECode'
import { HLLSTrack, PlayResult } from 'src/stream/HLLSTrack'
import { api, asyncApi } from "src/common/APIAnnotation"
import { MediaType, PlayerViewMode, PlayOptions, RTCStreamEvent } from 'src/common/ObjDefinition'
import { LoggerFactory } from 'src/logger/LoggerFactory'
import EmitterAble from 'src/common/EmitterAble'
import EventEmitter from 'events'
import { HWLLSStat } from 'src/stat/HWLLSStat'

const module_ = 'RemoteStream'
/**
 * 模型说明：
 * 一个流，包含多个track.
 * 对于辅流，则仅仅包含一个视频track。其音频在主流的音频track上
 * 对于主流，则包含多个视频流track，和一个音频流track
 */
export class RemoteStream extends EmitterAble {
  protected videoTrack: HLLSTrack
  protected audioTrack: HLLSTrack
  private readonly traceHandler: () => void
  private playDivContainer: HTMLElement

  constructor(stat: HWLLSStat, eventEmitter: EventEmitter) {
    super(module_, stat, eventEmitter)
    this.traceHandler = this.playerStatusTrace.bind(this)
  }

  @asyncApi("Stream$play#Promise<void>#PlayOptions")
  public async play(options: PlayOptions): Promise<void> {
    if (options.objectFit) {
      if (options.objectFit && !/^cover|contain|fill$/.test(options.objectFit)) {
        LoggerFactory.error(module_, `invalid play options.objectFit: ${options.objectFit}`)
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `invalid play options.objectFit: ${options.objectFit}`)
      }
    } else {
      options.objectFit = PlayerViewMode.CONTAIN
    }
    options.muted = !!options.muted
    LoggerFactory.info(module_, `stream start to play with elementId: ${options.elementId}, options.objectFit: ${options.objectFit}, options.muted: ${options.muted}`)
    if (!options.elementId) {
      LoggerFactory.error(module_, `elementId:${options.elementId} not a invalid HTMLElement Id`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `elementId:${options.elementId} not a invalid HTMLElement Id`)
    }

    const videoElement = document.getElementById(options.elementId)
    if (!videoElement) {
      LoggerFactory.error(module_, `document.getElementById by elementId:${options.elementId} failed`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `document.getElementById by elementId:${options.elementId} failed`)
    }

    let playerDivContainer: HTMLElement = videoElement.querySelector(`#player_container`)// 音频track和视频track公用一个divContainer
    if (!playerDivContainer) {
      if (!this.playDivContainer) {
        playerDivContainer = document.createElement('div')
        playerDivContainer.setAttribute('id', 'player_container')
        playerDivContainer.style.width = "100%"
        playerDivContainer.style.height = "100%"
        playerDivContainer.style.position = "relative"
        playerDivContainer.style.overflow = "hidden"
        playerDivContainer.style['background-color'] = "#000"
        this.playDivContainer = playerDivContainer
      }
      videoElement.appendChild(this.playDivContainer)
      LoggerFactory.info(module_, `new player div`)
    } else {
      LoggerFactory.info(module_, `exist player div`)
    }

    await this.playTracks(playerDivContainer, options)
  }

  @asyncApi("Stream$resume#Promise<void>#void")
  public async resume(): Promise<void> {
    LoggerFactory.info(module_, `resume begin`)
    await Promise.all([this.resumeAudio(), this.resumeVideo()])
  }

  @api("Stream$pause#void#void")
  public pause(): void {
    LoggerFactory.info(module_, `pause begin`)
    this.pauseAudio()
    this.pauseVideo()
  }

  /**
   * 停止播放
   */
  @api("Stream$stop#void#void")
  public stop(): void {
    LoggerFactory.info(module_, `stop stream begin`)
    this.audioTrack?.stop()
    this.videoTrack?.stop()
    LoggerFactory.info(module_, `stop stream end`)
  }

  @api("Stream$muteAudio#boolean")
  public muteAudio(): boolean {
    try {
      if (this.audioTrack) {
        this.audioTrack.muteTrack()
        return true
      }
      return false
    } catch (error) {
      LoggerFactory.error(module_, `muteAudio failed: ${error}`)
      return false
    }
  }

  @api("Stream$muteVideo#boolean")
  public muteVideo(): boolean {
    try {
      if (this.videoTrack) {
        this.videoTrack.muteTrack()
        return true
      }
      return false
    } catch (error) {
      LoggerFactory.error(module_, `muteVideo failed: ${error}`)
      return false
    }
  }

  @api("Stream$unmuteAudio#boolean")
  public unmuteAudio(): boolean {
    try {
      if (this.audioTrack) {
        this.audioTrack.unmuteTrack()
        return true
      }
      return false
    } catch (error) {
      LoggerFactory.error(module_, `unmuteAudio failed: ${error}`)
      return false
    }
  }

  @api("Stream$unmuteVideo#boolean")
  public unmuteVideo(): boolean {
    try {
      if (this.videoTrack) {
        this.videoTrack.unmuteTrack()
        return true
      }
      return false
    } catch (error) {
      LoggerFactory.error(module_, `unmuteVideo failed: ${error}`)
      return false
    }
  }

  @api("Stream$setAudioVolume#void#number")
  public setAudioVolume(volume: number): void {
    if (this.audioTrack) {
      this.audioTrack.setAudioVolume(volume)
    }
  }

  public getAudioLevel(): number {
    if (this.audioTrack) {
      return this.audioTrack.getAudioLevel()
    }
    return 0
  }

  public getAudioHRTCTrack(): HLLSTrack {
    return this.audioTrack
  }

  public getVideoHRTCTrack(): HLLSTrack {
    return this.videoTrack
  }

  public hasAudio(): boolean {
    return !!this.getAudioHRTCTrack()
  }

  public hasVideo(): boolean {
    return !!this.getVideoHRTCTrack()
  }

  /**
   * 对已经存在的远端流设置connection.ontrack返回的物理track信息
   * @param track： 物理track
   * @param trackId： 视频track id
   */
  public addRemoteTrack(track: MediaStreamTrack): void {
    if (track.kind === MediaType.TRACK_TYPE_AUDIO) {
      if (this.audioTrack) {
        this.audioTrack.replaceTrack(track)
      } else {
        this.audioTrack = new HLLSTrack({
          trackType: MediaType.TRACK_TYPE_AUDIO,
          track: track,
          event: this.eventEmitter
        })
      }
    } else {
      if (this.videoTrack) {
        this.videoTrack.replaceTrack(track)
      } else {
        this.videoTrack = new HLLSTrack({
          trackType: MediaType.TRACK_TYPE_VIDEO,
          track: track,
          event: this.eventEmitter
        })
      }
    }
    LoggerFactory.info(module_, `addRemoteTrack for ${track.kind} success`)
  }

  public destory(): void {
    this.off(RTCStreamEvent.PLAYER_STATE_TRACE, this.traceHandler, true)
    this.stop()
    this.playDivContainer?.remove()
    this.playDivContainer = null
  }

  public resumeAudio(): Promise<void> {
    return this.audioTrack?.resume()
  }

  public resumeVideo(): Promise<void> {
    return this.videoTrack?.resume()
  }

  public pauseAudio(): void {
    this.audioTrack?.pause()
  }

  public pauseVideo(): void {
    this.videoTrack?.pause()
  }

  public exitFullScreen(): void {
    this.videoTrack?.exitFullScreen()
  }

  public enterFullScreen(): void {
    this.videoTrack?.enterFullScreen()
  }

  private playerStatusTrace(event) {
    LoggerFactory.debug(module_, `playerStatusTrace, ${JSON.stringify(event)}`)
  }

  private async playTracks(playerDiv: HTMLElement, options: PlayOptions) {
    const playPromises: Promise<PlayResult>[] = []
    if (options.video && this.videoTrack?.getTrack()) {
      playPromises.push(this.videoTrack.play(playerDiv, options.elementId, {
        objectFit: options.objectFit,
        showLoading: options.showLoading,
        autoPlay: options.autoPlay !== false ? true : false,
        poster: options.poster
      }))
    }
    if (options.audio && this.audioTrack?.getTrack()) {
      // 可以多次调用play，不会重复播放
      playPromises.push(this.audioTrack.play(playerDiv, options.elementId, {
        muted: options.muted,
        autoPlay: options.autoPlay !== false ? true : false
      }))
    }
    if (playPromises.length === 0) {
      LoggerFactory.warn(module_, `no available track for play`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'no available track to play.')
    }

    this.off(RTCStreamEvent.PLAYER_STATE_TRACE, this.traceHandler, true)
    this.on(RTCStreamEvent.PLAYER_STATE_TRACE, this.traceHandler, true)

    await Promise.all(playPromises).then(results => {
      const err = results.filter(result => result.error !== null)
      if (err.length > 0) {
        throw err[0].error
      }
    })
  }
}

