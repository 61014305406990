import { PlanbSDPEditor } from 'src/sdp/PlanbSDPEditor'
import { UnifiedPlanSDPEditor } from 'src/sdp/UnifiedPlanSDPEditor'
import { LoggerFactory } from 'src/logger/LoggerFactory'
import System from 'src/system/System'
import { ErrorCode, ErrorMsg, HwLLSError } from 'src/ecode/ECode'
import { ConnectionStatus, SfuInfo, WebRTCConfig } from 'src/common/ObjDefinition'
import EventEmitter from 'events'
import { HWLLSStat } from 'src/stat/HWLLSStat'
import { MediaStats } from 'src/stat/MediaStat'
import { GlobalVariableManager } from 'src/managers/GlobalVariableManager'

interface ConnectionInfo {
  connection: RTCPeerConnection;
  connectFailedTimes: number;
  disConnectDelayHandleTimer?: NodeJS.Timeout;
  candidate?: RTCIceCandidate[];
  connectionStatisticTimeOut?: NodeJS.Timeout;
}

export interface RTCIceTransportStat {
  currentRoundTripTime?: number;
  availableOutgoingBitrate?: number;
  bytesSent?: number;
  bytesReceived?: number;
}

const module_ = 'PeerConnectionsManager'
export class PeerConnectionsManager {
  private connectionId: string
  private eventEmitter: EventEmitter
  private stats: HWLLSStat
  private peerConnection: ConnectionInfo
  private sdpDescMode: 'plan-b' | 'unified-plan'
  private rtcSdp: any
  private isFireFox = System.isFirefox()

  constructor(stats: HWLLSStat, eventEmitter: EventEmitter, connectionId: string) {
    this.connectionId = connectionId
    this.eventEmitter = eventEmitter
    this.stats = stats
    if (System.isOnlySupportUnfiedPlan()) {
      this.sdpDescMode = 'unified-plan'
      this.rtcSdp = new UnifiedPlanSDPEditor()
    } else {
      this.sdpDescMode = 'plan-b'
      this.rtcSdp = new PlanbSDPEditor()
    }

    LoggerFactory.debug(module_, `ConnectionsManager, SDP mode: ${this.sdpDescMode}`)
  }

  public getConnectionId(): string {
    return this.connectionId
  }

  public isConnectionsExist(): boolean {
    return !!this.peerConnection
  }

  public isConnectionEstablished(): boolean {
    if (this.peerConnection && this.peerConnection.connection?.connectionState === 'connected') {
      return true
    }
    return false
  }

  public async initConnectionAndSdp(mediaOption: WebRTCConfig, handlers: { onTrackHandler: any }, isRetry?: boolean): Promise<void> {
    const connectionInfo = this.createPeerConnection()
    const data = await this.createOffer({ offerToReceiveAudio: mediaOption.receiveAudio, offerToReceiveVideo: mediaOption.receiveVideo })

    const transformedSdp = this.rtcSdp.transformOfferSdp(data.sdp)
    await connectionInfo.connection.setLocalDescription({ type: 'offer', sdp: transformedSdp })
    this.onConnection(connectionInfo, handlers.onTrackHandler)
    this.iceAddressCollect(connectionInfo.connection)
    if (!this.isFireFox) {
      const noTCPCandidateSdp = connectionInfo.connection.localDescription.sdp
      LoggerFactory.debug(module_, `setLocalDescription, sdp offer: ${this.rtcSdp.printSdpInfo(noTCPCandidateSdp)}`)
      await connectionInfo.connection.setLocalDescription({ type: 'offer', sdp: this.rtcSdp.transformOfferSdp(noTCPCandidateSdp) })
    }
    // 规避移动端浏览器chromium83以下版本上首次createOffer时，收集到的payload不正确的问题
    if (mediaOption.receiveVideo && System.isChrome() && !this.rtcSdp.containsValidVideoPayload(data.sdp) && !isRetry) {
      this.destroyPeerConnection()
      LoggerFactory.error(module_, 'initConnectionAndSdp, no H264 payload and try createOffer again')
      await this.initConnectionAndSdp(mediaOption, handlers, true)
    }
  }

  public modifySdpCandidates(): string {
    const peerConnectionInfo = this.peerConnection
    let offerSdp = null
    if (peerConnectionInfo) {
      offerSdp = this.rtcSdp.modifySdpCandidates(peerConnectionInfo.connection.localDescription.sdp, peerConnectionInfo.candidate)
    }
    return offerSdp
  }

  public destroyPeerConnection(removeSenders?: RTCRtpSender[]) {
    if (this.peerConnection) {
      clearTimeout(this.peerConnection.disConnectDelayHandleTimer)
      clearTimeout(this.peerConnection.connectionStatisticTimeOut)
      if (this.peerConnection.connection.signalingState !== 'closed') {
        removeSenders?.forEach(sender => this.peerConnection.connection.removeTrack(sender))
        this.peerConnection.connection.close()
        this.peerConnection.connectFailedTimes = 0
      }
      this.eventEmitter.removeAllListeners('STARTUP_MEDIA_STATS')
      MediaStats.shutDownMediaStats(this.connectionId)
      this.peerConnection = null
    }
  }

  public async handleAnswerSdpFromServer(answerSdp: string): Promise<void> {
    GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('icePairStart')

    const connection = this.peerConnection.connection
    const remoteDescription = this.rtcSdp.transformAnswerSdp(answerSdp, connection.localDescription.sdp)
    LoggerFactory.debug(module_, `handleAnswerSdpFromServer, sdp answer: ${this.rtcSdp.printSdpInfo(remoteDescription)}`)

    try {
      await connection.setRemoteDescription({ type: 'answer', sdp: remoteDescription }) // ICE + DTLS no stream
      if (this.isFireFox) {
        // Firefox浏览器在调用setRemoteDescription之前必须调用createOffer
        await connection.setLocalDescription({ type: 'offer', sdp: (await connection.createOffer()).sdp })
        await connection.setRemoteDescription({ type: 'answer', sdp: remoteDescription }) // ICE + DTLS no stream
      }
    } catch (error) {
      LoggerFactory.error(module_, `setRemoteDescription error, answer sdp invalid or offer sdp invalid: ${error}`)
      throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error?.message || 'setRemoteDescription error, answer sdp invalid or offer sdp invalid')
    }
  }

  public getSfuInfoFromSdp(sdp: string): SfuInfo {
    const sfuInfo: SfuInfo = this.rtcSdp.getSfuInfo(sdp);
    sfuInfo.connectionId = this.getConnectionId()
    return sfuInfo
  }

  public getConnection(): RTCPeerConnection {
    if (!this.isConnectionsExist()) {
      return null
    }
    return this.peerConnection?.connection || null
  }

  private async createOffer(options?: RTCOfferOptions) {
    const connection = this.peerConnection.connection
    const offerSdp = await connection.createOffer(options)
    return offerSdp
  }

  private iceCandidateListener(connectionInfo: ConnectionInfo) {
    LoggerFactory.info(module_, `iceCandidate add listener`)
    connectionInfo.connection.onicecandidate = (event) => {
      LoggerFactory.info(module_, `###onicecandidate: ${LoggerFactory.shieldIpAddress(event.candidate?.candidate)}`)
      if (!event.candidate) {
        return
      }
      if (!connectionInfo.candidate) {
        connectionInfo.candidate = []
      }
      connectionInfo.candidate.push(event.candidate)
    }
  }

  private iceRestart(connectionInfo: ConnectionInfo, handler?: any) {
    this.stats.reportConnectionStatus(ConnectionStatus.CLOSED)
    connectionInfo.connection.createOffer({
      iceRestart: true
    }).then((data) => {
      connectionInfo.connection.setLocalDescription({
        type: 'offer',
        sdp: this.rtcSdp.transformOfferSdp(data.sdp)
      })
      connectionInfo.connection.setRemoteDescription({
        sdp: connectionInfo.connection.remoteDescription.sdp,
        type: 'answer'
      })
    }).finally(() => {
      handler && handler()
    })
    connectionInfo.connectFailedTimes++
    if (connectionInfo.connectFailedTimes >= 1) {
      LoggerFactory.error(module_, `onconnectionstatechange, emit media error event.`)
      this.eventEmitter.emit('Error', {
        errCode: ErrorCode.HWLLS_MEDIA_NETWORK_ERROR,
        errDesc: ErrorMsg[ErrorCode.HWLLS_MEDIA_NETWORK_ERROR]
      })
      connectionInfo.connectFailedTimes = 0
    }
  }

  private onConnection(connectionInfo: ConnectionInfo, handler: any): void {
    connectionInfo.connection.ontrack = (e) => {
      handler(e)
    }

    connectionInfo.connection.onconnectionstatechange = () => {
      LoggerFactory.info(module_, `onconnectionstatechange, connect state is: ${connectionInfo.connection.connectionState}`)
      if (connectionInfo.connection.connectionState === 'connected') {
        GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('dtlsComplete')
        clearTimeout(connectionInfo.connectionStatisticTimeOut)
        this.eventEmitter.emit('STARTUP_MEDIA_STATS')
      }
    }
    connectionInfo.connection.oniceconnectionstatechange = async () => {
      LoggerFactory.info(module_, `oniceconnectionstatechange, iceConnectionState: ${connectionInfo.connection.iceConnectionState}`)

      if (connectionInfo.connection.iceConnectionState === 'disconnected') {
        if (connectionInfo.disConnectDelayHandleTimer) {
          return
        }
        connectionInfo.disConnectDelayHandleTimer = setTimeout(() => {
          LoggerFactory.info(module_, `oniceconnectionstatechange, disConnect state delay handler, go iceRestart: ${connectionInfo.connection.iceConnectionState}`)
          this.iceRestart(connectionInfo, () => {
            clearTimeout(connectionInfo.disConnectDelayHandleTimer)
            connectionInfo.disConnectDelayHandleTimer = null
          })
        }, 5000)
        return
      }
      clearTimeout(connectionInfo.disConnectDelayHandleTimer)
      connectionInfo.disConnectDelayHandleTimer = null
      if ((connectionInfo.connection.iceConnectionState === 'failed' || connectionInfo.connection.iceConnectionState === 'closed')) {
        LoggerFactory.info(module_, `[KPI] oniceconnectionstatechange, iceconnect failed, go iceRestart: ${connectionInfo.connection.iceConnectionState}`)
        this.iceRestart(connectionInfo)
      } else if (['connected', 'completed'].includes(connectionInfo.connection.iceConnectionState)) {
        LoggerFactory.info(module_, `[KPI] oniceconnectionstatechange, iceconnect state: ${connectionInfo.connection.iceConnectionState}`)
        GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('iceComplete')
        connectionInfo.connectFailedTimes = 0
        const protocol = await MediaStats.getCandidatePairProtocol(this.connectionId)
        this.stats.reportConnectionStatus(ConnectionStatus.CONNECTED, protocol)
      } else if (connectionInfo.connection.iceConnectionState === 'checking') {
        LoggerFactory.info(module_, `[KPI] oniceconnectionstatechange, iceconnect state: ${connectionInfo.connection.iceConnectionState}`)
        GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('iceChecking')
      }
    }
  }

  private async iceAddressCollect(connection: RTCPeerConnection): Promise<void> {
    const timeout = 6000
    LoggerFactory.info(module_, `[KPI] begin to do ice candidate`)
    GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('iceCandidateStart')
    await new Promise<void>((resolve) => {
      let timeoutId: NodeJS.Timeout = null
      const iceCheck = (): void => {
        LoggerFactory.info(module_, `[KPI] ICE collect status: ${connection.iceGatheringState}`)
        if (connection.iceGatheringState === 'complete') {
          clearTimeout(timeoutId)
          LoggerFactory.info(module_, '[KPI] ICE collect complete')
          GlobalVariableManager.getInstance(this.connectionId).updatePlayTrackKpi('iceCandidateComplete')
          connection.removeEventListener('icegatheringstatechange', iceCheck)
          resolve()
        }
      }
      connection.addEventListener('icegatheringstatechange', iceCheck)
      timeoutId = setTimeout(() => {
        LoggerFactory.error(module_, '[KPI] ICE collect fail')
        clearTimeout(timeoutId)
        connection.removeEventListener('icegatheringstatechange', iceCheck)
        resolve()
      }, timeout)
    })
  }

  private createPeerConnection(): ConnectionInfo {
    const initConfigs: RTCConfiguration = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: google
      rtcpMuxPolicy: 'require',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: google
      bundlePolicy: "max-bundle"
    }
    initConfigs['sdpSemantics'] = this.sdpDescMode

    if (this.peerConnection) {
      this.peerConnection.connection?.close()
    }
    const peerConnection = new RTCPeerConnection(initConfigs)
    const connectionInfo = {
      connection: peerConnection,
      connectFailedTimes: 0
    }
    this.peerConnection = connectionInfo
    this.iceCandidateListener(this.peerConnection)
    this.eventEmitter.removeAllListeners('STARTUP_MEDIA_STATS')
    this.eventEmitter.once('STARTUP_MEDIA_STATS', () => {
      LoggerFactory.info(module_, 'startUp Media Stats')
      MediaStats.startUpMediaStats(this)
    })
    connectionInfo['connectionStatisticTimeOut'] = setTimeout(() => {
      clearTimeout(connectionInfo['connectionStatisticTimeOut'])
      this.eventEmitter.emit('STARTUP_MEDIA_STATS')
    }, 6000)
    return this.peerConnection
  }
}
