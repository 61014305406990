import HWLLSEnter from "src/HWLLSEnter"

const instance = new HWLLSEnter()
export default {
  getVersion: instance.getVersion.bind(instance),
  checkSystemRequirements: instance.checkSystemRequirements.bind(instance),
  setParameter: instance.setParameter.bind(instance),
  createClient: instance.createClient.bind(instance),
  saveLog: instance.saveLog.bind(instance),
  setLogLevel: instance.setLogLevel.bind(instance),
  uploadLog: instance.uploadLog.bind(instance)
}