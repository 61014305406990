import { configs } from "src/config/config"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"
import commonUtil from "src/utils/CommonUtil"
import HttpRequest from "src/utils/HttpRequestUtil"
/**
 * 上报媒体打点信息
 *
 * @class HWLLSReport
 */
const FIXED_RECORDS_LENGTH = 10000
const MAX_BATCH_RECORDS = 50
const OPS_REPORT_SERVER_URL = `https://${configs.OPS_REPORT_DOMAIN}/v2/log/log_report?service=LLL`
const module_ = 'HWLLSReport'

class HWLLSReport {
  private reportInterval: any
  private records: any[]

  constructor() {
    this.records = []
    this.initEmergencyReport()
    this.regularReport(configs.OPS_REPORT_INTERVAL)
  }

  public async addRecord(param: any): Promise<void> {
    this.records.push(param)
    if (this.records.length > FIXED_RECORDS_LENGTH) {
      this.records.splice(0, this.records.length - FIXED_RECORDS_LENGTH)
    }
  }

  public immediateReportRecords(): void {
    if (this.records.length !== 0) {
      this.reportRecords(this.records)
    }
  }

  private initEmergencyReport() {
    window.onbeforeunload = () => {
      LoggerFactory.info(module_, `app is closing, emergency report`)
      if (this.records.length !== 0) {
        this.reportRecordsEmergency(this.records)
      }
    }
  }

  private async sendRequest(data: any[], failedRecords: any[]): Promise<void> {
    try {
      const respone = await HttpRequest.fetch([OPS_REPORT_SERVER_URL], {
        method: 'POST',
        body: JSON.stringify({
          request_id: commonUtil.generateStandardUuid(),
          timestamp: Math.round(commonUtil.getCurrentSyncTimestamp() / 1000),
          logs: data
        })
      }, {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': configs.OPS_AUTH_TOKEN
      })

      if (respone.httpCode && respone.data?.Code != 0) {
        LoggerFactory.error(module_, `sendRequest and get fail Respone: ${JSON.stringify(respone)}`)
        throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR)
      }
    } catch (error) {
      LoggerFactory.error(module_, `sendRequest failed`)
      failedRecords.push(...data)
    }
  }

  private async reportRecords(data: any[]): Promise<void> {
    const failedRecords = []
    const sendPromise = []
    do {
      sendPromise.push(this.sendRequest(data.splice(0, MAX_BATCH_RECORDS), failedRecords))
    } while (data.length > 0)

    Promise.all(sendPromise).finally(() => {
      this.records.push(...failedRecords)
      failedRecords.length = 0
      if (this.records.length > FIXED_RECORDS_LENGTH) {
        this.records.splice(0, this.records.length - FIXED_RECORDS_LENGTH)
      }
    })
  }

  private async reportRecordsEmergency(data: any): Promise<void> {
    if (!navigator.sendBeacon) {
      this.reportRecords(data)
      return
    }
    navigator.sendBeacon(OPS_REPORT_SERVER_URL, new Blob([JSON.stringify({
      request_id: commonUtil.generateStandardUuid(),
      timestamp: Math.round(commonUtil.getCurrentSyncTimestamp() / 1000),
      logs: data
    })], { type: 'application/json;charset=UTF-8' }))
  }

  private regularReport(delay: number): void {
    if (this.reportInterval) {
      clearInterval(this.reportInterval)
    }
    this.reportInterval = setInterval(() => {
      if (this.records.length !== 0) {
        this.reportRecords(this.records)
      }
    }, delay)
  }
}

const hwllsReport = new HWLLSReport()
export default hwllsReport
