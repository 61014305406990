export enum ErrorCode {
  HWLLS_OK = 0,
  HWLLS_ERROR_INVALID_URL = 50000000, // URL不合法
  HWLLS_ERROR_INVALID_PARAMETER = 50000001,// request json参数不合法
  HWLLS_ERROR_SERVER_CONNECT_FAIL = 50000002,// 无法连接服务端
  HWLLS_ERROR_SERVER_NO_RESPONSE = 50000003,// 服务器无响应
  HWLLS_ERROR_AUTH_FAIL = 50000004,// 鉴权失败
  HWLLS_ERROR_STREAM_NOT_EXIST = 50000005, // 请求的流不存在
  HWLLS_ERROR_WEBRTC_UNSUPPORTED = 50000006, // WEBRTC浏览器不支持
  HWLLS_MEDIA_NETWORK_ERROR = 50000007, // 媒体网络连接异常
  HWLLS_ERROR_BAD_REQUEST = 50000008,// bad Request
  HWLLS_ERROR_STREAM_INVALID_PARAMETER = 50000009,// 流信息错误
  HWLLS_ERROR_ENCODING_NOT_ACCEPTED = 500000010, // 服务端信令解压失败，或回退至老版本不支持信令压缩
  HWLLS_INTERNAL_ERROR = 50000020, // 其他内部错误
  HWLLS_BUSSINESS_DOWNGRADE = 50000021, // 601 业务降级异常
  HWLLS_PLAY_WEBRTC_RETRY_FAILED = 50000022, // 播放中断后，多次重新尝试起播超时
  HWLLS_PLAY_FLV_RETRY_FAILED = 50000023, // 播放中断后，多次重新尝试起播超时
  HWLLS_PLAY_HLS_RETRY_FAILED = 50000024, // 播放中断后，多次重新尝试起播超时

  HWLLS_ERROR_LIVE_UNSUPPORTED = 50000030, // 播放内容格式，浏览器不支持
  HWLLS_ERROR_UNEXPECTED_EOF = 50000031, // 播放内容，异常的网络EOF
  HWLLS_ERROR_MEDIA_ERROR = 50000032, // 播放媒体内容异常

  HWLLS_PLAY_NOT_ALLOW = 51000000, // 播放权限受限，需要手动触发播放
  HWLLS_PLAY_TIMEOUT = 51000001 // 播放超时，10s内没有拉到有效帧数据
}

export const ErrorMsg: Record<number, string> = {
  [ErrorCode.HWLLS_OK]: 'success',
  [ErrorCode.HWLLS_ERROR_INVALID_URL]: 'url invalid.',
  [ErrorCode.HWLLS_ERROR_INVALID_PARAMETER]: 'parameter invalid.',
  [ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL]: 'server not available or server internal error.',
  [ErrorCode.HWLLS_ERROR_SERVER_NO_RESPONSE]: 'server respone timeout.',
  [ErrorCode.HWLLS_ERROR_AUTH_FAIL]: 'server authnicate failed.',
  [ErrorCode.HWLLS_ERROR_STREAM_NOT_EXIST]: 'stream not exist.',
  [ErrorCode.HWLLS_MEDIA_NETWORK_ERROR]: 'media connection establish failed, please switch network or try again later.',
  [ErrorCode.HWLLS_ERROR_BAD_REQUEST]: 'bad request.',
  [ErrorCode.HWLLS_ERROR_STREAM_INVALID_PARAMETER]: 'browser not support avc codec.',
  [ErrorCode.HWLLS_INTERNAL_ERROR]: 'internal error',
  [ErrorCode.HWLLS_BUSSINESS_DOWNGRADE]: 'fatal error, bussiness downgrade: hls/http-flv etc.',
  [ErrorCode.HWLLS_PLAY_WEBRTC_RETRY_FAILED]: 'recover play with webrtc protocol timeOut, pls comfirm the server is avaliable.',
  [ErrorCode.HWLLS_PLAY_FLV_RETRY_FAILED]: 'recover play with flv format timeOut, pls comfirm the server is avaliable.',
  [ErrorCode.HWLLS_PLAY_HLS_RETRY_FAILED]: 'recover play with hls format timeOut, pls comfirm the server is avaliable.',
  [ErrorCode.HWLLS_PLAY_NOT_ALLOW]: 'the user didn\'t interact with the document first, please trigger by gesture.',
  [ErrorCode.HWLLS_PLAY_TIMEOUT]: 'play timeout, please confirm the stream server status.',
  [ErrorCode.HWLLS_ERROR_UNEXPECTED_EOF]: 'related to unexpected network EOF which cannot be recovered.',
  [ErrorCode.HWLLS_ERROR_ENCODING_NOT_ACCEPTED]: 'server does not accept content-encoding request'
}

export class HwLLSError extends Error {
  code: number
  message: string
  constructor(code: number, message?: string) {
    let msg = ''
    if (code in ErrorCode) {
      msg = ErrorMsg[code]
    } else {
      msg = 'unknow error'
    }
    super(message)
    this.code = code
    this.message = message || msg
  }

  getCode(): number {
    return this.code
  }

  getMsg(): string {
    return this.message
  }

  toString(): string {
    return `["code": ${this.code}, "message": "${this.message}"]`
  }
}
