import JSZip from "jszip"
import { configs } from "src/config/config"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import CommonUtil from "src/utils/CommonUtil"
import HttpRequest from "src/utils/HttpRequestUtil"

const LogLevels = {
  none: 0,
  error: 1,
  warn: 2,
  log: 3,
  info: 3,
  debug: 4,
  trace: 5,
  verbose: 5
}

const extraInfos = {
  domain: '',
  appName: '',
  streamName: '',
  svrsid: ''
}

export class LoggerFactory {
  static logLevel = 'error'
  static logBuffer: string[] = []
  static logBufferSize = 10000

  /**
  * 设置日志输出级别
  *
  * @param {LogLevel} level 日志级别
  */
  public static setLogLevel(level: string) {
    if (LogLevels[level] >= 0) {
      LoggerFactory.logLevel = level
    } else {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'log level is invalid')
    }
    return true
  }

  public static setExtraInfos(options: { domain?: string; appName?: string; streamName?: string; svrsid?: string }) {
    if (options.domain !== undefined) {
      extraInfos.domain = options.domain
    }
    if (options.appName !== undefined) {
      extraInfos.appName = options.appName
    }
    if (options.streamName !== undefined) {
      extraInfos.streamName = options.streamName
    }
    if (options.svrsid !== undefined) {
      extraInfos.svrsid = options.svrsid
    }
  }

  public static shieldIpAddress(originalString: string): string {
    if (!originalString) {
      return null
    }
    return originalString.replace(/\b(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\b/g, '$1.*.*.$2')
  }

  public static shieldUrlParameters(url: string): string {
    if (!url) {
      return url
    }
    const parameters = url.match(/([^?|=|&]+)=([^&]+)/g)
    if (parameters) {
      parameters.forEach(parameter => {
        const keyValue = /([^=]*)=(.*)/g.exec(parameter)
        url = url.replace(parameter, `${keyValue[1]}=${LoggerFactory.secretString(keyValue[2])}`)
      })
    }
    return url
  }

  public static secretString(originalString: string): string {
    if (!originalString) {
      return null
    }
    const confusConstStr = '********'
    const firstSliceLength = Math.ceil(originalString.length / 3)
    const lastSliceLength = Math.ceil((originalString.length - firstSliceLength) / 2)

    if (lastSliceLength === 0) {
      return `${originalString.substr(0, firstSliceLength)}${confusConstStr}`
    }

    return `${originalString.substr(0, firstSliceLength)}${confusConstStr}${originalString.substr(-lastSliceLength, lastSliceLength)}`
  }

  static async getLogFileInfo(): Promise<{ content: Blob; urlFileNameEncode: string; fileName: string }> {
    const curTime = CommonUtil.getCurrentTimeInfo()
    const ctime = `${curTime.year}-${curTime.month}-${curTime.day}T${curTime.hour}:${curTime.min}:${curTime.sec}${curTime.zoneOff}`
    const filectime = `${curTime.year}-${curTime.month}-${curTime.day}T${curTime.hour}.${curTime.min}.${curTime.sec}${curTime.zoneOff.replace(":", ".")}`
    const curLogs = LoggerFactory.getLogBuffer()
    if (curLogs.length === 0) {
      return null
    }
    const baseFileName = `LLL_${extraInfos.domain || 'runtime'}##${extraInfos.appName || "default"}##${extraInfos.streamName || "default"}##`
    const fileName = `${baseFileName}${extraInfos.svrsid || "default"}##${filectime}`
    const urlFileNameEncode = encodeURIComponent(`${baseFileName}${ctime}`)
    const zip = new JSZip()
    const folder = zip.folder(`${ctime}#${CommonUtil.generateRandomId(6)}`)
    folder.file(`${fileName}.log`, `${curLogs.join('\n')}`)

    const content = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 9
      }
    })
    return { content, urlFileNameEncode, fileName }
  }

  static async uploadLog(): Promise<void> {
    const logInfo = await LoggerFactory.getLogFileInfo()
    const body = new FormData()
    body.append(`file`, logInfo.content, `${logInfo.fileName}.zip`)
    HttpRequest.fetch([`https://${configs.OPS_REPORT_DOMAIN}/v1/api/hwrtc_client/log_collect?file=${logInfo.urlFileNameEncode}`], {
      method: 'POST',
      body: body,
      mode: 'cors'
    }, {
      'Authorization': configs.OPS_AUTH_TOKEN
    })
  }

  public static error(tag: string, msg: string): void {
    LoggerFactory.print('error', tag, msg)
  }

  public static warn(tag: string, msg: string): void {
    LoggerFactory.print('warn', tag, msg)
  }

  public static info(tag: string, msg: string): void {
    LoggerFactory.print('info', tag, msg)
  }

  public static log(tag: string, msg: string): void {
    LoggerFactory.print('log', tag, msg)
  }

  public static debug(tag: string, msg: string): void {
    LoggerFactory.print('debug', tag, msg)
  }

  public static trace(tag: string, msg: string): void {
    LoggerFactory.print('trace', tag, msg)
  }

  public static verbose(tag: string, msg: string): void {
    LoggerFactory.print('verbose', tag, msg)
  }

  /**
   * 日志清除
   */
  public static clearLogs(): void {
    LoggerFactory.logBuffer = []
  }

  /**
   * 设置日志缓存大小
   *
   * @param size
   */
  public static setlogBufferSize(size: number): void {
    LoggerFactory.logBufferSize = size
  }

  /**
   * 获取日志缓存大小
   */
  public static getlogBufferSize() {
    return LoggerFactory.logBufferSize
  }

  /**
   * 获取LogBuffer
   */
  public static getLogBuffer() {
    return LoggerFactory.logBuffer
  }

  /**
   * 日志处理
   *
   * @param level
   * @param tag
   * @param msg
   * @param info
   */
  private static print(level: string, tag: string, msg: string): void {
    const curTime = CommonUtil.getCurrentTimeInfo()
    const logMsg = `${curTime.year}-${curTime.month}-${curTime.day} ${curTime.hour}:${curTime.min}:${curTime.sec}.${curTime.millsec} [HWLLS] [${level}] [${tag}] [${msg}]`

    this.logBuffer.push(logMsg)
    while (this.logBuffer.length >= this.logBufferSize) {
      this.logBuffer.shift()
    }

    if (LogLevels[LoggerFactory.logLevel] >= LogLevels[level]) {
      console[LogLevels[level] >= 4 ? 'debug' : level](logMsg)
    }
  }
}