import { LoggerFactory } from 'src/logger/LoggerFactory'
import System from 'src/system/System'
import { configs } from 'src/config/config'
import { parametersUtil } from 'src/utils/ParametersUtil'
import { api, asyncApi } from "src/common/APIAnnotation"
import { HWLLSClient } from 'src/client/HWLLSClient'
import EmitterAble from 'src/common/EmitterAble'
import { HWFlvClient } from 'src/client/HWFlvClient'
import { HWHlsClient } from 'src/client/HWHlsClient'
import { MediaFormat } from './common/ObjDefinition'
import { ErrorCode, HwLLSError } from './ecode/ECode'

export default class HWLLSEnter extends EmitterAble {
  constructor() {
    super('HWLLSEnter')
  }

  @api("HWLLSEnter$getVersion#string#void")
  getVersion(): string {
    return configs.SDK_VERSION
  }

  @asyncApi("HWLLSEnter$checkSystemRequirements#Promise<boolean>#void")
  async checkSystemRequirements(): Promise<boolean> {
    return await System.checkSystemRequirements()
  }

  @api("HWLLSEnter$setParameter#boolean#string#string")
  setParameter(parameterKey: string, parameterValue: any): boolean {
    return parametersUtil.setParameter(parameterKey, parameterValue)
  }

  @api("HWLLSEnter$createClient#HWLLSClient|HWFlvClient#void")
  createClient(type?: MediaFormat): HWLLSClient | HWFlvClient | HWHlsClient {
    if (!type || type === MediaFormat.WEBRTC) {
      return new HWLLSClient()
    }
    if (type === MediaFormat.HLS && System.hlsCapabilityCheck()) {
      return new HWHlsClient()
    }
    if (type === MediaFormat.FLV && System.flvCapabilityCheck()) {
      return new HWFlvClient()
    }
    throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
  }

  @api("HWLLSEnter$uploadLog#Promise<void>#void")
  uploadLog(): Promise<void> {
    return LoggerFactory.uploadLog()
  }

  async saveLog(): Promise<Blob> {
    return (await LoggerFactory.getLogFileInfo()).content
  }

  @api("HWLLSEnter$setLogLevel#boolean#string")
  setLogLevel(level: string): boolean {
    return LoggerFactory.setLogLevel(level)
  }
}