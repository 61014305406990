import { PeerConnectionsManager } from 'src/managers/PeerConnectionsManager'
import HWLLSInterval from 'src/stat/HWLLSInterval'
import { MediaType } from 'src/common/ObjDefinition'
import System from 'src/system/System'
import CommonUtil from 'src/utils/CommonUtil'
import { configs } from 'src/config/config'
import { LoggerFactory } from 'src/logger/LoggerFactory'

const FREEZEN_KEYS = {
  audio: (): string => {
    return 'packetsReceived'
  },
  video: (data: any) => {
    if (Object.prototype.hasOwnProperty.call(data, 'framesDecoded')) {
      return 'framesDecoded'
    }
    if (Object.prototype.hasOwnProperty.call(data, 'framesReceived')) {
      return 'framesReceived'
    }
    return 'packetsReceived'
  }
}
const FREEZEN_THRESHOLD = {
  audio200: 200,
  video200: 200,
  video600: 600
}
const TIMER_INTERVAL_LIMIT = 2000

export class MediaStats {
  static startUpMediaStats(connectionsManager: PeerConnectionsManager): void {
    HWLLSInterval.connectionRegister(connectionsManager)
  }

  static shutDownMediaStats(connectionId: string): void {
    HWLLSInterval.reset(connectionId)
  }

  static getLeastestVideoInfo(connectionId: string, ssrc: number): any {
    if (!ssrc) {
      return null
    }
    return HWLLSInterval.getleastestStats(connectionId, `inbound-rtp_video_${ssrc}`)
  }

  static getLeastestAudioInfo(connectionId: string, ssrc: number): any {
    if (!ssrc) {
      return null
    }
    return HWLLSInterval.getleastestStats(connectionId, `inbound-rtp_audio_${ssrc}`)
  }

  static getConnectionNetType(connectionId: string): string {
    if (System.isFirefox() && !connectionId) {
      return null
    }
    const datas = HWLLSInterval.getleastestStats(connectionId)?.data
    if (!datas) {
      return null
    }
    let netType = null
    for (const sample of datas.values()) {
      if (sample.type === 'local-candidate') {
        netType = sample.networkType
        break
      }
    }
    return netType !== 'unknown' ? netType : 'other'
  }

  static async getCandidatePairProtocol(connectionId: string): Promise<string> {
    await CommonUtil.sleep(800)
    const datas = HWLLSInterval.getleastestStats(connectionId)?.data
    if (!datas) {
      return 'udp'
    }
    return datas.get(datas.get('candidate-pair')?.localCandidateId)?.protocol || 'udp'
  }

  static getMediaStatistic(connectionId: string, ssrc: number, mediaType: MediaType): any {
    const samples = HWLLSInterval.getHistoryDatas(connectionId)
    if (!samples || samples.size() < 1) {
      return null
    }
    const isProcessed = mediaType === MediaType.TRACK_TYPE_AUDIO ? 1 : 2
    const data = []
    let totalRTT = 0

    for (let idx = 0; idx < samples.size(); idx++) {
      if ((samples.getElement(idx).processed & isProcessed) > 0) {
        continue
      }
      samples.getElement(idx).processed = samples.getElement(idx).processed | isProcessed
      const sampleData = samples.getElement(idx).data
      if (!sampleData) {
        continue
      }
      const ssrcStatistic = sampleData.get(`inbound-rtp_${mediaType}_${ssrc}`)
      if (ssrcStatistic) {
        data.push(ssrcStatistic)
        const candidatePair = sampleData.get('candidate-pair')
        if (!candidatePair) {
          continue
        }
        if (Object.prototype.hasOwnProperty.call(candidatePair, 'currentRoundTripTime')) {
          totalRTT += CommonUtil.getValue(candidatePair['currentRoundTripTime'], 0) * 1000
        } else {
          totalRTT += Math.abs(CommonUtil.getValue(candidatePair['lastPacketReceivedTimestamp'], 0) - CommonUtil.getValue(candidatePair['lastPacketSentTimestamp'], 0))
        }
      }
    }
    const sampleSize = data.length
    if (sampleSize === 0) {
      return null
    }

    const newestSample = data[0]
    const oldestSample = sampleSize > 1 ? data[sampleSize - 1] : {}
    const freezeKey = FREEZEN_KEYS[mediaType](newestSample)

    const totalByteReceived = CommonUtil.getValue(newestSample['bytesReceived'], 0)
    const totalPacketsReceived = CommonUtil.getValue(newestSample['packetsReceived'], 0)
    const deltaBytes = Math.max(totalByteReceived - CommonUtil.getValue(oldestSample['bytesReceived'], 0), 0)
    const framesReceived = CommonUtil.getValue(newestSample['framesReceived'], 0)
    const framesDecoded = CommonUtil.getValue(newestSample['framesDecoded'], 0)
    const framesDropped = CommonUtil.getValue(newestSample['framesDropped'], 0)
    const lostPktCnt = Math.max(newestSample['packetsLost'] - CommonUtil.getValue(oldestSample['packetsLost'], 0), 0)

    const { total200FreezeTime, total200FreezeCnt, total600VFreezeTime, total600VFreezeCnt, totalJetter, totalStatisticTime, validSampleCount } = MediaStats.calcFreezeStatistic(data, freezeKey, mediaType)

    const statisticInfo = {
      rtt: Math.ceil(totalRTT / sampleSize), total200FreezeTime, total200FreezeCnt, totalStatisticTime, totalPacketsReceived, totalByteReceived, bitRate: Math.round(deltaBytes * 8 / (totalStatisticTime || 1)), lostPktCnt, jitter: Math.round(totalJetter * 1000 / validSampleCount), nackCnt: CommonUtil.getValue(newestSample['nackCount'], 0)
    }
    if (mediaType === MediaType.TRACK_TYPE_AUDIO) {
      return statisticInfo
    }

    const deltaDecodeFrames = Math.max(framesDecoded - CommonUtil.getValue(oldestSample['framesDecoded'], 0), 0)
    const keyFrames = Math.max(newestSample['keyFramesDecoded'] - CommonUtil.getValue(oldestSample['keyFramesDecoded'], 0), 0)
    const frameRate = deltaDecodeFrames * 1000 / (totalStatisticTime || 1)
    const jbDelay = ((CommonUtil.getValue(newestSample['jitterBufferDelay'], 0) - CommonUtil.getValue(oldestSample['jitterBufferDelay'], 0)) / Math.max(CommonUtil.getValue(newestSample['jitterBufferEmittedCount'], 0) - CommonUtil.getValue(oldestSample['jitterBufferEmittedCount'], 0), 1)) * 1000

    const delayInfo = {
      rtt: statisticInfo.rtt,
      jitterBufferDelay: CommonUtil.getValue(newestSample['jitterBufferDelay'], 0),
      jitterBufferEmittedCount: CommonUtil.getValue(newestSample['jitterBufferEmittedCount'], 0),
      framesPerSecond: newestSample['framesPerSecond'] || Math.ceil(frameRate),
      jbDelay: Math.ceil(Math.max(jbDelay, 0)),
      frameDelay: Math.max((framesReceived - framesDecoded - framesDropped) / CommonUtil.getValue(frameRate, 1), 0) * 1000
    }
    const downloadDelay = Math.ceil(delayInfo.rtt + delayInfo.jbDelay + delayInfo.frameDelay)

    Object.assign(statisticInfo, { width: CommonUtil.getValue(newestSample['frameWidth'], 0), height: CommonUtil.getValue(newestSample['frameHeight'], 0), totalDecodeFrames: deltaDecodeFrames, frameRate: Math.ceil(frameRate), keyFrames, delayInfo, downloadDelay, framesReceived, framesDecoded, framesDropped, total600VFreezeTime, total600VFreezeCnt, pliCnt: CommonUtil.getValue(newestSample['pliCount'], 0), firCnt: CommonUtil.getValue(newestSample['firCount'], 0) })
    return statisticInfo
  }

  static calcFreezeStatistic(data: any[], freezeKey: string, mediaType: MediaType): any {
    let validSampleCount = 0
    let totalStatisticTime = 0
    let freeze200 = 0
    let total200FreezeTime = 0
    let total200FreezeCnt = 0
    let freeze600V = 0
    let total600VFreezeTime = 0
    let total600VFreezeCnt = 0
    let totalJetter = 0

    const isVideo = mediaType !== MediaType.TRACK_TYPE_AUDIO
    const useTimestamp = Object.prototype.hasOwnProperty.call(data[0], 'timestamp')

    if (data.length < 2) {
      return { total200FreezeTime, total200FreezeCnt, total600VFreezeTime, total600VFreezeCnt, totalJetter: CommonUtil.getValue(data[0]['jitter'], 0), totalStatisticTime: configs.STATISTIC_INTERVAL, validSampleCount: 1 }
    }

    for (let i = 0; i < data.length; i++) {
      if (!data[i] || (data[i]['bytesReceived'] === 0 && data[i]['packetsReceived'] === 0)) {
        LoggerFactory.error('MediaStats', `Has no data at start, ignore sample: ${data[i].timestamp}`)
        continue
      }

      if (i === 0) {
        validSampleCount++
        totalJetter += CommonUtil.getValue(data[i]['jitter'], 0)
        continue
      }

      const deltaTimeStamp = useTimestamp ? Math.round(CommonUtil.getValue(data[i - 1].timestamp, 0) - CommonUtil.getValue(data[i].timestamp, 0)) : configs.STATISTIC_INTERVAL
      if (deltaTimeStamp > TIMER_INTERVAL_LIMIT) {
        LoggerFactory.error('MediaStats', `Has huge gap between ${data[i].timestamp} - ${data[i - 1].timestamp}, ignore sample: ${data[i].timestamp}, deltaTimeStamp: ${deltaTimeStamp}`)
        continue
      }
      validSampleCount++
      totalJetter += CommonUtil.getValue(data[i]['jitter'], 0)
      totalStatisticTime += deltaTimeStamp

      const isFirstFrameReceived = data[i][freezeKey] !== 0
      if (!isFirstFrameReceived) {
        LoggerFactory.error('MediaStats', `first frame has not received yet, ignore stuck statistic`)
        continue
      }

      if (data[i - 1][freezeKey] === data[i][freezeKey]) {
        const fz = deltaTimeStamp
        freeze200 += fz
        if (isVideo) {
          freeze600V += fz
        }
      } else {
        if (freeze200 >= FREEZEN_THRESHOLD[`${mediaType}200`]) {
          total200FreezeTime += freeze200
          total200FreezeCnt++
        }
        freeze200 = 0

        if (isVideo && freeze600V >= FREEZEN_THRESHOLD[`${mediaType}600`]) {
          total600VFreezeTime += freeze600V
          total600VFreezeCnt++
        }
        freeze600V = 0
      }

    }
    if (freeze200 >= FREEZEN_THRESHOLD[`${mediaType}200`]) {
      total200FreezeTime += freeze200
      total200FreezeCnt++
    }
    if (isVideo && freeze600V >= FREEZEN_THRESHOLD[`${mediaType}600`]) {
      total600VFreezeTime += freeze600V
      total600VFreezeCnt++
    }
    return {
      total200FreezeTime, total200FreezeCnt, total600VFreezeTime, total600VFreezeCnt, totalJetter, totalStatisticTime: totalStatisticTime, validSampleCount
    }
  }
}