import { EventEmitter } from 'events'
import FlvJs from 'flv.js'
import { PlayerViewMode, PosterMode } from 'src/common/ObjDefinition'
import { ErrorCode, ErrorMsg, HwLLSError } from 'src/ecode/ECode'
import { LoggerFactory } from 'src/logger/LoggerFactory'
import commonUtil from 'src/utils/CommonUtil'
import fullScreen, { LoadingMask, PosterMask } from './PlayerUtils'

export class FlvPlayer {
  private module_ = 'FlvPlayer'
  private playerEventHandler: any
  private event: EventEmitter
  private parentDiv: HTMLElement
  private objectFit: PlayerViewMode
  private muted: boolean
  private player: FlvJs.Player
  private videoBufferedReday: boolean
  private loadingUtils: LoadingMask
  private autoPlay: boolean
  private showLoading: boolean
  private poster_: any

  constructor(options: {
    eventEmitter: EventEmitter;
    url: string;
    parentDiv: HTMLElement;
    objectFit: PlayerViewMode;
    muted: boolean;
    poster: any;
  }) {
    this.loadingUtils = new LoadingMask(options.parentDiv)
    this.event = options.eventEmitter
    this.playerEventHandler = {
      errorHandler: (event, desc) => {
        LoggerFactory.error(this.module_, `event ERROR: ${JSON.stringify(event)}`)
        this.loadingMask(false)
        if (event === FlvJs.ErrorTypes.NETWORK_ERROR) {
          switch (desc) {
            case FlvJs.ErrorDetails.NETWORK_EXCEPTION:
              this.event.emit('Error', {
                errCode: ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL,
                errDesc: ErrorMsg[ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL]
              })
              break
            case FlvJs.ErrorDetails.NETWORK_STATUS_CODE_INVALID:
              this.event.emit('Error', {
                errCode: ErrorCode.HWLLS_ERROR_STREAM_NOT_EXIST,
                errDesc: ErrorMsg[ErrorCode.HWLLS_ERROR_STREAM_NOT_EXIST]
              })
              break
            case FlvJs.ErrorDetails.NETWORK_TIMEOUT:
              this.event.emit('Error', {
                errCode: ErrorCode.HWLLS_ERROR_SERVER_NO_RESPONSE,
                errDesc: ErrorMsg[ErrorCode.HWLLS_ERROR_SERVER_NO_RESPONSE]
              })
              break
            case FlvJs.ErrorDetails.NETWORK_UNRECOVERABLE_EARLY_EOF:
              this.event.emit('Error', {
                errCode: ErrorCode.HWLLS_ERROR_UNEXPECTED_EOF,
                errDesc: ErrorMsg[ErrorCode.HWLLS_ERROR_UNEXPECTED_EOF]
              })
              break
            default:
              this.event.emit('Error', {
                errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
                errDesc: desc
              })
              break
          }
        } else if (event === FlvJs.ErrorTypes.MEDIA_ERROR) {
          const mediaInfo: any = this.getMediaInfo()
          this.event.emit('Error', {
            errCode: ErrorCode.HWLLS_ERROR_MEDIA_ERROR,
            errDesc: `${desc}, videoCodec: ${mediaInfo.videoCodec} and audioCodec: ${mediaInfo.audioCodec}`
          })
        } else {
          this.event.emit('Error', {
            errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
            errDesc: desc || ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
          })
        }
      },
      loadCompleteHandler: () => {
        LoggerFactory.info(this.module_, `event LOADING_COMPLETE`)
      },
      unexpectedEOFHandler: (event) => {
        LoggerFactory.error(this.module_, `event RECOVERED_EARLY_EOF: ${JSON.stringify(event)}`)
      },
      mediaDataArrived: (event) => {
        LoggerFactory.info(this.module_, `event METADATA_ARRIVED: ${JSON.stringify(event)}`)
        this.event.emit('HWLLSEvents.VIDEO_START')
        this.event.emit('HWLLSEvents.AUDIO_START')
        this.loadingMask(false)
        this.videoBufferedReday = true
      },
      logHandler: (level: string, msg: string) => {
        LoggerFactory[level](this.module_, msg)
      }
    }
    this.parentDiv = options.parentDiv
    this.objectFit = options.objectFit || PlayerViewMode.CONTAIN
    this.muted = Boolean(options.muted)
    this.poster_ = options.poster
    this.createFlvPlayer(options.url)
    this.initPlayerListener()
  }

  public async play(autoPlay: boolean, showLoading: boolean): Promise<void> {
    this.autoPlay = autoPlay === false ? false : true
    if (this.poster_) {
      PosterMask.attachPoster(this.parentDiv, this.poster_.url, this.poster_.mode || PosterMode.FILL)
      if (!this.autoPlay && this.poster_.startEnable) {
        PosterMask.disPlayPoster(true, this.poster_.url)
      }
    }
    this.showLoading = showLoading
    let element: any = this.parentDiv.querySelector(`#video_player`)
    if (!element) {
      element = document.createElement('video')
      element.setAttribute('id', 'video_player')
      element.style.width = "100%"
      element.style.height = "100%"
      element.style.position = "absolute"
      element.style['object-fit'] = this.objectFit
      this.autoPlay && element.setAttribute('autoplay', '')
      element.setAttribute('playsinline', '')
      element.setAttribute('webkit-playsinline', '')
      commonUtil.isTencentX5BrowserKernel() && element.setAttribute('x5-playsinline', '')
      this.parentDiv.appendChild(element)
    }
    try {
      this.player.attachMediaElement(element)
      if (!this.muted) {
        this.player.volume = 0.5
      } else {
        this.player.muted = true
      }
      this.player.load()
      if (this.autoPlay) {
        showLoading && this.loadingMask(true)
        await this.player.play()
      }
    } catch (error) {
      LoggerFactory.error(this.module_, `play failed, errmsg=${error},parentDiv id:${this.parentDiv?.id}`)
      if (error.name === 'NotAllowedError') {
        throw new HwLLSError(ErrorCode.HWLLS_PLAY_NOT_ALLOW)
      } else {
        throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error.message)
      }
    }
  }

  public pause(): void {
    if (this.player) {
      this.player.pause()
      if (this.poster_?.pauseEnable) {
        PosterMask.disPlayPoster(true, this.poster_.url)
      }
    }
  }

  public async resume(): Promise<void> {
    if (this.poster_) {
      PosterMask.disPlayPoster(false, this.poster_.url)
    }
    if (this.player) {
      if (!this.videoBufferedReday && this.showLoading) {
        this.loadingMask(true)
      }
      return await this.player.play()
    }
  }

  public mute(isMute: boolean): void {
    if (this.player) {
      this.player.muted = isMute
      this.muted = isMute
    }
  }

  public async switchPlay(options: any): Promise<void> {
    this.videoBufferedReday = false
    this.offPlayerListener()
    this.player.detachMediaElement()
    this.player.unload()
    this.player.destroy()

    this.poster_ = options.poster
    this.createFlvPlayer(options.url)
    if (this.parentDiv && this.parentDiv.id !== options.parentDiv?.id) {
      const videoElement = document.querySelector(`#${this.parentDiv.id} #video_player`)
      videoElement && this.parentDiv.appendChild(videoElement)
      document.querySelector(`#${this.parentDiv.id} #video_player`)?.remove()
      this.parentDiv = options.parentDiv
    }
    this.initPlayerListener()
    await this.play(options.autoPlay, options.showLoading)
  }

  public stopPlay(): void {
    this.videoBufferedReday = false
    this.loadingUtils.stopLoadingMask()
    document.querySelector(`#${this.parentDiv.id} #video_player`)?.remove()
    this.poster_ && PosterMask.detachPoster(this.parentDiv, this.poster_.url)
    this.offPlayerListener()
    this.player.detachMediaElement()
    this.player.unload()
    this.player.destroy()
  }

  public destory(): void {
    this.stopPlay()
    this.player = null
  }

  public getPlayoutVolume(): number {
    if (this.player?.muted) {
      return 0
    }
    return (this.player?.volume || 0) * 100
  }

  public setPlayoutVolume(volume: number): boolean {
    if (this.player) {
      this.player.volume = Number((volume / 100).toFixed(2))
      return true
    }
    return false
  }

  public getMediaInfo(): FlvJs.FlvPlayerMediaInfo | FlvJs.NativePlayerMediaInfo {
    return this.player?.mediaInfo
  }

  public getStatisticInfo(): FlvJs.NativePlayerStatisticsInfo | FlvJs.FlvPlayerStatisticsInfo {
    return this.player?.statisticsInfo
  }

  public loadingMask(isShow: boolean): void {
    if (isShow) {
      if (this.autoPlay) {
        this.loadingUtils.loadingMask(true)
      }
    } else {
      this.loadingUtils.loadingMask(false)
    }
  }

  public exitFullScreen(): void {
    fullScreen.exitFullScreen()
  }

  public enterFullScreen(): void {
    fullScreen.enterFullScreen(this.parentDiv, this.parentDiv.querySelector(`#video_player`), this.event)
  }

  private createFlvPlayer(url: string): void {
    this.player = FlvJs.createPlayer({
      type: 'flv',
      isLive: true,
      cors: true,
      url: url
    }, {
      enableWorker: false,
      enableStashBuffer: true,
      lazyLoad: false
    })
    FlvJs.LoggingControl.applyConfig({
      enableAll: false
    })
    FlvJs.LoggingControl.addLogListener(this.playerEventHandler.logHandler)
  }

  private initPlayerListener(): void {
    this.player.on(FlvJs.Events.ERROR, this.playerEventHandler.errorHandler)
    this.player.on(FlvJs.Events.LOADING_COMPLETE, this.playerEventHandler.loadCompleteHandler)
    this.player.on(FlvJs.Events.RECOVERED_EARLY_EOF, this.playerEventHandler.unexpectedEOFHandler)
    this.player.on(FlvJs.Events.METADATA_ARRIVED, this.playerEventHandler.mediaDataArrived)
  }

  private offPlayerListener(): void {
    this.player?.off(FlvJs.Events.ERROR, this.playerEventHandler.errorHandler)
    this.player?.off(FlvJs.Events.LOADING_COMPLETE, this.playerEventHandler.loadCompleteHandler)
    this.player?.off(FlvJs.Events.RECOVERED_EARLY_EOF, this.playerEventHandler.unexpectedEOFHandler)
    this.player?.off(FlvJs.Events.METADATA_ARRIVED, this.playerEventHandler.mediaDataArrived)
    FlvJs.LoggingControl.removeLogListener(this.playerEventHandler.logHandler)
  }
}