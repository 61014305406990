import { api, asyncApi } from "src/common/APIAnnotation"
import EmitterAble from "src/common/EmitterAble"
import { PlayerInterface } from "src/client/IClient"
import { configs } from "src/config/config"
import { ErrorCode, ErrorMsg, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { GlobalVariableManager } from "src/managers/GlobalVariableManager"
import { PeerConnectionsManager } from "src/managers/PeerConnectionsManager"
import HWLLSInterval from "src/stat/HWLLSInterval"
import { MediaStats } from "src/stat/MediaStat"
import { RemoteStream } from "src/stream/RemoteStream"
import CommonUtil from "src/utils/CommonUtil"
import HttpRequest from "src/utils/HttpRequestUtil"
import { parametersUtil, parameterValidKeys } from "src/utils/ParametersUtil"
import { ValidatorUtil } from "src/utils/ValidatorUtil"
import { StreamParams, HWLLSEvents, LoadingConfig, MediaType, NetworkQualityTypes, SdpNegotiateStandard, StartPlayOptions, StatisticBase, StatisticInfo, VideoStatistic, StreamInterruptRetry, MediaFormat, DomainPolicy, PullStreamResult, RouteType, SchedulePolicy, RetryNumber } from "src/common/ObjDefinition"
import { PosterMask } from "src/player/PlayerUtils"
import dnsPacket from "dns-packet"
import { Buffer } from "buffer"
import pako from "pako"

const module_ = 'HWLLSClient'

export class HWLLSClient extends EmitterAble implements PlayerInterface {
  private connectionsManager: PeerConnectionsManager
  private liveStream: RemoteStream
  private startPlayOptions: StartPlayOptions
  private playOption: StartPlayOptions
  private errMap: Map<number, ErrorCode>
  private domains: string[]
  private debugStatisticTimer = {
    timer: null,
    interval: 1,
  }
  private detectTimer = {
    timer: null,
    interval: 3,
    interruptRetry: null
  }
  private netQualityTimer: NodeJS.Timeout
  private preNetQuality: NetworkQualityTypes
  private preRemoteStreamStatistic: any
  private loadingconfig: LoadingConfig
  private prePlayTrackCache: any
  private recoveryInfo = {
    recoveryTimer: null,
    retryTimes: 0,
    recoveryUrl: null
  }

  private firstFrameHandler = {
    videoHandler: () => {
      GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('videoPlaySucc')
      let result = PullStreamResult.SUCC
      if (GlobalVariableManager.getInstance(this.identifiedId).getPlayTrackKpi().videoTimeOut > 0) {
        result = PullStreamResult.SUCC_AFTER_TIMEOUT
      }
      this.stats.reportPullMediaStreamResult(MediaType.TRACK_TYPE_VIDEO, result)
    },
    audioHandler: () => {
      GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('audioPlaySucc')
      let result = PullStreamResult.SUCC
      if (GlobalVariableManager.getInstance(this.identifiedId).getPlayTrackKpi().audioTimeOut > 0) {
        result = PullStreamResult.SUCC_AFTER_TIMEOUT
      }
      this.stats.reportPullMediaStreamResult(MediaType.TRACK_TYPE_AUDIO, result)
    }
  }

  constructor() {
    super(module_)
    this.preNetQuality = NetworkQualityTypes.NETWORK_QUALITY_INIT
    this.preRemoteStreamStatistic = {
      packetsReceived: [0, 0],
      packetsLost: [0, 0],
      nackCnt: [0, 0],
      preLostRate: []
    }
    this.connectionsManager = new PeerConnectionsManager(this.stats, this.eventEmitter, this.identifiedId)
    this.initErrorMap()
    window.onbeforeunload = () => {
      LoggerFactory.info(module_, `app is closing, emergency stop`)
      this.liveStream && this.stopSignal()
    }
  }

  public getInfo() {
    return {
      clientId: this.identifiedId,
      userAgent: navigator.userAgent
    }
  }

  @asyncApi("HWLLSClient$startPlay#Promise<void>#string#StartPlayOptions")
  public async startPlay(url: string, options: StartPlayOptions): Promise<void> {
    LoggerFactory.info(module_, `[KPI] startPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)

    GlobalVariableManager.addInstance(this.identifiedId)
    GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('startPlayTime')
    let checkResult = ValidatorUtil.checkUrlFormat(url, MediaFormat.WEBRTC)
    if (!checkResult) {
      LoggerFactory.error(module_, `startPlay failed, invalid url protocol or format: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_URL)
    }

    checkResult = ValidatorUtil.checkPlayOpt(options)
    if (!checkResult) {
      LoggerFactory.error(module_, `startPlay failed, invalid Parameter: ${JSON.stringify(options)}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }
    await this.initPeerConnection(url, SdpNegotiateStandard.COMMON_STANDARD, options)
    this.netQualityRegularReport()
    if (this.debugStatisticTimer.timer) {
      this.streamStatistic(true, this.debugStatisticTimer.interval)
    }
    if (this.detectTimer.timer) {
      this.enableStreamStateDetection(true, this.detectTimer.interval, this.detectTimer.interruptRetry)
    }
  }

  @asyncApi("HWLLSClient$startPlayCustomize#Promise<void>#string#StartPlayOptions")
  public async startPlayCustomize(url: string, options: StartPlayOptions): Promise<void> {
    LoggerFactory.info(module_, `[KPI] startPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    GlobalVariableManager.addInstance(this.identifiedId)
    GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('startPlayTime')
    const checkResult = ValidatorUtil.checkPlayOpt(options)
    if (!checkResult) {
      LoggerFactory.error(module_, `startPlayCustomize failed, invalid Parameter: ${JSON.stringify(options)}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }
    await this.initPeerConnection(url, SdpNegotiateStandard.CUSTOMIZE_STANDARD, options)
    this.netQualityRegularReport()
    if (this.debugStatisticTimer.timer) {
      this.streamStatistic(true, this.debugStatisticTimer.interval)
    }
    if (this.detectTimer.timer) {
      this.enableStreamStateDetection(true, this.detectTimer.interval, this.detectTimer.interruptRetry)
    }
  }

  @asyncApi("HWLLSClient$switchPlay#Promise<boolean>#string#StartPlayOptions")
  public async switchPlay(url: string, options?: StartPlayOptions): Promise<void> {
    if (this.liveStream) {
      const stopTime = CommonUtil.getCurrentLocalTimestamp()
      try {
        this.liveStream.stop()
        this.stopSignal()
        GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('endPlayTime')
      } catch (error) {
        LoggerFactory.error(module_, `stopPlay occur error: ${error}`)
      } finally {
        this.stats.reportStopPullStreamReqEvent(stopTime)
        this.resetClient4Switch()
      }
    }
    LoggerFactory.info(module_, `[KPI] switchPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    GlobalVariableManager.addInstance(this.identifiedId)
    GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('startPlayTime')
    let checkResult = false
    let mode = SdpNegotiateStandard.COMMON_STANDARD
    if (/webrtc:\/\/.*/ig.test(url)) {
      checkResult = ValidatorUtil.checkUrlFormat(url, MediaFormat.WEBRTC)
      if (!checkResult) {
        LoggerFactory.error(module_, `switchPlay failed, invalid Parameter: ${url}`)
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
      }
    } else {
      mode = SdpNegotiateStandard.CUSTOMIZE_STANDARD
    }

    if (!options && !this.startPlayOptions) {
      LoggerFactory.error(module_, 'switchPlay failed, options is null')
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }
    if (options) {
      checkResult = ValidatorUtil.checkPlayOpt(options)
      if (!checkResult) {
        LoggerFactory.error(module_, `switchPlay failed, invalid Parameter: ${JSON.stringify(options)}`)
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
      }
    }

    await this.initPeerConnection(url, mode, options || this.startPlayOptions, true)
    if (this.debugStatisticTimer.timer) {
      this.streamStatistic(true, this.debugStatisticTimer.interval)
    }
    if (this.detectTimer.timer) {
      this.enableStreamStateDetection(true, this.detectTimer.interval, this.detectTimer.interruptRetry)
    }
  }

  @api("HWLLSClient$stopPlay#boolean#void")
  public stopPlay(): boolean {
    if (this.liveStream) {
      const stopTime = CommonUtil.getCurrentLocalTimestamp()
      try {
        this.liveStream.stop()
        this.stopSignal()
        GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('endPlayTime')
        return true
      } finally {
        this.stats.reportStopPullStreamReqEvent(stopTime)
        this.resetClient4Stop()
      }
    }
    return false
  }

  @asyncApi("HWLLSClient$replay#Promise<boolean>#void")
  public async replay(): Promise<boolean> {
    if (this.liveStream) {
      try {
        await this.liveStream.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @asyncApi("HWLLSClient$resume#Promise<boolean>#void")
  public async resume(): Promise<boolean> {
    if (this.liveStream) {
      try {
        await this.liveStream.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @api("HWLLSClient$pause#boolean#void")
  public pause(): boolean {
    if (this.liveStream) {
      this.liveStream.pause()
      return true
    }
    return false
  }

  @api("HWLLSClient$pauseVideo#boolean#void")
  public pauseVideo(): boolean {
    if (this.liveStream) {
      this.liveStream.pauseVideo()
      return true
    }
    return false
  }

  @asyncApi("HWLLSClient$resumeVideo#Promise<boolean>#void")
  public async resumeVideo(): Promise<boolean> {
    if (this.liveStream) {
      try {
        await this.liveStream.resumeVideo()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resumeVideo failed: ${error}`)
        return false
      }
    }
    return false
  }

  @api("HWLLSClient$pauseAudio#boolean#void")
  public pauseAudio(): boolean {
    if (this.liveStream) {
      this.liveStream.pauseAudio()
      return true
    }
    return false
  }

  @asyncApi("HWLLSClient$Promise<boolean>#boolean#void")
  public async resumeAudio(): Promise<boolean> {
    if (this.liveStream) {
      try {
        await this.liveStream.resumeAudio()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resumeAudio failed: ${error}`)
        return false
      }
    }
    return false
  }

  @api("HWLLSClient$setPlayoutVolume#boolean#number")
  public setPlayoutVolume(volume: number): boolean {
    if (this.liveStream) {
      const audioTrack = this.liveStream.getAudioHRTCTrack()
      if (audioTrack) {
        audioTrack.setAudioVolume(volume)
        return true
      }
    }
    return false
  }

  public on(eventName: string, handler: any) {
    if ([HWLLSEvents.MEDIA_STATISTIC, HWLLSEvents.NET_QUALITY].includes(eventName)) {
      super.on(eventName, handler)
    } else {
      super.on(eventName, handler, true)
    }
  }

  public off(eventName: string, handler: any) {
    if ([HWLLSEvents.MEDIA_STATISTIC, HWLLSEvents.NET_QUALITY].includes(eventName)) {
      super.off(eventName, handler)
    } else {
      super.off(eventName, handler, true)
    }
  }

  public getPlayoutVolume(): number {
    if (this.liveStream) {
      try {
        const audioTrack = this.liveStream.getAudioHRTCTrack()
        if (audioTrack) {
          return audioTrack.getAudioLevel() * 100
        }
      } catch (error) {
        LoggerFactory.error(module_, `getPlayoutVolume occur error: ${error}`)
      }
    }
    return 0
  }

  @api("HWLLSClient$streamStatistic#void#boolean#number#ExecutableFunction")
  public streamStatistic(enable: boolean, interval = 1): void {
    if (enable) {
      if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
        LoggerFactory.error(module_, 'streamStatistic, invalid parameter of interval.')
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
      }
      const perStatistic = {
        audioReceiveBytes: 0,
        videoReceiveBytes: 0,
        videoFrames: 0
      }
      this.getStreamStatisticInfo(perStatistic, interval)
    } else {
      clearTimeout(this.debugStatisticTimer.timer)
      this.debugStatisticTimer = {
        timer: null,
        interval: 1
      }
    }
  }

  @api("HWLLSClient$destoryClient#void#void")
  public destoryClient(): void {
    window.onbeforeunload = null
    this.resetClient()
    this.offAllEvents()
  }

  @api("HWLLSClient$fullScreenToggle#boolean#void")
  public fullScreenToggle(isExit = false): void {
    if (isExit) {
      this.liveStream?.exitFullScreen()
    } else {
      this.liveStream?.enterFullScreen()
    }
  }

  @api("HWLLSClient$enableStreamStateDetection#boolean#boolean#number")
  public enableStreamStateDetection(enable: boolean, interval = 3, interruptRetry?: StreamInterruptRetry): boolean {
    if (!enable) {
      clearTimeout(this.detectTimer.timer)
      this.detectTimer = {
        timer: null,
        interval: 3,
        interruptRetry: null
      }
      return true
    }

    if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
      LoggerFactory.error(module_, 'enableStreamStateDetection, invalid parameter of interval.')
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }

    clearTimeout(this.detectTimer.timer)
    const preStat = {
      preVideoStat: {
        preFramesDecoded: 0,
        isInterrupted: false
      },
      preAudioStat: {
        prePacketReceived: 0,
        isInterrupted: false
      }
    }

    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    if (this.liveStream?.getVideoHRTCTrack()) {
      preStat.preVideoStat.preFramesDecoded = CommonUtil.getValue(MediaStats.getLeastestVideoInfo(this.connectionsManager.getConnectionId(), sfuInfo.vSsrc)?.framesDecoded, 0)
    }
    if (this.liveStream?.getAudioHRTCTrack()) {
      preStat.preAudioStat.prePacketReceived = CommonUtil.getValue(MediaStats.getLeastestVideoInfo(this.connectionsManager.getConnectionId(), sfuInfo.aSsrc)?.packetsReceived, 0)
    }
    this.detectTimer.interval = interval
    this.detectTimer.interruptRetry = interruptRetry
    this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    return true
  }

  private judgeStreamInterrupted(preStat: any, interval: number, interruptRetry?: StreamInterruptRetry): void {
    this.detectTimer.timer = setTimeout(() => {
      clearTimeout(this.detectTimer.timer)
      let videoStat = null
      let audioStat = null
      let isVideoInterrupted = false
      let isAudioInterrupted = false
      const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
      if (this.liveStream?.getVideoHRTCTrack()) {
        videoStat = MediaStats.getLeastestVideoInfo(this.connectionsManager.getConnectionId(), sfuInfo.vSsrc)
        isVideoInterrupted = CommonUtil.getValue(videoStat?.framesDecoded, 0) - CommonUtil.getValue(preStat.preVideoStat.preFramesDecoded, 0) <= 0
        if (!isVideoInterrupted) {
          clearTimeout(this.recoveryInfo.recoveryTimer)
          this.recoveryInfo.retryTimes = 0
        }
        if (videoStat) {
          preStat.preVideoStat.preFramesDecoded = CommonUtil.getValue(videoStat.framesDecoded, 0)
        }
      }
      if (this.liveStream?.getAudioHRTCTrack()) {
        audioStat = MediaStats.getLeastestAudioInfo(this.connectionsManager.getConnectionId(), sfuInfo.aSsrc)
        isAudioInterrupted = CommonUtil.getValue(audioStat?.packetsReceived, 0) - CommonUtil.getValue(preStat.preAudioStat.prePacketReceived, 0) <= 0
        if (audioStat) {
          preStat.preAudioStat.prePacketReceived = CommonUtil.getValue(audioStat.packetsReceived, 0)
        }
      }

      if (this.startPlayOptions?.webrtcConfig?.receiveVideo !== false) {
        if (preStat.preVideoStat.isInterrupted && !isVideoInterrupted) {
          this.eventEmitter.emit(HWLLSEvents.VIDEO_RECOVERY)
          preStat.preVideoStat.isInterrupted = false
        } else if (!preStat.preVideoStat.isInterrupted && isVideoInterrupted) {
          this.eventEmitter.emit(HWLLSEvents.VIDEO_INTERRUPTED)
          preStat.preVideoStat.isInterrupted = true
          if (interruptRetry?.enable) {
            LoggerFactory.info(this.moudle_, `autoRecoverPlay timer start`)
            clearTimeout(this.recoveryInfo.recoveryTimer)
            this.autoRecoverPlay(this.recoveryInfo.recoveryUrl, Math.max(10, interruptRetry?.retryInterval || 30), interruptRetry?.retryTimes || 30)
          }
        }
      }

      if (this.startPlayOptions?.webrtcConfig?.receiveAudio !== false) {
        if (preStat.preAudioStat.isInterrupted && !isAudioInterrupted) {
          this.eventEmitter.emit(HWLLSEvents.AUDIO_RECOVERY)
          preStat.preAudioStat.isInterrupted = false
        } else if (!preStat.preAudioStat.isInterrupted && isAudioInterrupted) {
          this.eventEmitter.emit(HWLLSEvents.AUDIO_INTERRUPTED)
          preStat.preAudioStat.isInterrupted = true
        }
      }
      this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    }, interval * 1000);
  }

  private autoRecoverPlay(recoveryUrl: string, retryInterval: number, retryTimes: number): void {
    let currentRetryTimes = 0
    this.recoveryInfo.recoveryTimer = setTimeout(async () => {
      LoggerFactory.info(this.moudle_, `autoRecoverPlay every ${retryInterval} sencond`)
      clearTimeout(this.recoveryInfo.recoveryTimer)
      this.recoveryInfo.recoveryTimer = null
      if (recoveryUrl !== this.recoveryInfo.recoveryUrl) {
        this.recoveryInfo.retryTimes = 0
        return
      }
      try {
        this.recoveryInfo.retryTimes++
        currentRetryTimes = this.recoveryInfo.retryTimes
        await this.switchPlay(recoveryUrl, { ...this.startPlayOptions, ...{ recover: true } })
      } finally {
        this.recoveryInfo.retryTimes = currentRetryTimes
        if (currentRetryTimes < retryTimes) {
          this.autoRecoverPlay(recoveryUrl, retryInterval, retryTimes)
        } else {
          this.eventEmitter.emit('Error', {
            errCode: ErrorCode.HWLLS_PLAY_WEBRTC_RETRY_FAILED,
            errDesc: ErrorMsg[ErrorCode.HWLLS_PLAY_WEBRTC_RETRY_FAILED]
          })
        }
      }
    }, retryInterval * 1000)
  }

  private initErrorMap(): void {
    this.errMap = new Map<number, ErrorCode>()
    this.errMap.set(100, ErrorCode.HWLLS_ERROR_INVALID_URL)
    this.errMap.set(101, ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    this.errMap.set(102, ErrorCode.HWLLS_ERROR_ENCODING_NOT_ACCEPTED)
    this.errMap.set(400, ErrorCode.HWLLS_ERROR_BAD_REQUEST)
    this.errMap.set(401, ErrorCode.HWLLS_ERROR_STREAM_INVALID_PARAMETER)
    this.errMap.set(403, ErrorCode.HWLLS_ERROR_AUTH_FAIL)
    this.errMap.set(404, ErrorCode.HWLLS_ERROR_STREAM_NOT_EXIST)
    this.errMap.set(500, ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL)
    this.errMap.set(503, ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL)
    this.errMap.set(601, ErrorCode.HWLLS_BUSSINESS_DOWNGRADE)
  }

  /**
   * 根据丢包率评估网络质量
   * rankNetworkQuality
   */
  private async calcNetworkQuality(): Promise<NetworkQualityTypes> {
    const conn = this.connectionsManager.getConnection()
    if (!conn) {
      return NetworkQualityTypes.NETWORK_QUALITY_UNKNOW
    }
    if (conn && ["disconnected", "failed", "closed"].includes(conn.connectionState)) {
      return NetworkQualityTypes.NETWORK_QUALITY_DISCONNECT
    }
    let pkgLossRate = await this.getDownloadStatistic(MediaType.TRACK_TYPE_VIDEO)

    if (pkgLossRate < 0) {
      pkgLossRate = await this.getDownloadStatistic(MediaType.TRACK_TYPE_AUDIO)
    }

    if (pkgLossRate === -1) {
      return NetworkQualityTypes.NETWORK_QUALITY_UNKNOW
    }
    if (pkgLossRate < 1) {
      return NetworkQualityTypes.NETWORK_QUALITY_GREAT
    }
    if (pkgLossRate < 5) {
      return NetworkQualityTypes.NETWORK_QUALITY_GOOD
    }
    if (pkgLossRate < 12) {
      return NetworkQualityTypes.NETWORK_QUALITY_DEFECTS
    }
    if (pkgLossRate < 20) {
      return NetworkQualityTypes.NETWORK_QUALITY_WEAK
    }
    if (pkgLossRate < 30) {
      return NetworkQualityTypes.NETWORK_QUALITY_BAD
    }
    return NetworkQualityTypes.NETWORK_QUALITY_DISCONNECT
  }

  private async getDownloadStatistic(streamType: MediaType): Promise<number> {
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId).getSfuInfo()
    const stats = streamType === MediaType.TRACK_TYPE_VIDEO ? MediaStats.getLeastestVideoInfo(this.connectionsManager.getConnectionId(), sfuInfo?.vSsrc) : MediaStats.getLeastestAudioInfo(this.connectionsManager.getConnectionId(), sfuInfo?.aSsrc)
    if (!stats) {
      return -1
    }
    const index = streamType === MediaType.TRACK_TYPE_VIDEO ? 0 : 1
    const curRemoteStreamStatistic = {
      packetsReceived: 0,
      packetsLost: 0,
      nackCnt: 0
    }
    curRemoteStreamStatistic.packetsReceived = CommonUtil.getValue(stats['packetsReceived'], 0) // 已接收包数
    curRemoteStreamStatistic.packetsLost = CommonUtil.getValue(stats['packetsLost'], 0) // 丢包数
    curRemoteStreamStatistic.nackCnt = CommonUtil.getValue(stats['nackCount'], 0) // nack数量
    if (curRemoteStreamStatistic.packetsReceived === 0) {
      return -1
    }
    const deltaPkts = Math.max(curRemoteStreamStatistic.packetsReceived - this.preRemoteStreamStatistic.packetsReceived[index], 1)
    const deltaLostPkts = Math.max(curRemoteStreamStatistic.packetsLost - this.preRemoteStreamStatistic.packetsLost[index], 0)
    const deltaNackCnt = Math.max(curRemoteStreamStatistic.nackCnt - this.preRemoteStreamStatistic.nackCnt[index], 0)

    let rate = 0
    const base = deltaNackCnt * 100 / (deltaNackCnt + deltaPkts)
    if (base <= 20) {
      rate = deltaNackCnt * 0.9 * 100 / (deltaNackCnt * 0.9 + deltaPkts)
    } else if (base <= 30) {
      rate = deltaNackCnt * 0.86 * 100 / (deltaNackCnt * 0.86 + deltaPkts)
    } else if (base <= 40) {
      rate = deltaNackCnt * 0.82 * 100 / (deltaNackCnt * 0.82 + deltaPkts)
    } else if (base <= 50) {
      rate = deltaNackCnt * 0.78 * 100 / (deltaNackCnt * 0.78 + deltaPkts)
    } else {
      rate = deltaNackCnt * 0.7 * 100 / (deltaNackCnt * 0.7 + deltaPkts)
    }

    this.preRemoteStreamStatistic.preLostRate.push(Math.min(Math.max(deltaLostPkts * 100 / (deltaLostPkts + deltaPkts), rate), 100))
    if (this.preRemoteStreamStatistic.preLostRate.length > 3) {
      this.preRemoteStreamStatistic.preLostRate.shift()
    }
    this.preRemoteStreamStatistic.packetsReceived[index] = curRemoteStreamStatistic.packetsReceived
    this.preRemoteStreamStatistic.packetsLost[index] = curRemoteStreamStatistic.packetsLost
    this.preRemoteStreamStatistic.nackCnt[index] = curRemoteStreamStatistic.nackCnt

    const lostRates = this.smoothData(this.preRemoteStreamStatistic.preLostRate, 0.9)
    return lostRates[lostRates.length - 1]
  }

  private smoothData(values: number[], alpha: number) {
    const average = (data: number[]) => {
      const sum = data.reduce(function (sum, value) {
        return sum + value
      }, 0)
      const avg = sum / data.length
      return avg
    }
    const weighted = average(values) * alpha
    const smoothed = []
    for (let i = 0; i < values.length; i++) {
      const curr = values[i]
      const prev = i > 0 ? smoothed[i - 1] : values[values.length - 1]
      const next = i < values.length - 1 ? values[i + 1] : values[0]
      const improved = Number(average([weighted, prev, curr, next]).toFixed(2))
      smoothed.push(improved)
    }
    return smoothed
  }

  private netQualityRegularReport(): void {
    clearTimeout(this.netQualityTimer)
    this.netQualityTimer = setTimeout(() => {
      clearTimeout(this.netQualityTimer)
      this.calcNetworkQuality().then((networkQuality) => {
        // 在网络质量发生变化的时候上报
        if (this.preNetQuality === NetworkQualityTypes.NETWORK_QUALITY_INIT || this.preNetQuality !== networkQuality) {
          LoggerFactory.info(module_, `emit: ${HWLLSEvents.NET_QUALITY}, quality: ${networkQuality} `)
          this.eventEmitter.emit(HWLLSEvents.NET_QUALITY, networkQuality)
          if (this.loadingconfig.netQualityLoading) {
            this.eventEmitter.emit(HWLLSEvents.VIDEO_STUCK, networkQuality <= NetworkQualityTypes.NETWORK_QUALITY_UNKNOW || networkQuality >= this.loadingconfig.netQualityLoadingThreshold)
          }
        }

        this.preNetQuality = networkQuality
      }).finally(this.netQualityRegularReport.bind(this))
    }, 1000)
  }

  private async initPeerConnection(url: string, sdpNegotiateMode = SdpNegotiateStandard.COMMON_STANDARD, options: any, refreshSessionId?: boolean): Promise<void> {
    this.recoveryInfo.recoveryUrl = url
    if (!options.sessionId || refreshSessionId) {
      options.sessionId = CommonUtil.generateStandardUuid()
    }
    options.domainPolicy = options.domainPolicy || DomainPolicy.USER_DOMIAN
    options.schedulePolicy = options.schedulePolicy || SchedulePolicy.DNS
    this.startPlayOptions = options
    const autoRetry = options.recover
    if (autoRetry) {
      delete this.startPlayOptions['recover']
      this.playOption = { ...this.startPlayOptions, ...{ autoPlay: true } }
    } else {
      this.playOption = this.startPlayOptions
    }

    if (this.startPlayOptions.poster && this.startPlayOptions.poster.url) {
      PosterMask.preLoadPoster(this.startPlayOptions.poster.url)
    }

    this.loadingconfig = {
      ...{
        netQualityLoading: false,
        netQualityLoadingThreshold: NetworkQualityTypes.NETWORK_QUALITY_BAD,
      }, ...(parametersUtil.getParameter(parameterValidKeys.LOADING_CONFIG) || {})
    }
    await this.connectionsManager.initConnectionAndSdp({
      receiveVideo: this.startPlayOptions.webrtcConfig?.receiveVideo !== false,
      receiveAudio: this.startPlayOptions.webrtcConfig?.receiveAudio !== false
    }, { onTrackHandler: this.onTrackHandler.bind(this) })
    const offerSdp = this.connectionsManager.modifySdpCandidates()
    const joinQos = []
    const negotiateUrls: string[] = []
    if (sdpNegotiateMode === SdpNegotiateStandard.COMMON_STANDARD) {
      const { policy, userIp, routeType } = await this.getPullStreamDomain(url, options, joinQos)
      GlobalVariableManager.getInstance(this.identifiedId).updateClientAddr(userIp)
      for (let domain of this.domains) {
        if (!/^https?:\/\/.+/g.test(domain)) {
          domain = `https://${domain}`
        }
        negotiateUrls.push(/\/pullstream\??/.test(domain) ? domain : `${domain}${configs.NEGOTIATE_SDP_URL_PATH}`)
      }
      if (policy) {
        url = `${url}${url.indexOf('?') > 0 ? '&' : '?'}policy=${policy}`
      }
      this.setInputParams(url, routeType)
    } else {
      negotiateUrls.push(url)
      this.setInputParams(url)
    }
    const answerSdp = await this.negotiateSDP(negotiateUrls, url, { offerSdp, sdpNegotiateMode, sessionId: options.sessionId, joinQos, autoRetry })

    const answerMediaInfo = GlobalVariableManager.getInstance(this.identifiedId).getSfuInfo()
    // sence: sdk setting of receive audio & video, but RTS only respone auido or video
    if ((!answerMediaInfo.aSsrc || !answerMediaInfo.vSsrc) && this.startPlayOptions.webrtcConfig?.receiveVideo !== false && this.startPlayOptions.webrtcConfig?.receiveAudio !== false) {
      this.connectionsManager.destroyPeerConnection()
      await this.connectionsManager.initConnectionAndSdp({
        receiveVideo: !!answerMediaInfo.vSsrc,
        receiveAudio: !!answerMediaInfo.aSsrc
      }, { onTrackHandler: this.onTrackHandler.bind(this) })
    }
    this.addStartPlayEvent(!!answerMediaInfo.aSsrc, !!answerMediaInfo.vSsrc)
    await this.connectionsManager.handleAnswerSdpFromServer(answerSdp)
  }

  private addStartPlayEvent(audioEnable: boolean, videoEnable: boolean): void {
    if (audioEnable) {
      super.off(HWLLSEvents.AUDIO_START, this.firstFrameHandler.audioHandler)
      super.once(HWLLSEvents.AUDIO_START, this.firstFrameHandler.audioHandler)
      HWLLSInterval.off(`audio_start_${this.connectionsManager.getConnectionId()}`)
      HWLLSInterval.on(`audio_start_${this.connectionsManager.getConnectionId()}`, () => {
        const audioStartEventUntriggered = this.eventEmitter.listeners(HWLLSEvents.AUDIO_START).find(func => {
          return func === this.firstFrameHandler.audioHandler
        })
        if (audioStartEventUntriggered) {
          this.eventEmitter.emit(HWLLSEvents.AUDIO_START)
        }
      })
    }
    if (videoEnable) {
      super.off(HWLLSEvents.VIDEO_START, this.firstFrameHandler.videoHandler)
      super.once(HWLLSEvents.VIDEO_START, this.firstFrameHandler.videoHandler)
      HWLLSInterval.off(`video_start_${this.connectionsManager.getConnectionId()}`)
      HWLLSInterval.on(`video_start_${this.connectionsManager.getConnectionId()}`, () => {
        const videoStartEventUntriggered = this.eventEmitter.listeners(HWLLSEvents.VIDEO_START).find(func => {
          return func === this.firstFrameHandler.videoHandler
        })
        if (videoStartEventUntriggered) {
          this.eventEmitter.emit(HWLLSEvents.VIDEO_START)
        }
      })
    }
  }

  private async getPullStreamDomain(streamUrl: string, option: StartPlayOptions, joinQos: any[]): Promise<{ policy: string; userIp: string; routeType: RouteType }> {
    const accessDomain = parametersUtil.getParameter(parameterValidKeys.ACCESS_DOMAIN)
    if (accessDomain) {
      this.domains = [accessDomain]
    } else {
      if (option.domainPolicy === DomainPolicy.SHARE_DOMAIN) {
        this.domains = configs.SHARE_PULL_DOMAIN
      } else {
        const params = /(?:webrtc):\/\/([^\/]+)\/.*/ig.exec(streamUrl)
        if (params && params.length === 2) {
          this.domains = [params[1]]
        }
      }
    }

    let result = { policy: null, userIp: null, routeType: RouteType.DNS }
    try {
      GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('glsbRequest')
      if (option.schedulePolicy === SchedulePolicy.HTTPDNS) {
        let glsbDomain = parametersUtil.getParameter(parameterValidKeys.GLSB_DOMAIN) || configs.GSLB_DOMAIN
        if (!/^https?:\/\/.+/g.test(glsbDomain)) {
          glsbDomain = `https://${glsbDomain}`
        }
        const respone = await HttpRequest.fetch(
          [`${glsbDomain}/v1/live/dns?dns=${this.domains[0]}&withdomain=1&dualstack=1`],
          {
            method: 'GET',
            mode: 'cors'
          },
          {
            'Content-Type': 'application/json'
          }, configs.GSLB_REQUEST_TIMEOUT, 1, joinQos)
        if (respone.httpCode === 200 && respone.data?.errno === 0) {
          const domainInfos = respone.data.data[this.domains[0]]
          const ips = []
          ips.push(...(domainInfos?.ips || []), ...(domainInfos?.ipv6s || []))
          if (ips.length > 0) {
            LoggerFactory.debug(module_, 'gslb respone handle')
            const { ip, domain } = ips[0]
            GlobalVariableManager.getInstance(this.identifiedId).updateAccessAddr(ip)
            this.domains = [domain]
            result = { policy: domainInfos.policy, userIp: respone.data['user_ip'], routeType: RouteType.GSLB }
          }
        } else {
          LoggerFactory.error(module_, `gslb respone data error`)
        }
      }
    } catch (error) {
      LoggerFactory.error(module_, `gslb request failed: ${error}`)
    } finally {
      GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('glsbRespone')
      if (result.routeType !== RouteType.GSLB && !accessDomain) {
        this.dnsQuery(this.domains).then((ip: string) => {
          GlobalVariableManager.getInstance(this.identifiedId).updateAccessAddr(ip)
        })
      }
    }
    return result
  }

  private setInputParams(streamUrl: string, routeType = RouteType.DNS) {
    const externalParams = {
      sessionId: this.startPlayOptions.sessionId,
      routeType
    } as StreamParams
    const params = /(?:webrtc|http(?:s?)):\/\/([^\/]+)\/([^\/]+)\/([^?]+).*/ig.exec(streamUrl)
    if (params && params.length === 4) {
      externalParams.streamDomain = params[1]
      externalParams.appName = params[2]
      externalParams.streamName = params[3].replace(/\..*/, '')
      LoggerFactory.setExtraInfos({ domain: externalParams.streamDomain, appName: externalParams.appName, streamName: externalParams.streamName, svrsid: externalParams.sessionId })
    } else {
      LoggerFactory.info(module_, `get domain / appName / streamName failed`)
    }

    GlobalVariableManager.getInstance(this.identifiedId).setStreamParams(externalParams)
  }

  private async negotiateSDP(negotiateUrls: string[], streamUrl: string, options: any): Promise<string> {
    LoggerFactory.info(module_, `[KPI] negotiateSDP begin`)
    GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('negotiateSDPRequest')
    let data: any
    let httpCode = -1
    let retCode = -1
    let retMsg: string
    if (options.sdpNegotiateMode === SdpNegotiateStandard.COMMON_STANDARD) {
      data = {
        "sessionid": options.sessionId,
        "streamurl": streamUrl,
        "localsdp": {
          "type": "offer",
          "sdp": options.offerSdp
        }
      }
    } else {
      data = {
        "version": "v1.0",
        "sessionId": options.sessionId,
        "localSdp": {
          "type": "offer",
          "sdp": options.offerSdp
        }
      }
    }

    LoggerFactory.info(module_, `fetch sdp request: ${JSON.stringify(data)}`)
    options.joinQos.push({ 'offersdp': options.offerSdp })

    try {
      let respone = await this.tryNegotiateFetch(negotiateUrls, options, data, false)

      // 如果服务端不支持信令解压，则发送不压缩的消息
      if (this.errMap.get(respone.data.errcode || respone.data.code) === ErrorCode.HWLLS_ERROR_ENCODING_NOT_ACCEPTED) {
        LoggerFactory.error(module_, `${ErrorMsg[ErrorCode.HWLLS_ERROR_ENCODING_NOT_ACCEPTED]}`)

        respone = await this.tryNegotiateFetch(negotiateUrls, options, data, false)
      }

      httpCode = respone.httpCode

      LoggerFactory.setExtraInfos({ svrsid: respone.data.svrsid })
      GlobalVariableManager.getInstance(this.identifiedId).setPullMediaParams({ svrSid: respone.data.svrsid, svrsig: respone.data.svrsig, streamUrl: streamUrl, clientAddr: respone.data.clientaddr })

      retCode = isNaN(respone.data.errcode) ? respone.data.code : respone.data.errcode
      retMsg = respone.data.message || respone.data.errmsg
      if (retCode !== 0 && retCode !== 200) {
        if (this.errMap.get(retCode)) {
          throw new HwLLSError(this.errMap.get(retCode))
        } else {
          throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, `${retCode} _ ${retMsg}`)
        }
      }
      const answerSdp = (respone.data.remotesdp || respone.data.remoteSdp).sdp
      GlobalVariableManager.getInstance(this.identifiedId).setSfuInfo(this.connectionsManager.getSfuInfoFromSdp(answerSdp))
      return answerSdp
    } catch (error) {
      LoggerFactory.error(module_, `[KPI] negotiateSDP occur error: ${error}`)
      const code = error.getCode ? error.getCode() : error.name
      if (httpCode === -1) {
        httpCode = code
      }
      if (retCode === -1) {
        retCode = code
        retMsg = error.getMsg ? error.getMsg() : error.message
      }
      throw error
    } finally {
      GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('negotiateSDPRespone')
      this.stats.reportStartPullStreamReqEvent({ httpCode, retCode, retMsg, joinQos: options.joinQos, autoRetry: options.autoRetry ? 1 : 0 })
    }
  }

  private async tryNegotiateFetch(negotiateUrls: string[], options: any, data: any, dataCompression: boolean): Promise<any> {
    const fetchReq = { request: JSON.stringify(data), header: { 'Content-Type': 'application/json' } }
    if (dataCompression) {
      try {
        const gzipReq = pako.gzip(fetchReq.request, { to: 'string' })
        fetchReq.request = gzipReq
        fetchReq.header['Content-Encoding'] = 'gzip'
      } catch (error) {
        LoggerFactory.error(module_, `negotiateSDP gzip request failed`)
      }
    }

    const respone = await HttpRequest.fetch(
      negotiateUrls,
      {
        method: 'POST',
        body: fetchReq.request,
        mode: 'cors'
      },
      fetchReq.header, configs.PULLSTREAM_REQUEST_TIMEOUT, this.startPlayOptions.domainPolicy === DomainPolicy.SHARE_DOMAIN ? RetryNumber.SHARE_DOMAIN_RETRY_TIMES : RetryNumber.USER_DOMAIN_RETRY_TIMES, options.joinQos)

    LoggerFactory.info(module_, `[KPI] negotiateSDP get respone: ${JSON.stringify(respone)}`)

    if (respone.httpCode !== 200) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_SERVER_CONNECT_FAIL)
    }

    if (!respone.data || JSON.stringify(respone.data) === '{}') {
      LoggerFactory.error(module_, 'negotiateSDP, respone is null')
      throw new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, 'respone is null')
    }

    return respone;
  }

  private async stopSignal(): Promise<void> {
    const params = GlobalVariableManager.getInstance(this.identifiedId)?.getPullMediaParams()
    if (!params?.svrsig) {
      LoggerFactory.warn(module_, `stop stream signal failed, svrsig is null.`)
      return
    }

    for (let domain of this.domains) {
      try {
        if (!/^https?:\/\/.+/g.test(domain)) {
          domain = `https://${domain}`
        }
        const negotiateUrl = /\/pullstream\??/.test(domain) ? domain.replace(/(https?:\/\/[^\/]*)\/.*/g, `$1${configs.STOP_PLAY_URL_PATH}`) : `${domain}${configs.STOP_PLAY_URL_PATH}`
        await HttpRequest.fetch([negotiateUrl], {
          method: 'POST',
          body: JSON.stringify({
            'streamurl': params.streamUrl,
            "svrsig": params.svrsig
          }),
          mode: 'cors'
        }, {
          'Content-Type': 'application/json',
        })
        break
      } catch (error) {
        LoggerFactory.warn(module_, `stop stream signal failed: ${error}`)
      }
    }
  }

  private onTrackHandler(e: any): void {
    LoggerFactory.info(module_, `peerconnection ontrack event: ${e.track.kind}`)
    const trackType = e.track.kind
    if (trackType === MediaType.TRACK_TYPE_VIDEO || trackType === MediaType.TRACK_TYPE_AUDIO) {
      if (!this.liveStream) {
        this.liveStream = new RemoteStream(this.stats, this.eventEmitter)
      }
      this.liveStream.addRemoteTrack(e.track)
      const isVideo = trackType === MediaType.TRACK_TYPE_VIDEO
      if (isVideo) {
        HWLLSInterval.off(`video_stuck_${this.connectionsManager.getConnectionId()}`)
        HWLLSInterval.on(`video_stuck_${this.connectionsManager.getConnectionId()}`, (isStuck: boolean) => {
          if (!isStuck && this.loadingconfig.netQualityLoading && this.preNetQuality >= this.loadingconfig.netQualityLoadingThreshold) {
            return
          }
          this.eventEmitter.emit(HWLLSEvents.VIDEO_STUCK, isStuck)
        })
      }

      const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
      const streamParams = GlobalVariableManager.getInstance(this.identifiedId)?.getStreamParams()
      const mediaParams = GlobalVariableManager.getInstance(this.identifiedId)?.getPullMediaParams()

      this.liveStream.play({ ...this.playOption, audio: !isVideo, video: isVideo }).catch(error => {
        const isPlayErrorIgnore = error instanceof HwLLSError && (error.getCode() === ErrorCode.HWLLS_PLAY_NOT_ALLOW || /The operation was aborted/ig.test(error.getMsg()))
        if (isPlayErrorIgnore) {
          this.eventEmitter.emit('Error', {
            errCode: error.getCode(),
            errDesc: error.getMsg()
          })
        } else {
          let pullStreamResult = PullStreamResult.FAIL
          if (error instanceof HwLLSError) {
            if (error.getCode() !== ErrorCode.HWLLS_PLAY_TIMEOUT) {
              if (/interrupted by a new load request/ig.test(error.getMsg())) {
                pullStreamResult = PullStreamResult.INTERRUPT
              }
              if (isVideo && sfuInfo.aSsrc) {
                const audioStartEventUntriggered = this.eventEmitter.listeners(HWLLSEvents.AUDIO_START).find(func => {
                  return func === this.firstFrameHandler.audioHandler
                })
                if (audioStartEventUntriggered) {
                  this.stats.reportPullMediaStreamResult(MediaType.TRACK_TYPE_AUDIO, pullStreamResult, { playTrackKpi: this.prePlayTrackCache, sfuInfo, streamParams, mediaParams })
                }
              }
              this.eventEmitter.emit('Error', {
                errCode: error.getCode(),
                errDesc: error.getMsg()
              })
            } else {
              if (!this.connectionsManager.isConnectionEstablished()) {
                pullStreamResult = PullStreamResult.CONNECTION_UNCOMPLETE
              }
              if (isVideo && sfuInfo.aSsrc) {
                const audioStartEventUntriggered = this.eventEmitter.listeners(HWLLSEvents.AUDIO_START).find(func => {
                  return func === this.firstFrameHandler.audioHandler
                })
                if (audioStartEventUntriggered) {
                  GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi('audioTimeOut')
                  this.stats.reportPullMediaStreamResult(MediaType.TRACK_TYPE_AUDIO, pullStreamResult, { playTrackKpi: this.prePlayTrackCache, sfuInfo, streamParams, mediaParams })
                }
              }
              GlobalVariableManager.getInstance(this.identifiedId).updatePlayTrackKpi(`${isVideo ? 'video' : 'audio'}TimeOut`)
            }
          } else {
            this.eventEmitter.emit('Error', {
              errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
              errDesc: ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
            })
          }
          this.stats.reportPullMediaStreamResult(trackType, pullStreamResult, { playTrackKpi: this.prePlayTrackCache, sfuInfo, streamParams, mediaParams })
        }
      })
      this.stats.startMediaStatistic()
    }
  }

  private resetClient4Switch(): void {
    this.stats.cancelMediaStatistic()
    this.connectionsManager.destroyPeerConnection()
    this.prePlayTrackCache = GlobalVariableManager.getInstance(this.identifiedId)?.getPlayTrackKpi()
    GlobalVariableManager.delInstance(this.identifiedId)
    clearTimeout(this.debugStatisticTimer.timer)
    clearTimeout(this.detectTimer.timer)
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo.retryTimes = 0
  }

  private resetClient4Stop(): void {
    this.stats.cancelMediaStatistic()
    this.connectionsManager.destroyPeerConnection()
    this.prePlayTrackCache = GlobalVariableManager.getInstance(this.identifiedId)?.getPlayTrackKpi()
    GlobalVariableManager.delInstance(this.identifiedId)
    clearTimeout(this.debugStatisticTimer.timer)
    clearTimeout(this.detectTimer.timer)
    clearTimeout(this.netQualityTimer)
    this.netQualityTimer = null
    this.preNetQuality = NetworkQualityTypes.NETWORK_QUALITY_INIT
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo.retryTimes = 0
    this.recoveryInfo.recoveryUrl = null
    this.liveStream?.destory()
    this.liveStream = null
    this.startPlayOptions = null
  }

  private resetClient(): void {
    this.stats.cancelMediaStatistic()
    this.connectionsManager.destroyPeerConnection()
    this.prePlayTrackCache = GlobalVariableManager.getInstance(this.identifiedId)?.getPlayTrackKpi()
    GlobalVariableManager.delInstance(this.identifiedId)
    clearTimeout(this.debugStatisticTimer.timer)
    this.debugStatisticTimer = {
      timer: null,
      interval: 1
    }
    clearTimeout(this.detectTimer.timer)
    this.detectTimer = {
      timer: null,
      interval: 3,
      interruptRetry: null
    }
    clearTimeout(this.netQualityTimer)
    this.netQualityTimer = null
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo.retryTimes = 0
    this.recoveryInfo.recoveryUrl = null
    this.liveStream?.destory()
    this.liveStream = null
    this.startPlayOptions = null
    this.preNetQuality = NetworkQualityTypes.NETWORK_QUALITY_INIT
  }

  private getStreamStatisticInfo(perStatistic: any, interval: number): void {
    this.debugStatisticTimer.interval = interval
    this.debugStatisticTimer.timer = setTimeout(() => {
      clearTimeout(this.debugStatisticTimer.timer)
      const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId).getSfuInfo()
      const statisticInfo: StatisticInfo = {}
      if (sfuInfo.aSsrc) {
        const audioStatistic = MediaStats.getLeastestAudioInfo(this.connectionsManager.getConnectionId(), sfuInfo.aSsrc)
        const freezeInto = GlobalVariableManager.getInstance(this.identifiedId).getMediaFreezeStatistic(MediaType.TRACK_TYPE_AUDIO)
        if (audioStatistic) {
          const bitRate = perStatistic.audioReceiveBytes !== 0 ? Math.round((CommonUtil.getValue(audioStatistic.bytesReceived, 0) - perStatistic.audioReceiveBytes) * 8 / 1000) : 0
          statisticInfo.audio = {
            mediaType: MediaType.TRACK_TYPE_AUDIO,
            codec: 'OPUS',
            bitRate: Math.round(bitRate * 1000 / (interval * 1000)),
            packetsLost: Math.max(CommonUtil.getValue(audioStatistic.packetsLost, 0), 0),
            jitter: Math.floor(CommonUtil.getValue(audioStatistic.jitter, 0) * 1000),
            bytesReceived: CommonUtil.getValue(audioStatistic.bytesReceived, 0),
            packetsReceived: CommonUtil.getValue(audioStatistic.packetsReceived, 0),
            freeze200Count: freezeInto.freeze200Count,
            freeze200Duration: freezeInto.freeze200Duration
          } as StatisticBase
          perStatistic.audioReceiveBytes = CommonUtil.getValue(audioStatistic.bytesReceived, 0)
        }
      }
      if (sfuInfo.vSsrc) {
        const videoStatistic = MediaStats.getLeastestVideoInfo(this.connectionsManager.getConnectionId(), sfuInfo.vSsrc)
        if (videoStatistic) {
          const bitRate = perStatistic.videoReceiveBytes !== 0 ? Math.round((CommonUtil.getValue(videoStatistic.bytesReceived, 0) - perStatistic.videoReceiveBytes) * 8 / 1000) : 0
          const frameRate = perStatistic.videoFrames !== 0 ? (CommonUtil.getValue(videoStatistic.framesDecoded, 0) - perStatistic.videoFrames) : 0
          perStatistic.videoFrames = CommonUtil.getValue(videoStatistic.framesDecoded, 0)
          const freezeInto = GlobalVariableManager.getInstance(this.identifiedId).getMediaFreezeStatistic(MediaType.TRACK_TYPE_VIDEO)

          statisticInfo.video = {
            mediaType: MediaType.TRACK_TYPE_VIDEO,
            codec: 'H264',
            jitter: Math.floor(CommonUtil.getValue(videoStatistic.jitter, 0) * 1000),
            bitRate: Math.round(bitRate * 1000 / (interval * 1000)),
            bytesReceived: CommonUtil.getValue(videoStatistic.bytesReceived, 0),
            packetsReceived: CommonUtil.getValue(videoStatistic.packetsReceived, 0),
            packetsLost: Math.max(CommonUtil.getValue(videoStatistic.packetsLost, 0), 0),
            frameRate: Math.ceil(frameRate * 1000 / (interval * 1000)),
            width: CommonUtil.getValue(videoStatistic.frameWidth, 0),
            height: CommonUtil.getValue(videoStatistic.frameHeight, 0),
            freeze200Count: freezeInto.freeze200Count,
            freeze200Duration: freezeInto.freeze200Duration,
            freeze600Count: freezeInto.freeze600Count,
            freeze600Duration: freezeInto.freeze600Duration
          } as VideoStatistic
          perStatistic.videoReceiveBytes = CommonUtil.getValue(videoStatistic.bytesReceived, 0)
        }
      }

      this.eventEmitter.emit(HWLLSEvents.MEDIA_STATISTIC, statisticInfo)
      this.getStreamStatisticInfo(perStatistic, interval)
    }, interval * 1000)
  }

  private async dnsQuery(domains: string[]): Promise<string> {
    if (parametersUtil.getParameter(parameterValidKeys.DNS_QUERY_ENABLE) === false || !domains || domains.length < 1) {
      return ""
    }
    let ip4ARecord = ''
    for (const domain of domains) {
      const dnsQueryReq = {
        type: 'query',
        id: 0,
        flags: 256,
        questions: [{
          type: 'A',
          name: domain.replace(/^\.|\.$/gm, ''),
        }]
      }
      for (const dnsDomain of configs.PUBLIC_DNS_SERVER) {
        try {
          const dnsResult = await HttpRequest.fetch([`https://${dnsDomain}/dns-query?dns=${CommonUtil.dnsRequestEncode(dnsQueryReq)}`], {
            method: 'GET',
            mode: 'cors'
          }, {}, configs.DNS_REQUEST_TIMEOUT)

          if (dnsResult.httpCode !== 200) {
            continue
          }

          const result = dnsPacket.decode(Buffer.from(dnsResult.data))
          LoggerFactory.debug(module_, `dnsQuery result for ${domain}:  ${JSON.stringify(result)}`)
          for (const answer of (result?.answers || [])) {
            if (answer.type === 'A') {
              ip4ARecord = answer.data
              break
            }
          }
          if (!ip4ARecord) {
            continue
          }
        } catch (error) {
          LoggerFactory.error(module_, `dnsQuery failed for ${domain}:  ${error}`)
        }
      }
      if (ip4ARecord) {
        break
      }
    }

    return ip4ARecord
  }
}
