
import { HWLLSEvents, PosterMode } from "src/common/ObjDefinition"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"
import commonUtil from "src/utils/CommonUtil"
import { RTCPlayer, SoundMeter } from "./PlayerBase"
import { LoadingMask, PlayerAdapter, PosterMask } from "./PlayerUtils"
import fullScreen from "./PlayerUtils"

export class RTCAudioPlayer extends RTCPlayer {
  private readonly muted_: boolean
  private volume_: number
  private soundMeter_: SoundMeter
  private autoPlay_: boolean

  constructor(option: {
    playerDiv?: any;
    playerId: string;
    track: MediaStreamTrack;
    muted: boolean;
    volume: number;
    autoPlay: boolean;
  }) {
    super({
      track: option.track,
      playerDiv: option.playerDiv,
      playerId: option.playerId
    })
    this.module_ = 'RTCAudioPlayer'
    this.muted_ = option.muted
    this.volume_ = (typeof option?.volume === 'number') ? option.volume : 0.5
    this.soundMeter_ = null
    this.autoPlay_ = option.autoPlay
  }

  public async play(): Promise<Error> {
    return new Promise((resolve, reject) => {
      let element: any
      let multiPlayAction = true
      if (this.playerDiv_) {
        element = this.playerDiv_.querySelector(`#audio_${this.playerId_}`)
      } else {
        element = this.playerElement_
      }
      if (!element) {
        element = document.createElement('audio')
        element.muted = this.muted_
        element.setAttribute('id', `audio_${this.playerId_}`)
        this.autoPlay_ && element.setAttribute('autoplay', '')
        element.setAttribute('playsinline', '')
        element.setAttribute('webkit-playsinline', '')
        this.playerDiv_?.appendChild(element)
        multiPlayAction = false
      }

      if (this.mediaStream_.getAudioTracks().length === 0) {
        this.mediaStream_.addTrack(this.track_)
      }
      element.srcObject = this.mediaStream_
      this.playerElement_ = element
      if (!this.muted_) {
        this.setVolume(this.volume_)
      } else {
        this.playerElement_.muted = true
      }

      this.addFirstFrameCallBackListener()
      this.closeAllEvents()
      this.handleEvents()

      if (!this.autoPlay_) {
        return
      }
      clearTimeout(this.playTimeOutTimer)
      this.playTimeOutTimer = setTimeout(() => {
        LoggerFactory.info(this.module_, `trigger audioCanPlay event by Timer`)
        this.event_.emit('audioCanPlay')
        clearTimeout(this.playTimeOutTimer)
        this.playTimeOutTimer = setTimeout(() => {
          LoggerFactory.info(this.module_, `audio playing timeout`)
          clearTimeout(this.playTimeOutTimer)
          this.playTimeOutTimer = null
          reject(new HwLLSError(ErrorCode.HWLLS_PLAY_TIMEOUT))
        }, multiPlayAction ? 10000 : 8000)
      }, multiPlayAction ? 0 : 2000)
      this.listenHandlers.playHandler && this.event_.removeListener('audioCanPlay', this.listenHandlers.playHandler)
      this.listenHandlers.playHandler = this.doPlay.bind(this, resolve, reject, {
        volume: this.volume_,
        muted: this.muted_
      })
      this.event_.on('audioCanPlay', this.listenHandlers.playHandler)
    })
  }

  public isMuted(): boolean {
    return Boolean(this.playerElement_?.muted)
  }

  public setVolume(volume?: number): void {
    this.playerElement_.muted = false
    if (Number.isNaN(volume)) {
      this.playerElement_.volume = this.volume_
    } else {
      this.playerElement_.volume = volume
    }
  }

  public getAudioLevel(): number {
    if (!this.soundMeter_) {
      this.soundMeter_ = new SoundMeter()
      this.soundMeter_.connectToSource(this.track_)
    }
    return this.soundMeter_.getVolume()
  }

  public stop(): void {
    super.stop()
    if (this.soundMeter_) {
      this.soundMeter_.stop()
      this.soundMeter_ = null
    }
  }

  public async resume(): Promise<Error> {
    return super.resume({
      streamId: this.playerId_,
      volume: this.volume_,
      muted: this.muted_
    })
  }

  public replaceTrack(track: MediaStreamTrack): void {
    super.replaceTrack(track)
    if (this.soundMeter_) {
      this.soundMeter_.stop()
      this.soundMeter_ = null
    }
  }

  public async addFirstFrameCallBackListener() {
    // @ts-ignore
    if (!window.MediaStreamTrackProcessor) {
      return
    }
    // @ts-ignore
    const processor = new MediaStreamTrackProcessor({ track: this.track_ })
    const source = processor.readable.getReader()
    const { done, value } = await source.read()
    if (done && this.playerElement_) {
      LoggerFactory.debug(this.module_, 'first audio frame received.')
      this.event_.emit(HWLLSEvents.AUDIO_START)
      value?.close()
    }
  }
}

export class RTCVideoPlayer extends RTCPlayer {
  private readonly objectFit_: string
  private loadingUtils: LoadingMask
  private autoPlay_: boolean
  private showLoading: boolean
  private poster_: any

  constructor(option: {
    parentDiv: any;
    playerId: string;
    objectFit: string;
    track: MediaStreamTrack;
    autoPlay: boolean;
    showLoading: boolean;
    poster: any;
  }) {
    super({
      track: option.track,
      playerDiv: option.parentDiv,
      playerId: option.playerId
    })
    this.module_ = 'RTCVideoPlayer'
    this.objectFit_ = option.objectFit
    this.loadingUtils = new LoadingMask(option.parentDiv)
    this.autoPlay_ = option.autoPlay
    this.videoBufferedReday = false
    this.showLoading = option.showLoading
    this.poster_ = option.poster
    if (this.poster_) {
      PosterMask.attachPoster(option.parentDiv, this.poster_.url, this.poster_.mode || PosterMode.FILL)
      if (!this.autoPlay_ && this.poster_.startEnable) {
        PosterMask.disPlayPoster(true, this.poster_.url)
      }
    }
  }

  public async play(): Promise<Error> {
    return new Promise((resolve, reject) => {
      let element: any = this.playerDiv_.querySelector(`#video_${this.playerId_}`)
      let multiPlayAction = true
      if (!element) {
        element = document.createElement('video')
        element.muted = true
        element.setAttribute('id', `video_${this.playerId_}`)
        element.style.width = "100%"
        element.style.height = "100%"
        element.style.position = "absolute"
        element.style['object-fit'] = this.objectFit_
        this.autoPlay_ && element.setAttribute('autoplay', '')
        element.setAttribute('playsinline', '')
        element.setAttribute('webkit-playsinline', '')
        commonUtil.isTencentX5BrowserKernel() && element.setAttribute('x5-playsinline', '')
        element.setAttribute('muted', '')
        this.playerDiv_.appendChild(element)
        multiPlayAction = false
      }

      if (this.mediaStream_.getVideoTracks().length === 0) {
        this.mediaStream_.addTrack(this.track_)
      }
      element.srcObject = this.mediaStream_
      this.playerElement_ = element
      this.addFirstFrameCallBackListener()
      this.closeAllEvents()
      this.handleEvents()
      if (!this.autoPlay_) {
        return
      }
      clearTimeout(this.playTimeOutTimer)
      this.playTimeOutTimer = setTimeout(() => {
        LoggerFactory.info(this.module_, `trigger videoCanPlay event by Timer`)
        this.event_.emit('videoCanPlay')
        clearTimeout(this.playTimeOutTimer)
        this.playTimeOutTimer = setTimeout(() => {
          LoggerFactory.info(this.module_, `video playing timeout`)
          clearTimeout(this.playTimeOutTimer)
          this.playTimeOutTimer = null
          reject(new HwLLSError(ErrorCode.HWLLS_PLAY_TIMEOUT))
          this.listenHandlers.playInterruptHandler && this.event_.removeListener('playInterrupt', this.listenHandlers.playInterruptHandler)
        }, multiPlayAction ? 10000 : 8000)
      }, multiPlayAction ? 0 : 2000)

      if (this.module_.indexOf('Video') > 0) {
        this.listenHandlers.playInterruptHandler && this.event_.removeListener('playInterrupt', this.listenHandlers.playInterruptHandler)
        this.listenHandlers.playInterruptHandler = this.playInterruptHandler.bind(this, reject)
        this.event_.once('playInterrupt', this.listenHandlers.playInterruptHandler)
      }

      this.listenHandlers.playHandler && this.event_.removeListener('videoCanPlay', this.listenHandlers.playHandler)
      this.listenHandlers.playHandler = this.doPlay.bind(this, resolve, reject, {
        objectFit: this.objectFit_
      })
      this.event_.on('videoCanPlay', this.listenHandlers.playHandler)
    })
  }

  public async resume(): Promise<Error> {
    if (this.poster_) {
      PosterMask.disPlayPoster(false, this.poster_.url)
    }
    if (!this.manualStartedPlay()) {
      this.listenHandlers.playHandler && this.event_.removeListener('videoCanPlay', this.listenHandlers.playHandler)
      this.listenHandlers.playHandler = () => {
        this.loadingMask(false)
        this.resume()
      }
      this.event_.on('videoCanPlay', this.listenHandlers.playHandler)
      if (!this.videoBufferedReday && this.showLoading) {
        this.loadingMask(true)
      }
      await PlayerAdapter.multiPlatformAdapter()
    }
    return await super.resume({ streamId: this.playerId_, screentFit: this.objectFit_ })
  }

  public pause(): void {
    super.pause()
    if (this.poster_?.pauseEnable) {
      PosterMask.disPlayPoster(true, this.poster_.url)
    }
  }

  public stop(): void {
    super.stop()
    this.loadingUtils.stopLoadingMask()
    this.poster_ && PosterMask.detachPoster(this.playerDiv_, this.poster_.url)
    this.videoBufferedReday = false
  }

  public loadingMask(isShow: boolean): void {
    if (isShow) {
      if (this.autoPlay_ || this.manualStartedPlay()) {
        this.loadingUtils.loadingMask(true)
      }
    } else {
      this.loadingUtils.loadingMask(false)
    }
  }

  public addFirstFrameCallBackListener(): void {
    if (!this.playerElement_.requestVideoFrameCallback) {
      return
    }
    this.playerElement_.requestVideoFrameCallback(() => {
      LoggerFactory.debug(this.module_, 'first video frame received.')
      this.event_.emit(HWLLSEvents.VIDEO_START)
    })
  }


  public exitFullScreen(): void {
    fullScreen.exitFullScreen()
  }

  public enterFullScreen(): void {
    fullScreen.enterFullScreen(this.playerDiv_, this.playerElement_, this.event_)
  }

  private manualStartedPlay(): boolean {
    return this.event_.eventNames().includes('videoCanPlay')
  }
}