import { LoggerFactory } from "src/logger/LoggerFactory"
import commonUtil from "src/utils/CommonUtil"
import HlsJS from 'hls.js'
import { EventEmitter } from "events"
import { PlayerViewMode, PosterMode } from "src/common/ObjDefinition"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoadingMask, PlayerAdapter, PosterMask } from "./PlayerUtils"

export class HlsPlayer {
  private module_ = 'HlsPlayer'
  private playerEventHandler: any
  private event: EventEmitter
  private parentDiv: HTMLElement
  private objectFit: PlayerViewMode
  private muted: boolean
  private player: HTMLVideoElement
  private hlsPlayer: HlsJS
  private playUrl: string
  private playTimeOutTimer: NodeJS.Timeout
  private pauseCount: number
  private isNativePlayer: boolean
  private statisticInfo: any
  private videoBufferedReday: boolean
  private loadingUtils: LoadingMask
  private autoPlay: boolean
  private showLoading: boolean
  private poster_: any

  constructor(options: {
    eventEmitter: EventEmitter;
    url: string;
    parentDiv: HTMLElement;
    objectFit: PlayerViewMode;
    muted: boolean;
    poster: any;
  }) {
    this.loadingUtils = new LoadingMask(options.parentDiv)
    this.event = options.eventEmitter
    this.parentDiv = options.parentDiv
    this.objectFit = options.objectFit || PlayerViewMode.CONTAIN
    this.muted = Boolean(options.muted)
    this.playUrl = options.url
    this.pauseCount = 0
    this.poster_ = options.poster
    this.createHlsPlayer()
    this.playerEventHandler = {
      errorHandler: (event) => {
        LoggerFactory.error(this.module_, `video element error encountered: ${JSON.stringify(event)}`)
      },
      playingHandler: (event) => {
        LoggerFactory.debug(this.module_, `video element playing encountered: ${JSON.stringify(event)}`)
      },
      loadedMetaHandler: (event) => {
        LoggerFactory.debug(this.module_, `video element loadedMeta encountered: ${JSON.stringify(event)}`)
      },
      loadedDataHandler: (event) => {
        LoggerFactory.debug(this.module_, `video element loadedData encountered: ${JSON.stringify(event)}`)
        this.loadingMask(false)
      },
      canPlayHandler: (event) => {
        LoggerFactory.info(this.module_, `video element canPlay encountered: ${JSON.stringify(event)}`)
        this.event.emit('hlsCanPlay')
        this.loadingMask(false)
        this.videoBufferedReday = true
      },
      mediaRecoverHandler: () => {
        this.hlsPlayer?.swapAudioCodec()
        this.hlsPlayer?.recoverMediaError()
      },
      mediaAttachedHandler: () => {
        LoggerFactory.info(this.module_, `HlsPlayer MEDIA_ATTACHED`)
      },
      manifestParsedHandler: (event, data) => {
        LoggerFactory.info(this.module_, `HlsPlayer MANIFEST_PARSED, quality level: ${JSON.stringify(data.levels)} `)
      },
      levelSwitchedHandler: (event, data) => {
        LoggerFactory.info(this.module_, `HlsPlayer LEVEL_SWITCHED, ${JSON.stringify(data)}`)
      },
      frameDropHandler: (event, data) => {
        LoggerFactory.info(this.module_, `HlsPlayer FPS_DROP, ${JSON.stringify(data)}`)
        if (!this.isNativePlayer) {
          const mediaInfo = this.hlsPlayer.levels[this.hlsPlayer.currentLevel]
          const preTotalFrameDecoded = this.statisticInfo?.totalFrameDecoded || 0
          this.statisticInfo = {
            width: mediaInfo.width || 0,
            height: mediaInfo.height || 0,
            audioCodec: mediaInfo.audioCodec,
            videoCodec: mediaInfo.videoCodec,
            bitRate: mediaInfo.realBitrate || mediaInfo.bitrate || 0,
            frameRate: data.currentDecoded || 0,
            totalFrameDecoded: data.currentDecoded + preTotalFrameDecoded,
            frameDropped: data.currentDropped || 0,
            totalFrameDropped: data.totalDroppedFrames || 0
          }
        }
      },
      frameDropLevelCappingHandler: (event, data) => {
        LoggerFactory.info(this.module_, `HlsPlayer FPS_DROP_LEVEL_CAPPING, ${JSON.stringify(data)}`)
      },
      mediaErrHandler: (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case HlsJS.ErrorTypes.NETWORK_ERROR:
              LoggerFactory.error(this.module_, `HlsPlayer NETWORK_ERROR ${JSON.stringify(data)} encountered, try to recover`)
              this.hlsPlayer.startLoad()
              break;
            case HlsJS.ErrorTypes.MEDIA_ERROR:
              LoggerFactory.error(this.module_, `HlsPlayer NETWORK_ERROR ${JSON.stringify(data)} encountered, try to recover`)
              this.event.emit(HlsJS.ErrorTypes.MEDIA_ERROR)
              this.hlsPlayer.recoverMediaError()
              this.event.once(HlsJS.ErrorTypes.MEDIA_ERROR, this.playerEventHandler.mediaRecoverHandler)
              break;
            default:
              LoggerFactory.error(this.module_, `HlsPlayer other fatal ERROR ${JSON.stringify(data)} encountered, try to recover`)
              this.hlsPlayer.destroy()
              break;
          }
        }
      }
    }
  }

  public async play(autoPlay: boolean, showLoading: boolean): Promise<void> {
    this.autoPlay = autoPlay === false ? false : true
    if (this.poster_) {
      PosterMask.attachPoster(this.parentDiv, this.poster_.url, this.poster_.mode || PosterMode.FILL)
      if (!this.autoPlay && this.poster_.startEnable) {
        PosterMask.disPlayPoster(true, this.poster_.url)
      }
    }
    this.showLoading = showLoading
    return new Promise((resolve, reject) => {
      let element: any = this.parentDiv.querySelector(`#video_player`)
      let multiPlayAction = false
      if (!element) {
        multiPlayAction = true
        element = document.createElement('video')
        element.setAttribute('id', 'video_player')
        element.style.width = "100%"
        element.style.height = "100%"
        element.style.position = "absolute"
        element.style['object-fit'] = this.objectFit
        element.setAttribute('autoplay', '')
        element.setAttribute('playsinline', '')
        element.setAttribute('webkit-playsinline', '')
        commonUtil.isTencentX5BrowserKernel() && element.setAttribute('x5-playsinline', '')
        this.parentDiv.appendChild(element)
        this.player = element
      }
      this.offPlayerAndListener()
      this.initPlayerAndListener()
      if (!this.muted) {
        this.player.volume = 0.5
      } else {
        this.player.muted = true
      }
      if (this.autoPlay) {
        return
      }
      clearTimeout(this.playTimeOutTimer)
      this.playTimeOutTimer = setTimeout(() => {
        LoggerFactory.info(this.module_, `trigger videoCanPlay event by Timer`)
        this.event.emit('hlsCanPlay')
        clearTimeout(this.playTimeOutTimer)
        this.playTimeOutTimer = setTimeout(() => {
          LoggerFactory.info(this.module_, `video playing timeout`)
          clearTimeout(this.playTimeOutTimer)
          this.playTimeOutTimer = null
          resolve()
        }, 2000)
      }, multiPlayAction ? 0 : 1000)

      this.playerEventHandler.playHandler && this.event.removeListener('hlsCanPlay', this.playerEventHandler.playHandler)
      this.playerEventHandler.playHandler = async () => {
        await PlayerAdapter.multiPlatformAdapter()
        this.player?.play().then(() => {
          LoggerFactory.info(this.module_, `play success, parentDiv id:${this.parentDiv?.id}`)
          this.afterPlayStrategy()
          resolve()
        }).catch((error: Error) => {
          LoggerFactory.error(this.module_, `play failed, errmsg=${error}, parentDiv id:${this.parentDiv?.id}`)
          if (error.name === 'NotAllowedError') {
            reject(new HwLLSError(ErrorCode.HWLLS_PLAY_NOT_ALLOW))
          } else {
            reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error.message))
          }
        }).finally(() => {
          clearTimeout(this.playTimeOutTimer)
        })
      }
      this.event.on('hlsCanPlay', this.playerEventHandler.playHandler)
    })
  }

  public pause(): void {
    this.player?.pause()
    if (this.poster_?.pauseEnable) {
      PosterMask.disPlayPoster(true, this.poster_.url)
    }
  }

  public async resume(): Promise<void> {
    if (this.poster_) {
      PosterMask.disPlayPoster(false, this.poster_.url)
    }
    if (!this.manualStartedPlay()) {
      this.playerEventHandler.playHandler && this.event.removeListener('hlsCanPlay', this.playerEventHandler.playHandler)
      this.playerEventHandler.playHandler = () => {
        this.player?.play()
      }
      this.event.on('hlsCanPlay', this.playerEventHandler.playHandler)
      if (!this.videoBufferedReday && this.showLoading) {
        this.loadingMask(true)
      }
    }
    await this.player?.play()
  }

  public mute(isMute: boolean): void {
    if (this.player) {
      this.player.muted = isMute
      this.muted = isMute
    }
  }

  public async switchPlay(options: any): Promise<void> {
    this.statisticInfo = null
    this.videoBufferedReday = false
    this.offPlayerAndListener()
    if (this.parentDiv && this.parentDiv.id !== options.parentDiv?.id) {
      const videoElement = document.querySelector(`#${this.parentDiv.id} #video_player`)
      videoElement && this.parentDiv.appendChild(videoElement)
      document.querySelector(`#${this.parentDiv.id} #video_player`)?.remove()
      this.parentDiv = options.parentDiv
    }
    this.playUrl = options.url
    this.poster_ = options.poster
    await this.play(options.autoPlay, options.showLoading)
  }

  public stopPlay(): void {
    this.loadingUtils.stopLoadingMask()
    document.querySelector(`#${this.parentDiv.id} #video_player`)?.remove()
    this.poster_ && PosterMask.detachPoster(this.parentDiv, this.poster_.url)
    this.offPlayerAndListener()
    this.playUrl = null
    this.videoBufferedReday = false
    clearTimeout(this.playTimeOutTimer)
    this.hlsPlayer?.destroy()
  }

  public destory(): void {
    this.stopPlay()
    this.player = null
    this.hlsPlayer = null
  }

  public getPlayoutVolume(): number {
    if (this.player?.muted) {
      return 0
    }
    return (this.player?.volume || 0) * 100
  }

  public setPlayoutVolume(volume: number): boolean {
    if (this.player) {
      this.player.volume = Number((volume / 100).toFixed(2))
      return true
    }
    return false
  }

  public getStatisticInfo(): any {
    return this.statisticInfo
  }

  public loadingMask(isShow: boolean): void {
    if (isShow) {
      if (this.autoPlay || this.manualStartedPlay()) {
        this.loadingUtils.loadingMask(true)
      }
    } else {
      this.loadingUtils.loadingMask(false)
    }
  }

  private manualStartedPlay(): boolean {
    return this.event.eventNames().includes('hlsCanPlay')
  }

  private createHlsPlayer() {
    const videoElement = document.createElement('video')
    if (!videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      this.hlsPlayer = new HlsJS({
        debug: {
          trace: LoggerFactory.trace,
          debug: LoggerFactory.debug,
          log: LoggerFactory.log,
          warn: LoggerFactory.warn,
          info: LoggerFactory.info,
          error: LoggerFactory.error
        },
        initialLiveManifestSize: 1,
        liveSyncDurationCount: 3,
        // liveSyncDuration: 1,
        enableWorker: false,
        lowLatencyMode: true,
        progressive: true,
        capLevelOnFPSDrop: true,
        fpsDroppedMonitoringPeriod: 1000,
        fpsDroppedMonitoringThreshold: 0.2,
        startFragPrefetch: true
      })
      this.hlsPlayer.loadSource(this.playUrl)
      this.isNativePlayer = false
    } else {
      this.isNativePlayer = true
    }
  }

  private initPlayerAndListener() {
    if (this.player) {
      this.player.addEventListener('error', this.playerEventHandler.errorHandler)
      this.player.addEventListener('playing', this.playerEventHandler.playingHandler)
      this.player.addEventListener('loadedmetadata', this.playerEventHandler.loadedMetaHandler)
      this.player.addEventListener('loadeddata', this.playerEventHandler.loadedDataHandler)
      this.player.addEventListener('canplay', this.playerEventHandler.canPlayHandler)
    }
    if (!this.hlsPlayer && this.player) {
      this.player.src = this.playUrl
      this.player.load()
    } else if (this.hlsPlayer && this.player) {
      this.hlsPlayer.attachMedia(this.player)

      this.hlsPlayer.on(HlsJS.Events.MEDIA_ATTACHED, this.playerEventHandler.mediaAttachedHandler)
      this.hlsPlayer.on(HlsJS.Events.MANIFEST_PARSED, this.playerEventHandler.manifestParsedHandler)
      this.hlsPlayer.on(HlsJS.Events.LEVEL_SWITCHED, this.playerEventHandler.levelSwitchedHandler)
      this.hlsPlayer.on(HlsJS.Events.FPS_DROP, this.playerEventHandler.frameDropHandler)
      this.hlsPlayer.on(HlsJS.Events.FPS_DROP_LEVEL_CAPPING, this.playerEventHandler.frameDropLevelCappingHandler)
      this.hlsPlayer.on(HlsJS.Events.ERROR, this.playerEventHandler.mediaErrHandler)
    }
  }

  private offPlayerAndListener(): void {
    if (!this.hlsPlayer && this.player) {
      this.player.src = null
    } else if (this.hlsPlayer && this.player) {
      this.hlsPlayer.detachMedia()

      this.hlsPlayer.off(HlsJS.Events.MEDIA_ATTACHED, this.playerEventHandler.mediaAttachedHandler)
      this.hlsPlayer.off(HlsJS.Events.MANIFEST_PARSED, this.playerEventHandler.manifestParsedHandler)
      this.hlsPlayer.off(HlsJS.Events.LEVEL_SWITCHED, this.playerEventHandler.levelSwitchedHandler)
      this.hlsPlayer.off(HlsJS.Events.FPS_DROP, this.playerEventHandler.frameDropHandler)
      this.hlsPlayer.off(HlsJS.Events.FPS_DROP_LEVEL_CAPPING, this.playerEventHandler.frameDropLevelCappingHandler)
      this.hlsPlayer.off(HlsJS.Events.ERROR, this.playerEventHandler.mediaErrHandler)
    }

    if (this.player) {
      this.player.removeEventListener('error', this.playerEventHandler.errorHandler)
      this.player.removeEventListener('playing', this.playerEventHandler.playingHandler)
      this.player.removeEventListener('loadedmetadata', this.playerEventHandler.loadedMetaHandler)
      this.player.removeEventListener('loadeddata', this.playerEventHandler.loadedDataHandler)
      this.player.removeEventListener('canplay', this.playerEventHandler.canPlayHandler)
    }
    this.event.removeListener(HlsJS.ErrorTypes.MEDIA_ERROR, this.playerEventHandler.mediaRecoverHandler)
  }

  private afterPlayStrategy() {
    if (!this.pauseCount && commonUtil.getPlatform().indexOf("Ios 15") >= 0) {
      LoggerFactory.error(this.module_, commonUtil.getPlatform())
      const pauseTimer = setTimeout(() => {
        clearTimeout(pauseTimer)
        this.player?.pause()
        this.pauseCount++
      }, 100)
    }
  }
}