import { write } from "sdp-transform"
import { MediaType } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { MediaDirection } from "src/sdp/ISdp"
import { SDPEditor } from "src/sdp/SDPEditor"

export class PlanbSDPEditor extends SDPEditor {
  /*
  * 根据join room响应生成answerSdp
  * @param answerSdp
  */
  public transformAnswerSdp(answerSdp: string): string {
    LoggerFactory.debug(this.module_, `transformAnswerSdp, answerSdp: ${this.printSdpInfo(answerSdp)}`)
    const sdpDes = this.parseSdp(answerSdp)
    if (!sdpDes) {
      LoggerFactory.error(this.module_, `transformAnswerSdp failed,the sdp is invalid`)
      return undefined
    }

    const videoMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_VIDEO)
    const audioMedias = this.getMedias(sdpDes, MediaType.TRACK_TYPE_AUDIO)

    if (videoMedias && videoMedias.length > 0) {
      videoMedias.forEach(videoMedia => {
        videoMedia.direction = MediaDirection.SEND_ONLY
      })
    }

    if (audioMedias && audioMedias.length > 0) {
      audioMedias.forEach(audioMedia => {
        audioMedia.direction = MediaDirection.SEND_ONLY
      })
    }
    const resSdp = write(sdpDes)
    LoggerFactory.debug(this.module_, `transformAnswerSdp success,answerSdp: ${this.printSdpInfo(resSdp)}`)
    return resSdp
  }
}
