import CommonUtil from "src/utils/CommonUtil"
import { ExecutableFunction, FunctionUtil } from "src/utils/FunctionUtil"
import { EventEmitter } from "events"
import { HWLLSStat } from "src/stat/HWLLSStat"
import commonUtil from "src/utils/CommonUtil"

export default class EmitterAble {
  readonly identifiedId_: string

  readonly eventEmitter_: EventEmitter

  readonly stats_: HWLLSStat

  readonly moudle_: string

  callbackMap_: Map<string, ExecutableFunction> = new Map()

  onCallbackMap_: Map<string, ExecutableFunction> = new Map()

  protected constructor(moudle: string, stat?: HWLLSStat, eventEmitter?: EventEmitter) {
    this.identifiedId_ = commonUtil.generateStandardUuid()
    this.eventEmitter_ = eventEmitter || new EventEmitter()
    this.stats_ = stat || new HWLLSStat(this.identifiedId_)
    this.moudle_ = moudle || ''
  }

  public on(event: any, func: any, withTimeout?: boolean) {
    withTimeout ? this.onWithTimeout(event, func) : this.eventEmitter_.on(event, func)
  }

  public once(event: any, func: any) {
    this.eventEmitter_.once(event, func)
  }

  public off(event: any, func?: any, withTimeout?: boolean) {
    if (!func) {
      this.eventEmitter_.removeAllListeners(event)
    } else {
      withTimeout ? this.offWithTimeout(event, func) : this.eventEmitter_.removeListener(event, func)
    }
  }

  public offAllEvents() {
    this.eventEmitter_.removeAllListeners()
    this.callbackMap_.clear()
    this.onCallbackMap_.clear()
  }

  emit(event: any, data?: any) {
    this.eventEmitter_.emit(event, data)
  }

  onWithTimeout(event: any, func: any) {
    const getOnCallback = this.getOnCallback(event, func)
    this.eventEmitter_.on(event, getOnCallback)
  }

  offWithTimeout(event: any, func: any) {
    const getOffCallback = this.getOffCallback(event, func)
    if (getOffCallback) {
      this.eventEmitter_?.removeListener(event, getOffCallback)
    }
  }

  getOnCallback(event: any, func: any): ExecutableFunction {
    const key = `${event}_${func?.name}`
    const onCallback: ExecutableFunction = async (data: any) => {
      if (this.callbackMap_.get(key)) {
        const start = CommonUtil.getCurrentLocalTimestamp()
        let end: number
        try {
          await FunctionUtil.callWithTimeout(this.callbackMap_.get(key)(data), 10000)
          end = CommonUtil.getCurrentLocalTimestamp()
        } catch (error) {
          end = start + 5000
        } finally {
          this.stats_?.reportCallbackInfo(event.toString(), this.moudle_, start, end, data, (data) => {
            if (['object', 'function'].includes(typeof data) && data.getInfo) {
              return JSON.stringify(data.getInfo())
            }
            return JSON.stringify(data || {})
          })
        }
      }
    }
    this.callbackMap_.set(key, func)
    this.onCallbackMap_.set(key, onCallback)
    return onCallback
  }

  getOffCallback(event: any, func: any) {
    const key = `${event}_${func?.name}`
    const onCallback: ExecutableFunction = this.onCallbackMap_.get(key)
    if (this.onCallbackMap_.get(key)) {
      this.onCallbackMap_.delete(key)
    }
    if (this.callbackMap_.get(key)) {
      this.callbackMap_.delete(key)
    }
    return onCallback
  }

  get eventEmitter(): EventEmitter {
    return this.eventEmitter_
  }

  get identifiedId(): string {
    return this.identifiedId_
  }

  get stats(): HWLLSStat {
    return this.stats_
  }
}
