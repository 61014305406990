import { PlayTrackKpi, MediaType, PullMediaParams, SfuInfo, StreamParams } from "src/common/ObjDefinition"
import { LoggerFactory } from "src/logger/LoggerFactory"
import CommonUtil from "src/utils/CommonUtil"

export class GlobalVariables {
  private sfuInfo: SfuInfo
  private streamParams: StreamParams
  private pullMediaParams: PullMediaParams
  private audioStatisticCache: any[]
  private videoStatisticCache: any[]
  private playTrackKpi: PlayTrackKpi

  constructor() {
    this.audioStatisticCache = []
    this.videoStatisticCache = []
    this.playTrackKpi = {
      startPlayTime: 0,
      iceCandidateStart: 0,
      iceCandidateComplete: 0,
      icePairStart: 0,
      iceChecking: 0,
      iceComplete: 0,
      dtlsComplete: 0,
      glsbRequest: 0,
      glsbRespone: 0,
      negotiateSDPRequest: 0,
      negotiateSDPRespone: 0,
      audioPlaySucc: 0,
      videoPlaySucc: 0,
      endPlayTime: 0,
      videoTimeOut: 0,
      audioTimeOut: 0
    } as PlayTrackKpi

    this.pullMediaParams = {} as PullMediaParams
  }

  public updatePlayTrackKpi(key: string): void {
    this.playTrackKpi[key] = CommonUtil.getCurrentLocalTimestamp()
    if (/^(ice|dtls).*/ig.test(key)) {
      this.addKeyInfoMsg(key, this.playTrackKpi[key])
    }
    if (['endPlayTime', 'videoTimeOut', 'audioTimeOut'].includes(key)) {
      return
    }
    let isKpiDataReady = true
    for (const property in this.playTrackKpi) {
      if (!['videoTimeOut', 'audioTimeOut', 'endPlayTime', 'glsbRespone', 'glsbRequest'].includes(property) && !this.playTrackKpi[property]) {
        if (['videoPlaySucc', 'audioPlaySucc'].includes(property)) {
          if (!this.sfuInfo.aSsrc && !this.sfuInfo.vSsrc) {
            isKpiDataReady = false
            break
          }
          if (property === 'audioPlaySucc' && this.sfuInfo.aSsrc && !this.playTrackKpi[property]) {
            isKpiDataReady = false
            break
          }
          if (property === 'videoPlaySucc' && this.sfuInfo.vSsrc && !this.playTrackKpi[property]) {
            isKpiDataReady = false
            break
          }
        } else {
          if (!this.playTrackKpi[property]) {
            isKpiDataReady = false
            break
          }
        }
      }
    }
    if (isKpiDataReady) {
      LoggerFactory.info('KPI', `startPlay time spent analysis( gslbDispatch cost: ${this.playTrackKpi['glsbRespone'] - this.playTrackKpi['glsbRequest']}ms; negotiateSDP cost: ${this.playTrackKpi['negotiateSDPRespone'] - this.playTrackKpi['negotiateSDPRequest']}ms; localICE candidate collect cost: ${this.playTrackKpi['iceCandidateComplete'] - this.playTrackKpi['iceCandidateStart']}ms; Connection establish total cost: ${this.playTrackKpi['dtlsComplete'] - this.playTrackKpi['icePairStart']}ms; ICE Connectivity check: ${this.playTrackKpi['iceComplete'] - this.playTrackKpi['iceChecking']}ms; DTLS negotiate cost: ${this.playTrackKpi['dtlsComplete'] - this.playTrackKpi['iceComplete']}ms; first frame cost: ${Math.max(this.playTrackKpi['videoPlaySucc'], this.playTrackKpi['audioPlaySucc']) - this.playTrackKpi['startPlayTime']}ms`)
    }
  }

  public getPlayTrackKpi(): PlayTrackKpi {
    return this.playTrackKpi
  }

  public getSfuInfo(): SfuInfo {
    return this.sfuInfo
  }

  public setSfuInfo(sfuInfo: SfuInfo): void {
    if (sfuInfo.ipAddress) {
      sfuInfo.ipAddress = CommonUtil.isIpv6(sfuInfo.ipAddress) ? `[${sfuInfo.ipAddress}]` : sfuInfo.ipAddress
    }
    this.sfuInfo = sfuInfo
  }

  public setStreamParams(streamParams: StreamParams): void {
    if (!this.streamParams) {
      this.streamParams = streamParams
    } else {
      this.streamParams = { ...this.streamParams, ...streamParams }
    }
  }

  public getStreamParams(): StreamParams {
    return this.streamParams
  }

  public updateAccessAddr(ip: string): void {
    if (!this.streamParams) {
      this.streamParams = {
        accessAddr: CommonUtil.isIpv6(ip) ? `[${ip}]` : ip
      } as StreamParams
    } else {
      this.streamParams.accessAddr = CommonUtil.isIpv6(ip) ? `[${ip}]` : ip
    }
  }

  public setPullMediaParams(pullMediaParam: PullMediaParams): void {
    if (this.pullMediaParams.clientAddr) {
      delete pullMediaParam.clientAddr
    } else {
      pullMediaParam.clientAddr = CommonUtil.isIpv6(pullMediaParam.clientAddr) ? `[${pullMediaParam.clientAddr}]` : pullMediaParam.clientAddr
    }
    this.pullMediaParams = { ...this.pullMediaParams, ...pullMediaParam }
  }

  public updateClientAddr(ip: string): void {
    if (!ip) {
      return
    }
    if (!this.pullMediaParams.clientAddr) {
      this.pullMediaParams.clientAddr = CommonUtil.isIpv6(ip) ? `[${ip}]` : ip
    }
  }

  public getPullMediaParams(): PullMediaParams {
    return this.pullMediaParams
  }

  public addVideoStatisticCache(cacheInfo: any): void {
    this.videoStatisticCache.push(cacheInfo)
  }

  public addAudioStatisticCache(cacheInfo: any): void {
    this.audioStatisticCache.push(cacheInfo)
  }

  public calcPlayDuration(): number {
    return Math.round((this.playTrackKpi.endPlayTime - this.playTrackKpi.startPlayTime) / 1000)
  }

  public getMediaFreezeStatistic(mediaType: MediaType): any {
    const caches = this[mediaType === MediaType.TRACK_TYPE_AUDIO ? 'audioStatisticCache' : 'videoStatisticCache']
    let freeze200Duration = 0
    let freeze200Count = 0
    let freeze600Duration = 0
    let freeze600Count = 0
    for (const cache of caches) {
      freeze200Count += cache.total200FreezeCnt
      freeze200Duration += cache.total200FreezeTime

      if (mediaType === MediaType.TRACK_TYPE_VIDEO) {
        freeze600Count += cache.total600VFreezeCnt
        freeze600Duration += cache.total600VFreezeTime
      }
    }
    if (mediaType === MediaType.TRACK_TYPE_VIDEO) {
      return {
        freeze200Count, freeze200Duration, freeze600Count, freeze600Duration
      }
    }
    return {
      freeze200Count, freeze200Duration
    }
  }

  public calcAverageStatistic(): any {
    const averageStatistic = { afreeze200Cnt: 0, vfreeze200Cnt: 0, vfreeze600Cnt: 0 }
    let totalLostPkts = 0
    let totalPkts = this.audioStatisticCache.length > 0 ? this.audioStatisticCache[this.audioStatisticCache.length - 1]['totalPacketsReceived'] : 0
    let totalBitrate = 0
    let totalJitter = 0
    let freeze200Duration = 0
    let freeze600Duration = 0
    let totalStatisticTime = 0
    let rtt = 0
    for (const cache of this.audioStatisticCache) {
      averageStatistic.afreeze200Cnt += cache.total200FreezeCnt
      freeze200Duration += cache.total200FreezeTime
      totalStatisticTime += cache.totalStatisticTime
      totalLostPkts += cache.lostPktCnt
      totalBitrate += cache.bitRate
      totalJitter += cache.jitter
      rtt += cache.rtt
    }
    averageStatistic['afreeze200Rate'] = Math.round(freeze200Duration * 100 / Math.max(totalStatisticTime, 1))
    averageStatistic['aRecvBandwidthMean'] = Math.round(totalBitrate / Math.max(this.audioStatisticCache.length, 1))
    averageStatistic['aRecvLostMean'] = Math.round(totalLostPkts * 100 / Math.max(totalPkts + totalLostPkts, 1))
    averageStatistic['aRecvJitter'] = Math.round(totalJitter / Math.max(this.audioStatisticCache.length, 1))
    averageStatistic['aRecvRttMean'] = Math.round(rtt / Math.max(this.audioStatisticCache.length, 1))

    totalLostPkts = 0
    totalPkts = this.videoStatisticCache.length > 0 ? this.videoStatisticCache[this.videoStatisticCache.length - 1]['totalPacketsReceived'] : 0
    totalBitrate = 0
    totalJitter = 0
    freeze200Duration = 0
    totalStatisticTime = 0
    rtt = 0
    for (const cache of this.videoStatisticCache) {
      averageStatistic.vfreeze200Cnt += cache.total200FreezeCnt
      averageStatistic.vfreeze600Cnt += cache.total600VFreezeCnt
      freeze200Duration += cache.total200FreezeTime
      freeze600Duration += cache.total600VFreezeTime
      totalStatisticTime += cache.totalStatisticTime
      totalLostPkts += cache.lostPktCnt
      totalBitrate += cache.bitRate
      totalJitter += cache.jitter
      rtt += cache.rtt
    }
    averageStatistic['vfreeze200Rate'] = Math.round(freeze200Duration * 100 / Math.max(totalStatisticTime, 1))
    averageStatistic['vfreeze600Rate'] = Math.round(freeze600Duration * 100 / Math.max(totalStatisticTime, 1))
    averageStatistic['vRecvBandwidthMean'] = Math.round(totalBitrate / Math.max(this.videoStatisticCache.length, 1))
    averageStatistic['vRecvLostMean'] = Math.round(totalLostPkts * 100 / Math.max(totalPkts + totalLostPkts, 1))
    averageStatistic['vRecvJitter'] = Math.round(totalJitter / Math.max(this.videoStatisticCache.length, 1))
    averageStatistic['vRecvRttMean'] = Math.round(rtt / Math.max(this.videoStatisticCache.length, 1))
    return averageStatistic;
  }

  private addKeyInfoMsg(key: string, value: number): void {
    if (!this.pullMediaParams.keyInfoMsg) {
      this.pullMediaParams.keyInfoMsg = {}
    }

    if (key === 'iceCandidateComplete' && this.pullMediaParams.keyInfoMsg['iceCandidateStart']) {
      this.pullMediaParams.keyInfoMsg[key] = value - this.pullMediaParams.keyInfoMsg['iceCandidateStart']
    } else if (key === 'iceChecking' && this.pullMediaParams.keyInfoMsg['icePairStart']) {
      this.pullMediaParams.keyInfoMsg[key] = value - this.pullMediaParams.keyInfoMsg['icePairStart']
    } else if (key === 'iceComplete' && this.pullMediaParams.keyInfoMsg['icePairStart']) {
      this.pullMediaParams.keyInfoMsg[key] = value - this.pullMediaParams.keyInfoMsg['icePairStart'] - (this.pullMediaParams.keyInfoMsg['iceChecking'] || 0)
    } else if (key === 'dtlsComplete' && this.pullMediaParams.keyInfoMsg['icePairStart']) {
      this.pullMediaParams.keyInfoMsg[key] = value - this.pullMediaParams.keyInfoMsg['icePairStart'] - ((this.pullMediaParams.keyInfoMsg['iceChecking'] || 0) + (this.pullMediaParams.keyInfoMsg['iceComplete'] || 0))
    } else {
      this.pullMediaParams.keyInfoMsg[key] = value
    }
  }
}

export class GlobalVariableManager {
  private static globalVariableMap = new Map<string, GlobalVariables>()
  static addInstance(instanceId: string): void {
    GlobalVariableManager.globalVariableMap.set(instanceId, new GlobalVariables())
  }

  static getInstance(instanceId?: string): GlobalVariables {
    return GlobalVariableManager.globalVariableMap.get(instanceId)
  }

  static delInstance(instanceId?: string): void {
    if (instanceId) {
      GlobalVariableManager.globalVariableMap.delete(instanceId)
    } else {
      GlobalVariableManager.globalVariableMap.clear()
    }
  }
}