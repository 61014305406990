import CommonUtil from "src/utils/CommonUtil"
import { ExecutableFunction } from "src/utils/FunctionUtil"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { parameterValidKeys } from "src/utils/ParametersUtil"


function doFinalParam(argArr: any[]): any[] {
  try {
    return argArr.map(arg => {
      if (!['object', 'function'].includes(typeof arg)) {
        return arg
      }
      return arg.getInfo ? arg.getInfo() : arg
    })
  } catch (e) {
    return argArr
  }
}

function specialAPIFilter(funcName: string, specialFuncName: string, params: any[], matchInfo: string): boolean {
  if (funcName === specialFuncName) {
    if (params && params.length > 1) {
      return params[0] === matchInfo
    }
  }
  return true
}


export function asyncApi(shortApiName: string) {
  return (target, name, descriptor): any => {
    const func: ExecutableFunction = descriptor.value
    const moduleName: string = shortApiName.substr(0, shortApiName.indexOf("$"))
    return {
      ...descriptor, async value(...params) {
        let ret: any
        let err: any
        const start = CommonUtil.getCurrentLocalTimestamp()
        const argArr = params || []
        const arrParam = doFinalParam(argArr)
        try {
          LoggerFactory.info(moduleName, `${func.name} begin : parameters are ${JSON.stringify(arrParam)}`)
          ret = await func.apply(this, argArr)
          const arrResult = ret !== null && ret !== undefined ? doFinalParam([ret]) : []
          LoggerFactory.info(moduleName, `${func.name} end success : result is ${JSON.stringify(arrResult)}`)
          return ret
        } catch (error) {
          LoggerFactory.error(moduleName, `${func.name} end error : ${error}`)
          if (func.name === 'startPlay') {
            LoggerFactory.uploadLog()
          }
          err = error
          throw error
        } finally {
          const end = CommonUtil.getCurrentLocalTimestamp()
          const argsArr = [ret, ...argArr]
          const arrResult = doFinalParam(argsArr)
          this.stats_?.reportApiCallInfo(shortApiName, start, end, err, ...arrResult)
        }
      }
    }
  }
}

export function api(shortApiName: string) {
  return (target, name, descriptor): any => {
    const func: ExecutableFunction = descriptor.value
    const moduleName: string = shortApiName.substr(0, shortApiName.indexOf("$"))
    return {
      ...descriptor, value(...params) {
        let err: any
        let ret: any
        const start = CommonUtil.getCurrentLocalTimestamp()
        const argArr = params || []
        const arrParam = doFinalParam(argArr)
        try {
          LoggerFactory.info(moduleName, `${func.name} begin : parameters are ${JSON.stringify(arrParam)}`)
          ret = func.apply(this, argArr)
          const arrResult = ret !== null && ret !== undefined ? doFinalParam([ret]) : []
          LoggerFactory.info(moduleName, `${func.name} end success : result is ${JSON.stringify(arrResult)}`)
          return ret
        } catch (error) {
          LoggerFactory.error(moduleName, `${func.name} end error : ${error}`)
          err = error
          throw error
        } finally {
          const end = CommonUtil.getCurrentLocalTimestamp()
          const argsArr = [ret, ...argArr]
          const arrResult = doFinalParam(argsArr)
          specialAPIFilter(func.name, 'setParameter', argArr, parameterValidKeys.LOADING_CONFIG) && this.stats_?.reportApiCallInfo(shortApiName, start, end, err, ...arrResult)
        }
      }
    }
  }
}

export function callWithTimeout(timeout: number) {
  return (target, name, descriptor): any => {
    const func: ExecutableFunction = descriptor.value
    return {
      ...descriptor, value(...params) {
        const argArr = params || []
        let reject_
        const timeoutError = new HwLLSError(ErrorCode.HWLLS_ERROR_SERVER_NO_RESPONSE)
        const timeoutPromise_ = new Promise((resolve, x) => {
          reject_ = x
        })
        let timeoutTimer = null
        if (timeout) {
          timeoutTimer = setTimeout(() => {
            timeoutTimer && clearTimeout(timeoutTimer)
            reject_(timeoutError)
          }, timeout)
        }
        return Promise.race([func.apply(this, argArr).finally(() => {
          timeoutTimer && clearTimeout(timeoutTimer)
        }), timeoutPromise_])
      }
    }
  }
}
