import { DomainPolicy, MediaFormat, StartPlayOptions } from "src/common/ObjDefinition"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"

export class ValidatorUtil {
  public static checkStringParameter(parameter: any, maxLen = Infinity, minLen = 0, validateReg?: string): boolean {
    if (!parameter && minLen <= 0) {
      return true
    }
    if (typeof parameter !== 'string') {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `invalid type: ${parameter}`)
    }
    const parameterLength = this.getStringBytes(parameter)
    if (parameterLength > maxLen || parameterLength < minLen) {
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `invalid length: ${parameter}`)
    }
    if (validateReg) {
      const reg = new RegExp(validateReg)
      const result = reg.test(parameter)
      if (!result) {
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, `invalid format: ${parameter}`)
      }
    }
    return true
  }

  public static checkUrlFormat(url: string, format?: MediaFormat): boolean {
    format = format || MediaFormat.WEBRTC
    if (format === MediaFormat.WEBRTC) {
      return /webrtc:\/\/[^\/]*\/[^\/]*\/[^\/]+/ig.test(url)
    }
    if (format === MediaFormat.FLV) {
      return /(https?|wss?):\/\/[^\/]*\/[^\/]*\/[^\/]+/ig.test(url)
    }

    if (format === MediaFormat.HLS) {
      return /https?:\/\/[^\/]*\/[^\/]*\/[^\/]+/ig.test(url)
    }

    return false
  }

  public static checkPlayOpt(playOpt: StartPlayOptions): boolean {
    if (!playOpt.elementId) {
      return false
    }
    if (Object.prototype.hasOwnProperty.call(playOpt, 'objectFit') && !['cover', 'contain', 'fill'].includes(playOpt.objectFit)) {
      return false
    }
    if (playOpt.webrtcConfig && playOpt.webrtcConfig.receiveAudio === false && playOpt.webrtcConfig.receiveVideo === false) {
      return false
    }
    if (Object.prototype.hasOwnProperty.call(playOpt, 'domainPolicy') && ![DomainPolicy.SHARE_DOMAIN, DomainPolicy.USER_DOMIAN].includes(playOpt.domainPolicy)) {
      return false
    }
    return true
  }

  private static getStringBytes(str: string): number {
    if (!str) {
      return 0
    }
    let strByteLength = 0
    if (window.TextEncoder) {
      const textEncoder = new TextEncoder()
      strByteLength = textEncoder.encode(str).length
      return strByteLength
    }
    const strLength = str.length
    for (let idx = 0; idx < strLength; idx++) {
      if (str.charCodeAt(idx) > 0xFF) {
        strByteLength += 2
      } else {
        strByteLength++
      }
    }
    return strByteLength
  }
}
