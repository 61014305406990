import { EventEmitter } from 'events'
import { HWLLSEvents, RTCStreamEvent } from "src/common/ObjDefinition"
import { ErrorCode, HwLLSError } from "src/ecode/ECode"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { SysEventsBus } from "src/system/InternalSysEventsBus"
import commonUtil from "src/utils/CommonUtil"
import fullScreen, { PlayerAdapter } from './PlayerUtils'

export class SoundMeter {
  context_: any
  script_: any
  instant_: any
  slow_: any
  clip_: any
  mic_: any

  constructor() {
    this.context_ = new AudioContext()
    this.script_ = this.context_.createScriptProcessor(2048, 1, 1)
    this.script_.onaudioprocess = (event: any) => {
      const input = event.inputBuffer.getChannelData(0)
      let sum = 0
      let clipCnt = 0
      for (let n = 0; n < input.length; n++) {
        sum += input[n] * input[n]
        if (Math.abs(input[n]) > 0.99) {
          clipCnt += 1
        }
      }
      this.instant_ = Math.sqrt(sum / input.length)
      this.slow_ = 0.95 * this.slow_ + 0.05 * this.instant_
      this.clip_ = clipCnt / input.length
    }
    this.instant_ = 0
    this.slow_ = 0
    this.clip_ = 0
  }

  public connectToSource(track: MediaStreamTrack): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const stream = new MediaStream()
        stream.addTrack(track)
        this.mic_ = this.context_.createMediaStreamSource(stream)
        this.mic_.connect(this.script_)
        this.script_.connect(this.context_.destination)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  public stop() {
    this.mic_.disconnect()
    this.script_.disconnect()
    this.context_.close()
  }

  public getVolume(): number {
    return parseFloat(this.instant_.toFixed(2))
  }
}

export class RTCPlayer {
  protected event_: EventEmitter
  protected mediaStream_: MediaStream
  protected track_: MediaStreamTrack
  protected playerDiv_: any  // 播放器的div容器，音视频公用
  protected playerElement_: any  // 浏览器video或者audio标签
  protected playerId_: string // 播放element的唯一Id
  protected playTimeOutTimer: NodeJS.Timeout
  protected module_: string
  protected pauseCount: number
  // 移动端设备前后台切换事件监听，注册唯一Id
  protected sysEventRegisterUid: string
  protected trackEnable: boolean
  protected deBounceHandlers: any
  protected customEvent: any
  protected listenHandlers: {
    canPlayHandler: any;
    playingHandler: any;
    playerEndedHandler: any;
    playerPausedHandler: any;
    trackEndedHandler: any;
    trackMutedHandler: any;
    trackUnmutedHandler: any;
    playHandler: any;
    backgroundHandler: any;
    foregroundHandler: any;
    appPauseHandler: any;
    playInterruptHandler: any;
  }
  protected videoBufferedReday: boolean
  private state_: string // 播放状态
  private manualPauseFlag_: boolean // 手动触发暂停标志位，区分插拔耳机等行为引起的pause


  constructor(option: { track: MediaStreamTrack; playerDiv: any; playerId: string }) {
    this.module_ = 'RTCPlayer'
    this.mediaStream_ = new MediaStream()
    this.track_ = option.track
    this.playerDiv_ = option.playerDiv
    this.playerId_ = option.playerId
    this.playerElement_ = null
    this.state_ = 'NONE'
    this.manualPauseFlag_ = false
    this.event_ = new EventEmitter()
    this.pauseCount = 0
    this.customEvent = document.createEvent('CustomEvent')
    this.customEvent.initEvent('appPause')
    this.listenHandlers = {
      canPlayHandler: this.canPlayHandler.bind(this),
      playingHandler: this.playingHandler.bind(this),
      playerEndedHandler: this.playerEndedHandler.bind(this),
      playerPausedHandler: this.playerPausedHandler.bind(this),
      trackEndedHandler: this.trackEndedHandler.bind(this),
      trackMutedHandler: this.trackMutedHandler.bind(this),
      trackUnmutedHandler: this.trackUnmutedHandler.bind(this),
      playHandler: null,
      backgroundHandler: this.backgroundHandler.bind(this),
      foregroundHandler: this.foregroundHandler.bind(this),
      appPauseHandler: this.appPauseHandler.bind(this),
      playInterruptHandler: null
    }
    this.deBounceHandlers = {
      videoHandler: commonUtil.debounce(() => {
        LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, player is paused')
        this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'playerPause', type: `${this.module_}`, streamId: this.playerId_ })
        this.state_ = 'PAUSED'

        LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'try to play again')
        this.event_.emit('videoCanPlay')
      }, 800, false),
      audioHandler: commonUtil.debounce(() => {
        LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, player is paused')
        this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'playerPause', type: `${this.module_}`, streamId: this.playerId_ })
        this.state_ = 'PAUSED'
        LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'try to play again')
        this.event_.emit('audioCanPlay')
      }, 800, false)
    }
  }

  public on(event: any, func: any) {
    this.event_.on(event, func)
  }

  public off(event: any, func: any) {
    this.event_.removeListener(event, func)
  }

  public stop(): void {
    this.closeAllEvents()
    clearTimeout(this.playTimeOutTimer)
    if (this.playerElement_) {
      this.playerElement_.srcObject = null
    }
    this.playerElement_ = null
    this.mediaStream_ = new MediaStream()
    this.manualPauseFlag_ = false
    this.event_.emit('playInterrupt')
    LoggerFactory.info(this.module_, 'stop success')
  }

  public replaceTrack(track: MediaStreamTrack): void {
    LoggerFactory.info(this.module_, 'replaceTrack start')

    const sourceTracks = this.module_.indexOf('Audio') > 0 ? this.mediaStream_.getAudioTracks() : this.mediaStream_.getVideoTracks()
    const sourceTrack = sourceTracks.length ? sourceTracks[0] : null
    if (sourceTrack === track) {
      return
    }
    sourceTrack && this.mediaStream_.removeTrack(sourceTrack)
    this.mediaStream_.addTrack(track)
    this.track_ = track
    this.manualPauseFlag_ = false
  }

  protected async resume(playParameters: any): Promise<Error> {
    this.manualPauseFlag_ = false
    return new Promise((resolve, reject) => {
      if (this.playerElement_) {
        this.playerElement_.play().then(() => {
          LoggerFactory.info(this.module_, `audio player resume success, streamId:${this.playerId_}, playParameters: ${JSON.stringify(playParameters)}`)
          resolve(null)
        }).catch((error) => {
          LoggerFactory.error(this.module_, `player resume failed, errmsg=${error} , playParameters: ${JSON.stringify(playParameters)}`)
          if (error.name === 'NotAllowedError') {
            reject(new HwLLSError(ErrorCode.HWLLS_PLAY_NOT_ALLOW))
          } else {
            reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error.message))
          }
        })
      } else {
        reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, 'resume failed, element is null'))
      }
    })
  }

  protected pause(): void {
    if (this.playerElement_) {
      this.playerElement_.pause()
      if (this.state_ !== 'STOPPED') {
        this.manualPauseFlag_ = true
      }
    }
  }

  protected async doPlay(resolve: any, reject: any, playParam: any): Promise<any> {
    this.manualPauseFlag_ = false
    await PlayerAdapter.multiPlatformAdapter()
    let extendedInfo: string
    if (this.module_.indexOf('Video') > 0) {
      extendedInfo = `, screenFit:${playParam.objectFit}`
    } else {
      extendedInfo = `, volume:${playParam.volume}, muted:${playParam.muted}`
    }

    return this.playerElement_.play().then(() => {
      LoggerFactory.info(this.module_, `play success, parentDiv id:${this.playerDiv_?.id}, resolutionId or streamId:${this.playerId_}, physical track id:${this.track_?.id}${extendedInfo}`)
      this.afterPlayStrategy()
      resolve(null)
    }).catch((error: Error) => {
      LoggerFactory.error(this.module_, `play failed, errmsg=${error},parentDiv id:${this.playerDiv_?.id}, resolutionId or streamId:${this.playerId_}, physical track id:${this.track_?.id}${extendedInfo}`)
      if (error.name === 'NotAllowedError') {
        reject(new HwLLSError(ErrorCode.HWLLS_PLAY_NOT_ALLOW))
      } else {
        reject(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, error.message))
      }
    }).finally(() => {
      clearTimeout(this.playTimeOutTimer)
      this.listenHandlers.playInterruptHandler && this.event_.removeListener('playInterrupt', this.listenHandlers.playInterruptHandler)
    })
  }

  protected closeAllEvents(): void {
    this.playerElement_?.removeEventListener('canplay', this.listenHandlers.canPlayHandler)
    this.playerElement_?.removeEventListener('playing', this.listenHandlers.playingHandler)
    this.playerElement_?.removeEventListener('ended', this.listenHandlers.playerEndedHandler)
    this.playerElement_?.removeEventListener('pause', this.listenHandlers.playerPausedHandler)
    this.playerElement_?.removeEventListener('appPause', this.listenHandlers.appPauseHandler)
    this.deBounceHandlers.videoHandler.cancel()
    this.deBounceHandlers.audioHandler.cancel()
    this.track_?.removeEventListener('ended', this.listenHandlers.trackEndedHandler)
    this.track_?.removeEventListener('mute', this.listenHandlers.trackMutedHandler)
    this.track_?.removeEventListener('unmute', this.listenHandlers.trackUnmutedHandler)
    this.listenHandlers.playHandler && this.event_?.removeListener('videoCanPlay', this.listenHandlers.playHandler)
    this.listenHandlers.playHandler && this.event_?.removeListener('audioCanPlay', this.listenHandlers.playHandler)
    this.event_.removeListener('FOREGROUND', this.listenHandlers.foregroundHandler)
    this.event_.removeListener('BACKGROUND', this.listenHandlers.backgroundHandler)
    SysEventsBus.unregister(this.sysEventRegisterUid)
  }

  // 监听播放的事件
  protected handleEvents(): void {
    this.playerElement_.addEventListener('canplay', this.listenHandlers.canPlayHandler)
    this.playerElement_.addEventListener('playing', this.listenHandlers.playingHandler)
    this.playerElement_.addEventListener('ended', this.listenHandlers.playerEndedHandler)
    this.playerElement_.addEventListener('pause', this.listenHandlers.playerPausedHandler)
    this.playerElement_.addEventListener('appPause', this.listenHandlers.appPauseHandler)
    this.track_.addEventListener('ended', this.listenHandlers.trackEndedHandler)
    this.track_.addEventListener('mute', this.listenHandlers.trackMutedHandler)
    this.track_.addEventListener('unmute', this.listenHandlers.trackUnmutedHandler)
    this.event_.on('FOREGROUND', this.listenHandlers.foregroundHandler)
    this.event_.on('BACKGROUND', this.listenHandlers.backgroundHandler)
    this.sysEventRegisterUid = SysEventsBus.register(this.event_)
  }

  protected playInterruptHandler(rejectFunc: any): void {
    rejectFunc(new HwLLSError(ErrorCode.HWLLS_INTERNAL_ERROR, "AbortError: The play() request was interrupted by a new load request."))
  }

  private afterPlayStrategy() {
    if (!this.pauseCount && commonUtil.getPlatform().indexOf("Ios 15") >= 0) {
      LoggerFactory.error(this.module_, commonUtil.getPlatform())
      const pauseTimer = setTimeout(() => {
        clearTimeout(pauseTimer)
        this.playerElement_.pause()
        this.pauseCount++
      }, 100)
    }
  }

  private appPauseHandler(): void {
    this.manualPauseFlag_ = true
  }

  /**
   * handler of application became foreground
   */
  private foregroundHandler(): void {
    LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, application is foreground')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'foreground', type: this.module_ })
    if (this.module_.indexOf('Audio') > 0 && this.track_) {
      if (this.trackEnable !== this.track_.enabled)
        this.track_.enabled = this.trackEnable
    }
    if (this.state_ === 'PAUSED') {
      if (this.module_.indexOf('Video') > 0) {
        this.event_.emit('videoCanPlay')
      } else {
        this.event_.emit('audioCanPlay')
      }
    }
  }

  /**
   * handler of application became background
   */
  private backgroundHandler(): void {
    LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, application is background')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'background', type: this.module_ })
    // mute audio when set application background on Mobile
    if (this.module_.indexOf('Audio') > 0 && this.track_) {
      this.trackEnable = this.track_.enabled
      if (this.trackEnable) {
        this.track_.enabled = false
      }
    }
  }

  private canPlayHandler(): void {
    LoggerFactory.info(`${this.module_}#${this.playerId_}`, '[KPI] handleEvents, player is canplay status')
    this.event_.emit(this.module_.indexOf('Video') > 0 ? 'videoCanPlay' : 'audioCanPlay')
    if (this.module_.indexOf('Video')) {
      this.videoBufferedReday = true
    }
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'playerCanplay', type: `${this.module_}`, streamId: this.playerId_ })
  }

  private playingHandler(event): void {
    LoggerFactory.info(`${this.module_}#${this.playerId_}`, '[KPI] handleEvents, player is starting playing')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'playerPlaying', type: `${this.module_}`, streamId: this.playerId_ })
    this.state_ = 'PLAYING'
    this.manualPauseFlag_ = false
    // system playing control, so resume aduio at the same time
    if (fullScreen.getFullScreenStatus() && (!event.target.offsetParent || /^(<body>|<div\sid="player_container").*/.test(event.target.offsetParent.outerHTML)) && this.module_.indexOf('Video') > 0) {
      const audioElement = this.playerDiv_?.querySelector(`#audio_${this.playerId_}`)
      audioElement?.play()
      this.event_.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
        isFullScreen: fullScreen.getFullScreenStatus(),
        isPause: false
      })
      return
    }
  }

  private playerEndedHandler(): void {
    LoggerFactory.info(`${this.module_}#${this.playerId_}`, 'handleEvents, player is ended')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'playerEnded', type: `${this.module_}`, streamId: this.playerId_ })
    if (this.state_ !== 'STOPPED') {
      this.state_ = 'STOPPED'
    }
  }

  private playerPausedHandler(event): void {
    if (this.manualPauseFlag_) {
      return
    }

    // system pause control, so pause aduio at the same time
    if (fullScreen.getFullScreenStatus() && (!event.target.offsetParent || /^(<body>|<div\sid="player_container").*/.test(event.target.offsetParent.outerHTML)) && this.playerElement_.paused && this.module_.indexOf('Video') > 0) {
      const audioElement = this.playerDiv_?.querySelector(`#audio_${this.playerId_}`)
      if (audioElement) {
        audioElement.dispatchEvent(this.customEvent)
        audioElement.pause()
        this.event_.emit(HWLLSEvents.FULLSCREEN_STATUS_CHANGED, {
          isFullScreen: fullScreen.getFullScreenStatus(),
          isPause: true
        })
      }
      return
    }

    // adapts to system default pause action, for example: ios&mac device when earphone removed or cellphone calling
    if (this.module_.indexOf('Video') > 0) {
      this.deBounceHandlers.videoHandler.exec()
    } else {
      this.deBounceHandlers.audioHandler.exec()
    }
  }

  private trackEndedHandler(): void {
    LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, player track is ended')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'trackEnded', type: `${this.module_}`, streamId: this.playerId_ })
    if (this.state_ !== 'STOPPED') {
      this.state_ = 'STOPPED'
    }
  }

  private trackMutedHandler(): void {
    LoggerFactory.debug(`${this.module_}#${this.playerId_}`, 'handleEvents, track is muted')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'trackMute', type: `${this.module_}`, streamId: this.playerId_ })
    if (this.state_ !== 'PAUSED') {
      this.state_ = 'PAUSED'
    }
  }

  private trackUnmutedHandler(): void {
    LoggerFactory.debug(`${this.module_}#[${this.playerId_}]`, 'handleEvents, track is unmuted')
    this.event_.emit(RTCStreamEvent.PLAYER_STATE_TRACE, { event: 'trackUnmute', type: `${this.module_}`, streamId: this.playerId_ })
    if (this.state_ === 'PAUSED') {
      this.state_ = 'PLAYING'
    }
  }
}