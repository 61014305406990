import { PeerConnectionsManager } from 'src/managers/PeerConnectionsManager'
import CommonUtil from 'src/utils/CommonUtil'
import System from "src/system/System"
import { configs } from 'src/config/config'
import EventEmitter from 'events'
import { parametersUtil, parameterValidKeys } from 'src/utils/ParametersUtil'

interface StatData {
  data: Map<string, any>;
  processed: number;
}

export class LoopArray<T> {
  private elements: Array<T>;
  private _size: number | undefined;

  public constructor(capacity = 50) {
    this.elements = new Array<T>();
    this._size = capacity;
  }

  public push(o: T): boolean {
    if (o === null) {
      return false;
    }
    // 如果传递了size参数就设置了队列的大小
    if (this._size != undefined && !isNaN(this._size)) {
      if (this.elements.length === this._size) {
        this.elements.pop();
      }
    }
    this.elements.unshift(o);
    return true;
  }

  public size(): number {
    const size = this.elements.length;
    return size;
  }

  public clear(): void {
    delete this.elements;
    this.elements = new Array<T>();
  }

  public getElement(index: number): T {
    if (index < 0 || index >= this.size()) {
      return null
    }
    const element = this.elements[index];
    return element
  }
}

class HWLLSInterval {
  private readonly reportSymbol: symbol
  private connectionsManagers: PeerConnectionsManager[]
  private sampleArray: Map<string, LoopArray<StatData>>
  private isFireFox = System.isFirefox()
  private gatherTimer: NodeJS.Timeout
  private event: EventEmitter
  private preMediaStats: Map<string, { noChangeCount?: number; preReceiveFrames?: number; stuckThreshold?: number; frameStuckCheck?: boolean; frameFirstComing?: boolean; preAudioPackets?: number }>
  constructor() {
    this.reportSymbol = Symbol(`HWLLSInterval_${CommonUtil.getCurrentLocalTimestamp()}`)
    this.sampleArray = new Map<string, LoopArray<StatData>>()
    this.connectionsManagers = []
    this.event = new EventEmitter()
    this.preMediaStats = new Map()
    this.executeInterval(() => {
      this.execHandler()
    }, configs.STATISTIC_INTERVAL)
  }

  public on(eventName: string, handler: any) {
    this.event.on(eventName, handler)
  }

  public off(eventName?: string) {
    if (!eventName) {
      this.event.removeAllListeners()
    } else {
      this.event.removeAllListeners(eventName)
    }
  }

  public getSymbol(): symbol {
    return this.reportSymbol
  }

  public reset(connectionId?: string) {
    if (connectionId) {
      this.sampleArray.delete(connectionId)
      this.preMediaStats.delete(connectionId)
      this.off(`video_stuck_${connectionId}`)
      this.off(`video_start_${connectionId}`)
      this.off(`audio_start_${connectionId}`)
      for (let idx = 0; idx < this.connectionsManagers.length; idx++) {
        if (this.connectionsManagers[idx].getConnectionId() === connectionId) {
          this.connectionsManagers.splice(idx, 1)
          break
        }
      }
    } else {
      this.sampleArray.clear()
      this.preMediaStats.clear()
      this.off()
      this.connectionsManagers.length = 0
    }
  }

  public connectionRegister(connectionsManager: PeerConnectionsManager) {
    this.connectionsManagers.push(connectionsManager)
  }

  public getleastestStats(connectionId: string, ssrcLabel?: string): any {
    if (!connectionId) {
      return null
    }
    if (!ssrcLabel) {
      return this.sampleArray.get(connectionId)?.getElement(0)
    }
    let lastestStats = this.getElementStats(connectionId, ssrcLabel, 0)
    if (!lastestStats) {
      lastestStats = this.getElementStats(connectionId, ssrcLabel, 1) // fallback到第二个元素 如果第二个元素也为空，也直接返回
    }
    return lastestStats
  }

  public getHistoryDatas(connectionId: string): LoopArray<StatData> {
    return this.sampleArray.get(connectionId)
  }

  public getleastestStatsByPropertyName(connectionId: string, ssrcLabel: string, propertyName: string): any {
    let lastestStats = this.getElementStats(connectionId, ssrcLabel, 0)
    if (!lastestStats) {
      lastestStats = this.getElementStats(connectionId, ssrcLabel, 1) // fallback到第二个元素 如果第二个元素也为空，也直接返回
    }
    return lastestStats ? lastestStats[propertyName] : null
  }

  public getElementStats(connectionId: string, ssrcLabel: string, index: number): any {
    const map = this.sampleArray.get(connectionId)?.getElement(index)
    if (!map) {
      return null
    }
    if (this.isFireFox) {
      let reuslt = null
      const infos = ssrcLabel.split('_')
      const type = infos[0]
      const mediaType = infos[1]
      const ssrc = parseInt(infos[2])
      map.data.forEach((value: any) => {
        if (value.type === type && value.mediaType === mediaType && value.ssrc === ssrc) {
          reuslt = value
        }
      })
      return reuslt
    }
    return map.data.get(ssrcLabel)
  }

  private executeInterval(callback: any, interval: number) {
    const doWork = () => {
      this.gatherTimer = setTimeout(async () => {
        clearTimeout(this.gatherTimer)
        await callback()
        doWork()
      }, interval)
    }
    // 启动迭代器
    doWork()
  }

  private async getStats(connectionsManager: PeerConnectionsManager, map: Map<string, any>): Promise<void> {
    const connection = connectionsManager.getConnection()
    if (!connection || typeof connection.getStats !== 'function') {
      return
    }

    const report = await connection.getStats()
    const connectionId = connectionsManager.getConnectionId()
    if (report) {
      report.forEach((value: any, key: string) => {
        if (value.type !== 'candidate-pair' || value['selected'] === true || value['nominated '] === true || value['state'] === 'succeeded') {
          if (['candidate-pair', 'local-candidate', 'inbound-rtp'].includes(value.type)) {
            map.set(value.type !== 'local-candidate' ? `${value.type}${value.mediaType ? '_' : ''}${value.mediaType || ''}${value.ssrc ? '_' : ''}${value.ssrc || ''}` : key, value)
            this.streamHandler(connectionId, value, value.mediaType)
          }
        }
      })
    }
    if (!this.sampleArray.has(connectionId)) {
      this.sampleArray.set(connectionId, new LoopArray<StatData>())
    }
    this.sampleArray.get(connectionId).push({
      data: map,
      processed: 0
    })
  }

  private streamHandler(connectionId: string, data: any, mediaType: string) {
    let preStats = {}
    if (this.preMediaStats.has(connectionId)) {
      preStats = this.preMediaStats.get(connectionId)
    }
    if (mediaType === 'audio') {
      if (!Object.prototype.hasOwnProperty.call(preStats, 'preAudioPackets') && data['packetsReceived'] > 0) {
        this.event.emit(`audio_start_${connectionId}`)
        this.preMediaStats.set(connectionId, { preAudioPackets: data['packetsReceived'] })
      }
      return
    }

    if (mediaType === 'video') {
      this.judgeVideoStreamStuck(connectionId, data, preStats)
    }
  }

  private judgeVideoStreamStuck(connectionId: string, data: any, preStats: any): void {
    const decodedFrames = data['framesDecoded'] || 0
    if (preStats && Object.prototype.hasOwnProperty.call(preStats, 'preReceiveFrames')) {
      if (!preStats.frameStuckCheck && preStats.frameFirstComing) {
        return
      }

      if (decodedFrames === preStats.preReceiveFrames) {
        preStats.noChangeCount++
      } else {
        if (preStats.preReceiveFrames === 0 || preStats.noChangeCount > 1) {
          preStats.noChangeCount = 1
          this.event.emit(`video_stuck_${connectionId}`, false)
          preStats.preReceiveFrames === 0 && this.event.emit(`video_start_${connectionId}`)
          preStats.frameFirstComing = true
        }
      }
      preStats.preReceiveFrames = decodedFrames
      if (preStats.noChangeCount % preStats.stuckThreshold === 0) {
        this.event.emit(`video_stuck_${connectionId}`, true)
      }
    } else {
      const loadingconfig = {
        ...{
          frameStuckLoading: false,
          frameStuckThreshold: 10
        }, ...(parametersUtil.getParameter(parameterValidKeys.LOADING_CONFIG) || {})
      }
      const videoFrameStats = { noChangeCount: 1, preReceiveFrames: decodedFrames, stuckThreshold: loadingconfig.frameStuckThreshold, frameStuckCheck: loadingconfig.frameStuckLoading, frameFirstComing: false }
      if (decodedFrames) {
        videoFrameStats.frameFirstComing = true
        this.event.emit(`video_stuck_${connectionId}`, false)
        this.event.emit(`video_start_${connectionId}`)
      }
      this.preMediaStats.set(connectionId, Object.prototype.hasOwnProperty.call(preStats, 'preAudioPackets') ? { ...videoFrameStats, ...preStats } : videoFrameStats)
    }
  }

  private async execHandler(): Promise<void> {
    for (const connectionsManager of this.connectionsManagers) {
      const intervalData = new Map<string, any>()
      await this.getStats(connectionsManager, intervalData)
    }
  }
}

const hwllsInterval = new HWLLSInterval()
export default hwllsInterval
