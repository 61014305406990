// @ts-nocheck
class NetworkStat {
  private networkType: string

  private networkRTT: number

  private downlink: number

  constructor() {
    const networkInfo = navigator.connection
    if (!networkInfo) {
      return
    }
    this.networkType = networkInfo.type
    this.networkRTT = networkInfo.rtt
    this.downlink = networkInfo.downlink || -1
    if (Object.prototype.hasOwnProperty.call(navigator.connection, 'onchange')) {
      navigator.connection.onchange = () => {
        const networkInfo = navigator.connection
        this.networkType = networkInfo.type
        this.networkRTT = networkInfo.rtt
        this.downlink = networkInfo.downlink || -1
      }
    }
  }

  public getNetworkType(): string {
    return this.networkType
  }

  public getNetworkRTT(): number {
    return this.networkRTT
  }

  public getNetworkInfo(): { networkType: number; networkRTT: number } {
    return {
      networkType: this.networkType,
      networkRTT: this.networkRTT
    }
  }
}

const networkStat = new NetworkStat()
export default networkStat