import { api, asyncApi } from "src/common/APIAnnotation"
import EmitterAble from "src/common/EmitterAble"
import { LoggerFactory } from "src/logger/LoggerFactory"
import { ValidatorUtil } from "src/utils/ValidatorUtil"
import { ErrorCode, ErrorMsg, HwLLSError } from "src/ecode/ECode"
import { HWLLSEvents, MediaFormat, MediaType, StartPlayOptions, StatisticBase, StatisticInfo, StreamInterruptRetry, VideoStatistic } from "src/common/ObjDefinition"
import CommonUtil from "src/utils/CommonUtil"
import { PlayerInterface } from "src/client/IClient"
import { FlvPlayer } from "src/player/FlvPlayer"
import { PosterMask } from "src/player/PlayerUtils"

const module_ = 'HWFlvClient'

export class HWFlvClient extends EmitterAble implements PlayerInterface {
  private flvPlayer: FlvPlayer;
  private startPlayOptions: StartPlayOptions
  private debugStatisticTimer = {
    timer: null,
    interval: 1,
  }

  private detectTimer = {
    timer: null,
    interval: 3,
    interruptRetry: null
  }

  private recoveryInfo = {
    recoveryTimer: null,
    retryTimes: 0,
    recoveryUrl: null
  }

  constructor() {
    super(module_)
  }

  @asyncApi("HWFlvClient$startPlay#Promise<void>#string#StartPlayOptions")
  public async startPlay(url: string, options: StartPlayOptions): Promise<void> {
    LoggerFactory.info(module_, `[KPI] startPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    this.startPlayOptions = options
    url = url.replace(/\.m3u8/ig, '.flv')
    this.recoveryInfo.recoveryUrl = url
    const checkResult = ValidatorUtil.checkUrlFormat(url, MediaFormat.FLV)
    if (!checkResult) {
      LoggerFactory.error(module_, `startPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }
    const playerDom = document.getElementById(options.elementId)
    if (!playerDom) {
      LoggerFactory.error(module_, `switchPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'player Element is not exist.')
    }
    this.flvPlayer = new FlvPlayer({
      eventEmitter: this.eventEmitter,
      url: url,
      parentDiv: playerDom,
      objectFit: options.objectFit,
      muted: options.muted,
      poster: this.startPlayOptions.poster
    })
    if (this.startPlayOptions.poster && this.startPlayOptions.poster.url) {
      PosterMask.preLoadPoster(this.startPlayOptions.poster.url)
    }
    await this.flvPlayer.play(this.startPlayOptions.autoPlay, options.showLoading).catch(error => {
      const isPlayLimit = error instanceof HwLLSError && error.getCode() === ErrorCode.HWLLS_PLAY_NOT_ALLOW
      if (isPlayLimit) {
        this.eventEmitter.emit('Error', {
          errCode: error.getCode(),
          errDesc: error.getMsg()
        })
      } else {
        this.eventEmitter.emit('Error', {
          errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
          errDesc: ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
        })
      }
    })
  }

  @asyncApi("HWFlvClient$switchPlay#Promise<boolean>#string#StartPlayOptions")
  public async switchPlay(url: string, options?: StartPlayOptions): Promise<void> {
    clearTimeout(this.debugStatisticTimer.timer)
    clearTimeout(this.detectTimer.timer)
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo.retryTimes = 0

    LoggerFactory.info(module_, `[KPI] switchPlay url: ${LoggerFactory.shieldUrlParameters(url)}`)
    this.startPlayOptions = options || this.startPlayOptions
    url = url.replace(/\.m3u8/ig, '.flv')
    this.recoveryInfo.recoveryUrl = url

    const playerDom = document.getElementById(this.startPlayOptions.elementId)
    if (!playerDom) {
      LoggerFactory.error(module_, `switchPlay failed, invalid Parameter: ${url}`)
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER, 'player Element is not exist.')
    }
    if (this.startPlayOptions.poster && this.startPlayOptions.poster.url) {
      PosterMask.preLoadPoster(this.startPlayOptions.poster.url)
    }
    await this.flvPlayer.switchPlay({
      url: url,
      parentDiv: playerDom,
      objectFit: this.startPlayOptions.objectFit,
      muted: this.startPlayOptions.muted,
      autoPlay: this.startPlayOptions.autoPlay,
      showLoading: options.showLoading,
      poster: this.startPlayOptions.poster
    }).catch(error => {
      const isPlayLimit = error instanceof HwLLSError && error.getCode() === ErrorCode.HWLLS_PLAY_NOT_ALLOW
      if (isPlayLimit) {
        this.eventEmitter.emit('Error', {
          errCode: error.getCode(),
          errDesc: error.getMsg()
        })
      } else {
        this.eventEmitter.emit('Error', {
          errCode: ErrorCode.HWLLS_INTERNAL_ERROR,
          errDesc: ErrorMsg[ErrorCode.HWLLS_INTERNAL_ERROR]
        })
      }
    })
    if (this.debugStatisticTimer.timer) {
      this.streamStatistic(true, this.debugStatisticTimer.interval)
    }
    if (this.detectTimer.timer) {
      this.enableStreamStateDetection(true, this.detectTimer.interval, this.detectTimer.interruptRetry)
    }
  }


  @api("HWFlvClient$stopPlay#boolean#void")
  stopPlay(): boolean {
    return this.resetPlayer()
  }

  @asyncApi("HWFlvClient$replay#Promise<boolean>#void")
  public async replay(): Promise<boolean> {
    if (this.flvPlayer) {
      try {
        await this.flvPlayer.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @asyncApi("HWFlvClient$resume#Promise<boolean>#void")
  public async resume(): Promise<boolean> {
    if (this.flvPlayer) {
      try {
        await this.flvPlayer.resume()
        return true
      } catch (error) {
        LoggerFactory.error(module_, `resume failed: ${error}`)
        return false
      }
    }
    return false
  }

  @api("HWFlvClient$pause#boolean#void")
  public pause(): boolean {
    if (this.flvPlayer) {
      this.flvPlayer.pause()
      return true
    }
    return false
  }

  @api("HWFlvClient$pauseVideo#boolean#void")
  public pauseVideo(): boolean {
    LoggerFactory.error(module_, 'flv player is not support pause video only.')
    return false
  }

  @asyncApi("HWFlvClient$resumeVideo#Promsise<boolean>#void")
  public async resumeVideo(): Promise<boolean> {
    LoggerFactory.error(module_, 'flv player is not support resume video only.')
    return false
  }

  @api("HWFlvClient$pauseAudio#boolean#void")
  public pauseAudio(): boolean {
    if (this.flvPlayer) {
      this.flvPlayer.mute(true)
      return true
    }
    return false
  }

  @asyncApi("HWFlvClient$resumeAudio#Promsise<boolean>#void")
  public async resumeAudio(): Promise<boolean> {
    if (this.flvPlayer) {
      this.flvPlayer.mute(false)
      return true
    }
    return false
  }

  @api("HWFlvClient$setPlayoutVolume#boolean#number")
  public setPlayoutVolume(volume: number): boolean {
    if (this.flvPlayer) {
      this.flvPlayer.mute(false)
      this.flvPlayer.setPlayoutVolume(volume)
      return true
    }
    return false
  }

  public getPlayoutVolume(): number {
    return this.flvPlayer?.getPlayoutVolume()
  }

  @api("HWFlvClient$destoryClient#void#void")
  public destoryClient(): void {
    this.flvPlayer?.destory()
    this.offAllEvents()
  }

  @api("HWFlvClient$fullScreenToggle#boolean#void")
  public fullScreenToggle(isExit = false): void {
    if (isExit) {
      this.flvPlayer?.exitFullScreen()
    } else {
      this.flvPlayer?.enterFullScreen()
    }
  }

  on(eventName: string, handler: any) {
    if ([HWLLSEvents.MEDIA_STATISTIC, HWLLSEvents.NET_QUALITY].includes(eventName)) {
      super.on(eventName, handler)
    } else {
      super.on(eventName, handler, true)
    }
  }

  off(eventName: string, handler: any) {
    if ([HWLLSEvents.MEDIA_STATISTIC, HWLLSEvents.NET_QUALITY].includes(eventName)) {
      super.off(eventName, handler)
    } else {
      super.off(eventName, handler, true)
    }
  }

  @api("HWFlvClient$streamStatistic#void#boolean#number#ExecutableFunction")
  public streamStatistic(enable: boolean, interval = 1): void {
    if (enable) {
      if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
        LoggerFactory.error(module_, 'streamStatistic, invalid parameter of interval.')
        throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
      }
      const preDecodeFrames = 0
      this.getStreamStatisticInfo(preDecodeFrames, interval)
    } else {
      clearTimeout(this.debugStatisticTimer.timer)
      this.debugStatisticTimer = {
        timer: null,
        interval: 1
      }
    }
  }

  @api("HWFlvClient$enableStreamStateDetection#boolean#boolean#number")
  public enableStreamStateDetection(enable: boolean, interval = 3, interruptRetry?: StreamInterruptRetry): boolean {
    if (!enable) {
      clearTimeout(this.detectTimer.timer)
      this.detectTimer = {
        timer: null,
        interval: 3,
        interruptRetry: null
      }
      return true
    }

    if (!Number.isInteger(interval) || interval < 1 || interval > 60) {
      LoggerFactory.error(module_, 'enableStreamStateDetection, invalid parameter of interval.')
      throw new HwLLSError(ErrorCode.HWLLS_ERROR_INVALID_PARAMETER)
    }

    clearTimeout(this.detectTimer.timer)
    const preStat = {
      preFramesDecoded: 0,
      isInterrupted: false
    }

    if (this.flvPlayer) {
      preStat.preFramesDecoded = CommonUtil.getValue(this.flvPlayer.getStatisticInfo()?.decodedFrames, 0)
    }

    this.detectTimer.interval = interval
    this.detectTimer.interruptRetry = interruptRetry
    this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    return true
  }

  private judgeStreamInterrupted(preStat: any, interval: number, interruptRetry?: StreamInterruptRetry): void {
    this.detectTimer.timer = setTimeout(() => {
      clearTimeout(this.detectTimer.timer)
      let mediaStat = null
      let isInterrupted = false
      if (this.flvPlayer) {
        mediaStat = this.flvPlayer.getStatisticInfo()
        isInterrupted = CommonUtil.getValue(mediaStat?.decodedFrames, 0) - CommonUtil.getValue(preStat.preFramesDecoded, 0) <= 0
        if (!isInterrupted) {
          clearTimeout(this.recoveryInfo.recoveryTimer)
          this.recoveryInfo.retryTimes = 0
        }
        if (mediaStat) {
          preStat.preFramesDecoded = CommonUtil.getValue(mediaStat.decodedFrames, 0)
        }
      }

      if (preStat.isInterrupted && !isInterrupted) {
        this.flvPlayer?.loadingMask(false)
        this.eventEmitter.emit(HWLLSEvents.VIDEO_RECOVERY)
        this.eventEmitter.emit(HWLLSEvents.AUDIO_RECOVERY)
        preStat.isInterrupted = false
      } else if (!preStat.isInterrupted && isInterrupted) {
        this.startPlayOptions.showLoading && this.flvPlayer?.loadingMask(true)
        this.eventEmitter.emit(HWLLSEvents.VIDEO_INTERRUPTED)
        this.eventEmitter.emit(HWLLSEvents.AUDIO_INTERRUPTED)
        preStat.isInterrupted = true
        if (interruptRetry?.enable) {
          LoggerFactory.info(this.moudle_, `autoRecoverPlay timer start`)
          clearTimeout(this.recoveryInfo.recoveryTimer)
          this.autoRecoverPlay(this.recoveryInfo.recoveryUrl, Math.max(10, interruptRetry?.retryInterval || 30), interruptRetry?.retryTimes || 30)
        }
      }
      this.judgeStreamInterrupted(preStat, interval, interruptRetry)
    }, interval * 1000)
  }

  private autoRecoverPlay(recoveryUrl: string, retryInterval: number, retryTimes: number): void {
    let currentRetryTimes = 0
    this.recoveryInfo.recoveryTimer = setTimeout(async () => {
      LoggerFactory.info(this.moudle_, `autoRecoverPlay every ${retryInterval} sencond`)
      clearTimeout(this.recoveryInfo.recoveryTimer)
      this.recoveryInfo.recoveryTimer = null
      if (recoveryUrl !== this.recoveryInfo.recoveryUrl) {
        this.recoveryInfo.retryTimes = 0
        return
      }
      try {
        this.recoveryInfo.retryTimes++
        currentRetryTimes = this.recoveryInfo.retryTimes
        await this.switchPlay(recoveryUrl)
      } finally {
        this.recoveryInfo.retryTimes = currentRetryTimes
        if (currentRetryTimes < retryTimes) {
          this.autoRecoverPlay(recoveryUrl, retryInterval, retryTimes)
        } else {
          this.eventEmitter.emit('Error', {
            errCode: ErrorCode.HWLLS_PLAY_FLV_RETRY_FAILED,
            errDesc: ErrorMsg[ErrorCode.HWLLS_PLAY_FLV_RETRY_FAILED]
          })
        }
      }
    }, retryInterval * 1000)
  }

  private resetPlayer(): boolean {
    this.startPlayOptions = null
    clearTimeout(this.debugStatisticTimer.timer)
    this.debugStatisticTimer = {
      timer: null,
      interval: 1,
    }
    clearTimeout(this.detectTimer.timer)
    this.detectTimer = {
      timer: null,
      interval: 3,
      interruptRetry: null
    }
    clearTimeout(this.recoveryInfo.recoveryTimer)
    this.recoveryInfo = {
      recoveryTimer: null,
      retryTimes: 0,
      recoveryUrl: null
    }
    this.flvPlayer?.stopPlay()
    return true
  }

  private getStreamStatisticInfo(preDecodeFrames: number, interval: number): void {
    this.debugStatisticTimer.interval = interval
    this.debugStatisticTimer.timer = setTimeout(() => {
      clearTimeout(this.debugStatisticTimer.timer)
      const statisticInfo: StatisticInfo = {}

      const mediaStatistic: any = this.flvPlayer.getMediaInfo()
      if (mediaStatistic.audioCodec) {
        statisticInfo.audio = {
          mediaType: MediaType.TRACK_TYPE_AUDIO,
          bitRate: Math.round(mediaStatistic.audioDataRate),
          codec: mediaStatistic.audioCodec
        } as StatisticBase
      }

      if (mediaStatistic.videoCodec) {
        const videoMediaStatistic: any = this.flvPlayer.getStatisticInfo()
        statisticInfo.video = {
          mediaType: MediaType.TRACK_TYPE_VIDEO,
          codec: mediaStatistic.videoCodec,
          bitRate: Math.round(mediaStatistic.videoDataRate),
          frameRate: preDecodeFrames > 0 ? Number(((videoMediaStatistic.decodedFrames - preDecodeFrames) / interval).toFixed(1)) : 0,
          width: CommonUtil.getValue(mediaStatistic.width, 0),
          height: CommonUtil.getValue(mediaStatistic.height, 0)
        } as VideoStatistic
        preDecodeFrames = videoMediaStatistic.decodedFrames
      }

      this.eventEmitter.emit(HWLLSEvents.MEDIA_STATISTIC, statisticInfo)
      this.getStreamStatisticInfo(preDecodeFrames, interval)
    }, interval * 1000)
  }
}