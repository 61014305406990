import { configs } from 'src/config/config'
import CommonUtil from 'src/utils/CommonUtil'
import { parametersUtil, parameterValidKeys } from 'src/utils/ParametersUtil'
import { LoggerFactory } from 'src/logger/LoggerFactory'
import { ApiCallInfo, ApiEvents, AudioQosInfo, CallbackInfo, CommInfo, MediaType, QosEvents, StatEvents, VideoQosInfo } from 'src/common/ObjDefinition'
import { MediaStats } from 'src/stat/MediaStat'
import { GlobalVariableManager } from 'src/managers/GlobalVariableManager'
import HWLLSReport from 'src/stat/HWLLSReport'
import NetworkStat from './NetworkStat'

const INTERVAL_TIME = 5
/**
 * 上报业务打点信息
 *
 * @class HWLLSStat
 */
export class HWLLSStat {
  public commInfo: CommInfo
  private module_: string
  private identifiedId: string
  // 媒体QOS上报定时器
  private mediaStatisticTimer: NodeJS.Timeout

  constructor(identifiedId: string) {
    this.identifiedId = identifiedId
    this.module_ = 'HWLLSStat'
    this.commInfo = {} as CommInfo
    this.commInfo.deviceId = CommonUtil.getDeviceID()
    this.commInfo.v = `web-${configs.SDK_VERSION}-${configs.COMMIT_ID}`
    this.commInfo.userAgent = navigator.userAgent
  }

  public reportStartPullStreamReqEvent(requestInfo: any) {
    if (!this.isStatiscEnable()) {
      return
    }
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    const streamParams = GlobalVariableManager.getInstance(this.identifiedId)?.getStreamParams()
    const playTrackKpi = GlobalVariableManager.getInstance(this.identifiedId)?.getPlayTrackKpi()

    const opsInfo = this.generateCommonReportInfo({
      accessAddr: streamParams?.accessAddr || '',
      routeType: streamParams.routeType,
      SFUAddr: sfuInfo?.ipAddress || '',
      aPort: sfuInfo?.audioPort || 0,
      vPort: sfuInfo?.videoPort || 0,
      httpCode: requestInfo.httpCode,
      resultCode: requestInfo.retCode,
      resultMessage: requestInfo.retMsg,
      startTime: playTrackKpi.negotiateSDPRequest,
      endTime: playTrackKpi.negotiateSDPRespone,
      playQos: JSON.stringify(requestInfo.joinQos || {}),
      appUrl: CommonUtil.getAppUrl(),
      gslbCostTime: playTrackKpi.glsbRespone - playTrackKpi.glsbRequest,
      sigCostTime: playTrackKpi.negotiateSDPRespone - playTrackKpi.negotiateSDPRequest,
      platform: CommonUtil.getPlatform(),
      bv: CommonUtil.getBrowserVersion(),
      timeDiff: CommonUtil.getTimeDiff(),
      autoRetry: requestInfo.autoRetry
    }, StatEvents.EVENT_START_PULL_STREAM_REQ, `event^startPlay^SDK^ACS`)
    HWLLSReport.addRecord(opsInfo)
    HWLLSReport.immediateReportRecords()
  }

  public reportStopPullStreamReqEvent(startTime: number) {
    if (!this.isStatiscEnable()) {
      return
    }
    const averageStatistic = GlobalVariableManager.getInstance(this.identifiedId)?.calcAverageStatistic()
    const streamParams = GlobalVariableManager.getInstance(this.identifiedId).getStreamParams()
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId).getSfuInfo()
    const opsInfo = this.generateCommonReportInfo({
      reason: 'stopPlay',
      elapsed: GlobalVariableManager.getInstance(this.identifiedId)?.calcPlayDuration(),
      aRecvRttMean: averageStatistic.aRecvRttMean,
      vRecvRttMean: averageStatistic.vRecvRttMean,
      aRecvLostMean: averageStatistic.aRecvLostMean,
      vRecvLostMean: averageStatistic.vRecvLostMean,
      aRecvBandwidthMean: averageStatistic.aRecvBandwidthMean,
      vRecvBandwidthMean: averageStatistic.vRecvBandwidthMean,
      aRecvJitter: averageStatistic.aRecvJitter,
      vRecvJitter: averageStatistic.vRecvJitter,
      afreeze200Cnt: averageStatistic.afreeze200Cnt,
      vfreeze200Cnt: averageStatistic.vfreeze200Cnt,
      vfreeze600Cnt: averageStatistic.vfreeze600Cnt,
      afreeze200Rate: averageStatistic.afreeze200Rate,
      vfreeze200Rate: averageStatistic.vfreeze200Rate,
      vfreeze600Rate: averageStatistic.vfreeze600Rate,
      resultCode: 0,
      startTime: startTime,
      endTime: CommonUtil.getCurrentLocalTimestamp(),
      accessAddr: streamParams?.accessAddr || '',
      SFUAddr: sfuInfo?.ipAddress || ''
    }, StatEvents.EVENT_STOP_PULL_STREAM_REQ, `event^stopPlay^SDK^ACS`)
    HWLLSReport.addRecord(opsInfo)
    HWLLSReport.immediateReportRecords()
  }

  /**
   * 信令/媒体通道连接
   * @param on
   * @param type
   */
  public reportConnectionStatus(on: number, protocol?: string) {
    if (!this.isStatiscEnable()) {
      return
    }
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    let opsInfo = {
      SFUAddr: sfuInfo?.ipAddress,
      ssrc: sfuInfo?.vSsrc || sfuInfo?.aSsrc,
      on: on,
      protocol: protocol || ''
    }
    opsInfo = this.generateCommonReportInfo(opsInfo, StatEvents.EVENT_CONNECTION_CHANNEL_STATUS, `event^connectionState^SDK^ACS`)
    HWLLSReport.addRecord(opsInfo)
    HWLLSReport.immediateReportRecords()
  }

  /**
 * 媒体拉流结果
 * @param on
 * @param type
 */
  public reportPullMediaStreamResult(mediaType: MediaType, result: number, info?: any) {
    if (!this.isStatiscEnable()) {
      return
    }
    const sfuInfo = info?.sfuInfo || GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    const streamParams = info?.streamParams || GlobalVariableManager.getInstance(this.identifiedId)?.getStreamParams()
    const mediaParams = info?.mediaParams || GlobalVariableManager.getInstance(this.identifiedId)?.getPullMediaParams()
    const playTrackKpi = info?.playTrackKpi || GlobalVariableManager.getInstance(this.identifiedId)?.getPlayTrackKpi()

    const isAudio = mediaType === MediaType.TRACK_TYPE_AUDIO
    let opsInfo = {
      accessAddr: streamParams?.accessAddr || '',
      SFUAddr: sfuInfo?.ipAddress || '',
      streamType: isAudio ? 0 : 1,
      ssrc: isAudio ? sfuInfo?.aSsrc || 0 : sfuInfo?.vSsrc || 0,
      result,
      startTime: playTrackKpi.icePairStart,
      endTime: CommonUtil.getCurrentLocalTimestamp(),
      playQoS: JSON.stringify(mediaParams?.keyInfoMsg || []),
      mediaCostTime: (playTrackKpi.iceComplete || CommonUtil.getCurrentLocalTimestamp()) - playTrackKpi.icePairStart,
      totalTime: (playTrackKpi[isAudio ? 'audioPlaySucc' : 'videoPlaySucc'] || CommonUtil.getCurrentLocalTimestamp()) - playTrackKpi.startPlayTime
    }
    opsInfo = this.generateCommonReportInfo(opsInfo, StatEvents.EVENT_PULL_MEDIA_STREAM, `event^pullstreamResult^SDK^ACS`)
    HWLLSReport.addRecord(opsInfo)
    HWLLSReport.immediateReportRecords()
  }

  /**
   * 上报 对外接口的调用信息
   *
   */
  public reportApiCallInfo(apiShortName: string, start: number, end: number, exception: any, ret: any, ...params: any) {
    if (!this.isStatiscEnable()) {
      return
    }
    const apiLongName = `API^web#${apiShortName}^APP^SDK`
    let info = {
      apiCallId: CommonUtil.generateUuid(),
      apiName: apiLongName,
      params: JSON.stringify(params),
      startTime: start,
      endTime: end
    } as ApiCallInfo
    if (exception) {
      info.exception = `${exception.code} - ${exception.message}`
    } else {
      info.ret = ret ? JSON.stringify(ret) : ''
    }
    info = this.generateCommonReportInfo(info, ApiEvents.API_CALL, apiLongName)
    if (/checkSystemRequirements/.test(apiShortName)) {
      HWLLSReport.addRecord(info)
      HWLLSReport.immediateReportRecords()
    } else {
      HWLLSReport.addRecord(info)
    }
  }

  /**
   * recordClientCallbackInfo
   */
  public reportCallbackInfo(event: string, module: string, start: number, end: number, params: any, handler: any) {
    if (!this.isStatiscEnable()) {
      return
    }
    const dataStr = handler(params?.stream || params)
    let opsInfo = {
      callbackId: CommonUtil.generateUuid(),
      callbackName: `CALLBACK^web#${module}$#${event}#^SDK^APP`,
      callbackType: event,
      params: dataStr,
      startTime: start,
      endTime: end
    } as CallbackInfo
    opsInfo = this.generateCommonReportInfo(opsInfo, ApiEvents.CALLBACK_API, `CALLBACK^web#${module}$#${event}#^SDK^APP`)
    HWLLSReport.addRecord(opsInfo)
  }

  public reportVideoQos(qos: any) {
    if (!this.isStatiscEnable() || !qos) {
      return
    }
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    const streamParams = GlobalVariableManager.getInstance(this.identifiedId).getStreamParams()
    let opsInfo = {
      ssrc: sfuInfo.vSsrc || 0,
      accessAddr: streamParams?.accessAddr || '',
      SFUAddr: sfuInfo.ipAddress || '',
      codec: 'h264',
      picW: qos.width,
      picH: qos.height,
      bitrate: qos.bitRate,
      frameRate: qos.frameRate,
      lostPktCnt: qos.lostPktCnt,
      finalLostPktCnt: qos.lostPktCnt,
      maxContLostPkts: qos.lostPktCnt,
      rtt: qos.rtt,
      jitter: qos.jitter,
      bytes: qos.totalByteReceived,
      packets: qos.totalPacketsReceived,
      JBDepth: 0,
      playTotalTime: qos.totalStatisticTime,
      decFrameCnt: qos.totalDecodeFrames,
      freeze200Cnt: qos.total200FreezeCnt,
      freeze200Duration: qos.total200FreezeTime,
      freeze600Cnt: qos.total600VFreezeCnt,
      freeze600Duration: qos.total600VFreezeTime,
      iFrameCnt: qos.keyFrames,
      pFrameCnt: qos.totalDecodeFrames,
      bFrameCnt: 0,
      downloadDelay: qos.downloadDelay,
      delayInfo: qos.delayInfo,
      framesReceived: qos.framesReceived,
      framesDropped: qos.framesDropped,
      framesDecoded: qos.framesDecoded,
      nackCnt: qos.nackCnt,
      pliCnt: qos.pliCnt,
      firCnt: qos.firCnt
    } as VideoQosInfo
    opsInfo = this.generateCommonReportInfo(opsInfo, QosEvents.EVENT_VIDEO_QOS, `qos^video_downstreams_qos^SDK^APP`)
    HWLLSReport.addRecord(opsInfo)
  }

  public reportAudioQos(qos: any) {
    if (!this.isStatiscEnable() || !qos) {
      return
    }
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    const streamParams = GlobalVariableManager.getInstance(this.identifiedId).getStreamParams()
    let opsInfo = {
      ssrc: sfuInfo.aSsrc,
      accessAddr: streamParams?.accessAddr || '',
      SFUAddr: sfuInfo.ipAddress || '',
      codec: 'opus',
      bitrate: qos.bitRate,
      lostPktCnt: qos.lostPktCnt,
      finalLostPktCnt: qos.lostPktCnt,
      maxContLostPkts: qos.lostPktCnt,
      rtt: qos.rtt,
      jitter: qos.jitter,
      bytes: qos.bytes,
      packets: qos.packets,
      JBDepth: 0,
      playTotalTime: qos.totalStatisticTime,
      freeze200Cnt: qos.total200FreezeCnt,
      freeze200Duration: qos.total200FreezeTime,
      nackCnt: qos.nackCnt
    } as AudioQosInfo
    opsInfo = this.generateCommonReportInfo(opsInfo, QosEvents.EVENT_AUDIO_QOS, `qos^audio_downstreams_qos^SDK^APP`)
    HWLLSReport.addRecord(opsInfo)
  }

  public startMediaStatistic() {
    if (this.mediaStatisticTimer) {
      return
    }
    this.mediaStatisticTimer = setTimeout(() => {
      try {
        this.mediaStatisticHandler()
      } catch (error) {
        LoggerFactory.error(this.module_, `Media Statistic occur error: ${error}`)
      } finally {
        clearTimeout(this.mediaStatisticTimer)
        this.mediaStatisticTimer = null
        this.startMediaStatistic()
      }
    }, INTERVAL_TIME * 1000)
  }

  public cancelMediaStatistic() {
    this.mediaStatisticHandler()
    clearTimeout(this.mediaStatisticTimer)
    this.mediaStatisticTimer = null
  }

  private mediaStatisticHandler() {
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()
    if (!sfuInfo) {
      return
    }
    if (sfuInfo.aSsrc) {
      const audioStatisticInfo = MediaStats.getMediaStatistic(sfuInfo.connectionId, sfuInfo.aSsrc, MediaType.TRACK_TYPE_AUDIO)
      if (audioStatisticInfo) {
        this.reportAudioQos(audioStatisticInfo)
        GlobalVariableManager.getInstance(this.identifiedId)?.addAudioStatisticCache(audioStatisticInfo)
      }
    }

    if (sfuInfo.vSsrc) {
      const videoStatisticInfo = MediaStats.getMediaStatistic(sfuInfo.connectionId, sfuInfo.vSsrc, MediaType.TRACK_TYPE_VIDEO)
      if (videoStatisticInfo) {
        this.reportVideoQos(videoStatisticInfo)
        GlobalVariableManager.getInstance(this.identifiedId).addVideoStatisticCache(videoStatisticInfo)
      }
    }
  }

  private generateCommonReportInfo(opsInfo: any, event: number, eventName: string) {
    const streamParams = GlobalVariableManager.getInstance(this.identifiedId)?.getStreamParams()
    const mediaParams = GlobalVariableManager.getInstance(this.identifiedId)?.getPullMediaParams()
    const sfuInfo = GlobalVariableManager.getInstance(this.identifiedId)?.getSfuInfo()

    if (streamParams) {
      this.commInfo = {
        ...this.commInfo, ...{
          session: streamParams?.sessionId,
          domain: streamParams?.streamDomain,
          appName: streamParams?.appName,
          streamName: streamParams?.streamName,
          svrSid: mediaParams?.svrSid,
          clientAddr: mediaParams?.clientAddr
        }
      }
    }
    return {
      ...opsInfo, ...this.commInfo, ...{
        ctime: CommonUtil.getCurrentSyncTimestamp(),
        event: event,
        eventName: eventName,
        netType: NetworkStat.getNetworkType() || MediaStats.getConnectionNetType(sfuInfo?.connectionId)
      }
    }
  }

  private isStatiscEnable(): boolean {
    return parametersUtil.getParameter(parameterValidKeys.OPS_STATISC_ENABLE) !== false
  }
}